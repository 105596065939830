import {Button, Modal, Typography} from "antd";
import React from "react";
import {CloseOutlined} from "@ant-design/icons";

export function InfoModal({display, onOk, title = '', onCancel = ()=>{}}) {
    return (
        <Modal
            centered
            width={750}
            footer={null}
            closable={true}
            closeIcon={<CloseOutlined style={{color: 'white'}}/>}
            onCancel={() => {
                onCancel();
            }}
            bodyStyle={{
                width: 750,
                backgroundColor: "#353738",
                paddingRight: 60,
                paddingLeft: 60,
                paddingTop: 60,
                overflowX: "hidden",
            }}
            destroyOnClose
            visible={display}
            style={{top: 20, paddingBottom: 20}}
        >
            <div>
                <Typography.Title
                    style={{
                        display: "inline-block",
                        fontFamily: "Montserrat",
                        color: "#fff",
                        fontWeight: 400,
                        fontSize: 16,
                        paddingTop: 8,
                    }}
                >
                    {title}
                </Typography.Title>
            </div>
            <div style={{marginTop: 24}}/>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 20
            }}>
                <Button style={{
                    width: 250,
                }} type="primary" className={"sign-in"} onClick={() => {
                    onOk();
                }}>
                    Ok
                </Button>
                <Button style={{
                    width: 250,
                }} type="primary" className={"sign-in"} onClick={() => {
                    onCancel();
                }}>
                    Cancel
                </Button>
            </div>
            <div style={{marginTop: 36}}/>
        </Modal>
    )
}
