import React from 'react';
import {Button, Modal, Typography} from 'antd';

export function PiggyBackJSAcknowledgementModal({display, onOk, onCancel, titleOne, titleTwo}) {
    return (
        <Modal
            centered
            width={750}
            footer={null}
            closable={false}
            bodyStyle={{
                width: 750,
                backgroundColor: "#353738",
                paddingRight: 60,
                paddingLeft: 60,
                paddingTop: 60,
                overflowX: "hidden",
            }}
            destroyOnClose
            visible={display}
            style={{top: 20, paddingBottom: 20}}
        >
            <div>
                <Typography.Title
                    style={{
                        display: "inline-block",
                        fontFamily: "Montserrat",
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: 20,
                        paddingTop: 8,
                    }}
                >
                    {"Site Performance Advisory"}
                </Typography.Title>
            </div>
            <div>
                <Typography.Text style={{
                    display: "inline-block",
                    fontFamily: "Montserrat",
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: 14,
                    paddingBottom: 20,
                    paddingTop: 8,
                }}>
                    {titleOne || "This feature currently utilizes piggybacked JavaScript. Piggybacked JavaScript may slightly decrease page load performance."}
                    <br/><br/>
                    {titleTwo || "We are actively working with the vendor to transition to a more efficient Server-to-Server (S2S) integration. Once the S2S version is available, you will be automatically upgraded."}
                </Typography.Text>
            </div>
            <div style={{marginTop: 24}}/>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 20
            }}>
                <Button style={{
                    width: 250,
                }} type="primary" className={"sign-in"} onClick={() => {
                    onOk();
                }}>
                    Acknowledge and Enable
                </Button>
                <Button style = {{
                    width: 250
                }}type="primary" className={'cancel-button'} onClick={() => {
                    onCancel();
                }}>
                    Cancel
                </Button>
            </div>
            <div style={{marginTop: 36}}/>
        </Modal>
    )
}

