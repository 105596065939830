import { DatePicker, Dropdown, Button, Modal } from "antd"
import { AiOutlineCheck, AiOutlineDown, AiOutlinePlus } from "react-icons/ai"
import deleteIcon from '../../../../assets/remove_icon.png'
import arrowWhiteDown from '../../../../assets/arrow-white-down.svg'
import { attributesNotToIncudeInDropdown, booleanConditionDropdown, conditionDropdown, dateConditionDropdown, uuidConditionDropdown } from "../CSVDataEndpoint"
import { useEffect, useState } from "react"
import filterGroup from '../../../../assets/filter-group.svg'
import plusCircle from '../../../../assets/plus-circle.svg'
import moment from "moment"
import { CloseOutlined } from "@ant-design/icons"

const numberConditionDropdown = [
    { key: 'equals', label: 'Equals' },
    { key: 'lessThan', label: 'Less than' },
    { key: 'greaterThan', label: 'Greater than' }
];

const SingleFilter = ({ filter, index, editEnabled = false, attributes, updateSelectedCohortFilters = () => { }, selectedCohort, displayCondition = true, conditionDropdownDisabled = false, onRemoveFilter = () => { }, parentLength = 0, displayAttributesDropdown = true }) => {
    const dateFormat = "dddd MM/DD";
    const customFormat = (value) => `${value.format(dateFormat)}`;

    // State for all dropdowns
    const [isConditionDropdownOpen, setIsConditionDropdownOpen] = useState(false);
    const [isAttributeDropdownOpen, setIsAttributeDropdownOpen] = useState(false);
    const [isOperatorDropdownOpen, setIsOperatorDropdownOpen] = useState(false);
    const [isValueDropdownOpen, setIsValueDropdownOpen] = useState(false);
    const [isSearchSelectDropdownOpen, setIsSearchSelectDropdownOpen] = useState(false);

    const [searchSelectOptions, setSearchSelectOptions] = useState([])



    return <div style={{ display: 'flex', gap: 8, alignItems: 'center', marginBottom: 8 }}>

        {/* DROPDOWN FOR OR/ AND */}
        {displayCondition && <Dropdown
            trigger={["click"]}
            disabled={conditionDropdownDisabled}
            open={isConditionDropdownOpen}
            onOpenChange={(visible) => setIsConditionDropdownOpen(visible)}
            overlay={
                <div style={{ background: 'black', padding: 8, color: 'white', cursor: 'pointer' }}>
                    <div onClick={() => {
                        let tempFilter = JSON.parse(JSON.stringify(filter))
                        tempFilter.config.condition = "AND"
                        updateSelectedCohortFilters(tempFilter)
                        setIsConditionDropdownOpen(false)
                    }} style={{ padding: 4, cursor: 'pointer' }}>AND</div>
                    <div style={{ padding: 4, cursor: 'pointer' }} onClick={() => {
                        let tempFilter = JSON.parse(JSON.stringify(filter))
                        tempFilter.config.condition = "OR"
                        updateSelectedCohortFilters(tempFilter)
                        setIsConditionDropdownOpen(false)
                    }}>OR</div>
                </div>
            }>

            <div style={{ height: 40, width: 80, background: '#26282A', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 8, paddingInline: 16, borderRadius: 2, border: '1px solid #353738' }}>
                {filter?.config?.condition}
                {(editEnabled && !conditionDropdownDisabled) && <img src={arrowWhiteDown} />}
            </div>
        </Dropdown>}

        {/* DROPDOWN FOR ATTRIBUTES */}
        <Dropdown
            disabled={!(editEnabled)}
            trigger={["click"]}
            open={isAttributeDropdownOpen}
            onOpenChange={(visible) => setIsAttributeDropdownOpen(visible)}
            overlay={<div style={{ background: 'black', padding: 8, width: 220, cursor: 'pointer', maxHeight: 180, overflowY: 'scroll' }}>
                {attributes.filter(a => !attributesNotToIncudeInDropdown.includes(a.key))?.map(cd => (
                    <div onClick={() => {
                        let tempFilter = JSON.parse(JSON.stringify(filter))
                        tempFilter.config.operand = cd.key
                        tempFilter.config.value = ""
                        tempFilter.config.type = cd.type
                        tempFilter.config.operator = ""
                        updateSelectedCohortFilters(tempFilter)
                        setIsAttributeDropdownOpen(false)
                        
                        if(tempFilter.config.type === "searchSelect") {
                            setSearchSelectOptions(attributes.find(a => a.key === tempFilter.config.operand)?.acceptedValues)
                        }
                    }} style={{ color: 'white', padding: 4 }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                            <AiOutlineCheck color={cd.key === filter.attr ? "#61CEF7" : "transparent"} />
                            {cd.label}
                        </div>
                    </div>
                ))}
            </div>}>
            <div style={{ position: 'relative' }}>
                <input style={{ minHeight: 40, width: 220, minWidth: 220, cursor: 'pointer', color: 'white', background: '#171717', border: ' 1px solid #353738', borderRadius: '2px', marginTop: 0 }} placeholder="Select Attribute" readOnly className="lockr-data-endpoint-input" value={attributes?.find(a => a.key === filter?.config?.operand)?.label}></input>
                <div style={{ position: 'absolute', top: 8, right: 10 }}>
                    {editEnabled && <img src={arrowWhiteDown} />}
                </div>
            </div>

        </Dropdown>

        {/* DROPDOWN FOR CONDITIONS */}
        {displayAttributesDropdown && <Dropdown
            disabled={!(editEnabled)}
            trigger={["click"]}
            open={isOperatorDropdownOpen}
            onOpenChange={(visible) => setIsOperatorDropdownOpen(visible)}
            overlay={
                <div style={{ background: 'black', padding: 8, width: 170, cursor: 'pointer' }}>
                    {attributes.find(a => a.key === filter.config.type)?.type === "source" ?
                        attributes.find(a => a.key === filter?.config?.type).acceptedValues.map(cd => (
                            <div onClick={() => {
                                if (cd.acceptedValues) {
                                    let tempFilter = JSON.parse(JSON.stringify(filter))
                                    tempFilter.config.operand = cd.key
                                    tempFilter.config.value = ""
                                    tempFilter.config.type = "source"
                                    tempFilter.config.operator = "is"
                                    updateSelectedCohortFilters(tempFilter)
                                    setIsOperatorDropdownOpen(false)
                                }
                                else {
                                    let tempFilter = JSON.parse(JSON.stringify(filter))
                                    tempFilter.config.operand = cd.key
                                    tempFilter.config.value = ""
                                    tempFilter.config.type = "source"
                                    tempFilter.config.operator = "is"
                                    updateSelectedCohortFilters(tempFilter)
                                    setIsOperatorDropdownOpen(false)
                                }
                            }} style={{ color: 'white', padding: 4 }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter?.config?.operand ? "#61CEF7" : "transparent"} />{cd.label}</div>
                            </div>
                        )) : attributes.find(a => a.key === filter.config.operand)?.type === "date" ? dateConditionDropdown.map(cd => (
                            <div onClick={() => {
                                let tempFilter = JSON.parse(JSON.stringify(filter));
                                tempFilter.config.operator = cd.key
                                tempFilter.config.value = ""
                                tempFilter.config.type = "date"
                                updateSelectedCohortFilters(tempFilter)
                                setIsOperatorDropdownOpen(false)
                            }} style={{ color: 'white', padding: 4 }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter.config.operator ? "#61CEF7" : "transparent"} />{cd.label}</div>
                            </div>
                        )) : attributes.find(a => a.key === filter.config.operand)?.type === "boolean" ? booleanConditionDropdown.map(cd => (
                            <div onClick={() => {
                                let tempFilter = JSON.parse(JSON.stringify(filter));
                                tempFilter.config.operator = cd.key
                                tempFilter.config.value = ""
                                tempFilter.config.type = "boolean"
                                updateSelectedCohortFilters(tempFilter)
                                setIsOperatorDropdownOpen(false)
                            }} style={{ color: 'white', padding: 4 }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter.config.operator ? "#61CEF7" : "transparent"} />{cd.label}</div>
                            </div>
                        )) : attributes.find(a => a.key === filter.config.operand)?.type === "number" ? 
                            numberConditionDropdown.map(cd => (
                                <div onClick={() => {
                                    let tempFilter = JSON.parse(JSON.stringify(filter));
                                    tempFilter.config.operator = cd.key
                                    tempFilter.config.value = ""
                                    tempFilter.config.type = "number"
                                    updateSelectedCohortFilters(tempFilter)
                                    setIsOperatorDropdownOpen(false)
                                }} style={{ color: 'white', padding: 4 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                        <AiOutlineCheck color={cd.key === filter.config.operator ? "#61CEF7" : "transparent"} />
                                        {cd.label}
                                    </div>
                                </div>
                            )) :
                            conditionDropdown.map(cd => (
                                <div onClick={() => {
                                    let tempFilter = JSON.parse(JSON.stringify(filter));
                                    tempFilter.config.operator = cd.key
                                    tempFilter.config.value = ""
                                    tempFilter.config.type = "string"
                                    updateSelectedCohortFilters(tempFilter)
                                    setIsOperatorDropdownOpen(false)
                                }} style={{ color: 'white', padding: 4 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter.config.operator ? "#61CEF7" : "transparent"} />{cd.label}</div>
                                </div>
                            ))}
                </div>
            }>
            <div style={{ position: 'relative' }}>
                <input style={{ minHeight: 40, width: 220, minWidth: 220, cursor: 'pointer', color: 'white', background: '#171717', border: ' 1px solid #353738', borderRadius: '2px', marginTop: 0 }} placeholder="Select Condition" readOnly className="lockr-data-endpoint-input"
                    value={filter?.config?.type === "source" ? attributes.find(a => a.key === filter.config.type).acceptedValues.find(ac => ac.key === filter.config.operand)?.label : filter?.config?.type == "date" ? dateConditionDropdown.find(cd => cd.key === filter.config.operator)?.label ?? '' : filter?.config?.type === "boolean" ? booleanConditionDropdown.find(cd => cd.key === filter.config.operator)?.label ?? '' : filter?.config?.type === "number" ? numberConditionDropdown.find(cd => cd.key === filter.config.operator)?.label ?? '' : conditionDropdown.find(cd => cd.key === filter.config.operator)?.label ?? ''}></input>
                <div style={{ position: 'absolute', top: 8, right: 10 }}>
                    {editEnabled && <img src={arrowWhiteDown} />}
                </div>
            </div>

        </Dropdown>}



        {/* Value picker */}
        {filter.config.operator && (filter.config.type === 'source' ?
            <Dropdown
                disabled={!(editEnabled)}
                trigger={["click"]}
                open={isValueDropdownOpen}
                onOpenChange={(visible) => setIsValueDropdownOpen(visible)}
                overlay={
                    <div style={{ background: 'black', padding: 8, cursor: 'pointer', height: 180, overflowY: 'scroll' }}>
                        {attributes.find(a => a.key === filter.config.type).acceptedValues.find(ac => ac.key === filter.config.operand)?.acceptedValues.map(ac =>
                            <div onClick={() => {
                                let tempFilter = JSON.parse(JSON.stringify(filter));
                                tempFilter.config.value = ac.value
                                updateSelectedCohortFilters(tempFilter)
                                setIsValueDropdownOpen(false)
                            }} style={{ color: 'white', padding: 4 }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={filter.config.value === ac.value ? "#61CEF7" : "transparent"} />{ac.label}</div>
                            </div>)}
                    </div>}>
                <div style={{ position: 'relative', flex: 1, height: 40, marginTop: 0, background: '#26282a' }}>
                    <input value={filter.config.type === 'source'
                        ? attributes.find(a => a.key === filter.config.type)
                            ?.acceptedValues.find(av => av.key === filter.config.operand)
                            ?.acceptedValues.find(v => v.value === filter.config.value)?.label || filter.config.value
                        : filter.config.value}
                        style={{ paddingLeft: 8, backgroundColor: '#171717', cursor: 'pointer', color: 'white', minHeight: 40, marginTop: 0, background: '#171717', border: '1px solid #353738', width: '100%' }} placeholder="Select Value" readOnly className=""></input>
                    <div style={{ position: 'absolute', top: 8, right: 10 }}>
                        {editEnabled && <img src={arrowWhiteDown} />}
                    </div>
                </div>

            </Dropdown>
            : filter.config.operator === 'isNull' ?
                <Dropdown disabled={!(editEnabled)} trigger={["click"]} overlay={
                    <div style={{ background: 'black', padding: 8, width: 170, cursor: 'pointer' }}>
                        <div onClick={() => {
                            let tempFilter = JSON.parse(JSON.stringify(filter));
                            tempFilter.config.value = true
                            updateSelectedCohortFilters(tempFilter)
                            setIsOperatorDropdownOpen(false)
                        }} style={{ color: 'white', padding: 4 }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={filter.config.value ? "#61CEF7" : "transparent"} />{"True"}</div>
                        </div>
                        <div onClick={() => {
                            let tempFilter = JSON.parse(JSON.stringify(filter));
                            tempFilter.config.value = false
                            updateSelectedCohortFilters(tempFilter)
                            setIsOperatorDropdownOpen(false)
                        }} style={{ color: 'white', padding: 4 }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={(filter.config.value === false) ? "#61CEF7" : "transparent"} />{"False"}</div>
                        </div>
                    </div>}>
                    <div style={{ position: 'relative', flex: 1, height: 40, marginTop: 0, background: '#26282a' }}>
                        <input value={filter.config.value === "" ? "" : ((filter.config.value === false) ? "False" : "True")}
                            style={{ backgroundColor: '#171717', cursor: 'pointer', color: 'white', minHeight: 40, marginTop: 0, background: '#171717', border: '1px solid #353738', width: '100%' }} placeholder="Select Value" readOnly className=""></input>
                        <div style={{ position: 'absolute', top: 8, right: 10 }}>
                            {editEnabled && <img src={arrowWhiteDown} />}
                        </div>
                    </div>

                </Dropdown> : filter.config.operator === 'between' ?
                    <>
                        <DatePicker suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>} dropdownClassName="calender-date-picker" className="dashboard-date-picker" format={customFormat} disabled={!(editEnabled)} value={filter.config.value[0] ? moment(filter.config.value[0]) : ""} onChange={(e) => {
                            let tempFilter = JSON.parse(JSON.stringify(filter));
                            if (typeof tempFilter.config.value === 'string')
                                tempFilter.config.value = []
                            tempFilter.config.value[0] = moment(e).format("yyyy-MM-DD")
                            updateSelectedCohortFilters(tempFilter)
                            setIsOperatorDropdownOpen(false)
                        }} placeholder={conditionDropdown.find(c => c.key === filter.config.operator)?.label}
                            style={{ background: '#171717', border: '1px solid #353738', borderRadius: 2, padding: 8, height: 40, color: 'white', flex: 1, marginTop: 0 }} />
                        <DatePicker suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>} dropdownClassName="calender-date-picker" className="dashboard-date-picker" format={customFormat} disabled={!(editEnabled)} value={filter.config.value[1] ? moment(filter.config.value[1]) : ""} onChange={(e) => {
                            let tempFilter = JSON.parse(JSON.stringify(filter));
                            if (typeof tempFilter.config.value === 'string')
                                tempFilter.config.value = []
                            tempFilter.config.value[1] = moment(e).format("yyyy-MM-DD")
                            updateSelectedCohortFilters(tempFilter)
                            setIsOperatorDropdownOpen(false)
                        }} placeholder={conditionDropdown.find(c => c.key === filter.config.operator)?.label}
                            style={{ background: '#171717', border: '1px solid #353738', borderRadius: 2, padding: 8, height: 40, color: 'white', flex: 1, marginTop: 0 }} />
                    </> : filter.config.type === 'date' ?

                        <DatePicker suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>} dropdownClassName="calender-date-picker" className="dashboard-date-picker" format={customFormat} disabled={!(editEnabled)} value={filter.config.value ? moment(filter.config.value) : ""} onChange={(e) => {
                            let tempFilter = JSON.parse(JSON.stringify(filter));

                            tempFilter.config.value = moment(e).format("yyyy-MM-DD")
                            updateSelectedCohortFilters(tempFilter)
                            setIsOperatorDropdownOpen(false)
                        }} placeholder={conditionDropdown.find(c => c.key === filter.config.operator)?.label}
                            style={{
                                background: '#171717', border: '1px solid #353738', borderRadius: 2, padding: 8, height: 40, color: 'white', flex: 1, marginTop: 0
                            }} />




                        : filter.config.type === "boolean" ? booleanConditionDropdown.find(cd => cd.key === filter.config.value)?.label :
                            <input disabled={!(editEnabled)} value={filter.config.value} onChange={(e) => {
                                let tempFilter = JSON.parse(JSON.stringify(filter));
                                tempFilter.config.value = e.target.value
                                updateSelectedCohortFilters(tempFilter)
                                setIsOperatorDropdownOpen(false)
                            }} placeholder={conditionDropdown.find(c => c.key === filter.config.operator)?.label}
                                style={{ background: '#171717', border: '1px solid #353738', borderRadius: 2, padding: 8, height: 40, color: 'white', flex: 1, marginTop: 0 }} />
        )}

        {/* Search Value Picker for type searchSelect */}
        {filter.config.type === 'searchSelect' && (
            <Dropdown
                disabled={!(editEnabled)}
                trigger={["click"]}
                open={isSearchSelectDropdownOpen}
                onOpenChange={(visible) => setIsSearchSelectDropdownOpen(visible)}
                overlay={
                    <div style={{ background: 'black', padding: 8, cursor: 'pointer', maxHeight: 180, overflowY: 'scroll' }}>
                        <div style={{ color: 'white', padding: 4 }}>
                            {searchSelectOptions?.map((ac, index) => (
                                <div key={index} onClick={() => {
                                    let tempFilter = JSON.parse(JSON.stringify(filter));
                                    tempFilter.config.value = ac;
                                    updateSelectedCohortFilters(tempFilter);
                                    setIsSearchSelectDropdownOpen(false);
                                }} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                    <AiOutlineCheck color={filter.config.value === ac ? "#61CEF7" : "transparent"} />
                                    {ac}
                                </div>
                            ))}
                        </div>
                    </div>
                }>
                <div style={{ position: 'relative', flex: 1, height: 40, marginTop: 0, background: '#26282a' }}>
                    <input 
                        onChange={(e) => {
                            let tempFilter = JSON.parse(JSON.stringify(filter));
                            tempFilter.config.value = e.target.value;
                            updateSelectedCohortFilters(tempFilter);
                            setSearchSelectOptions(
                                attributes.find(a => a.key === filter.config.operand)?.acceptedValues.filter(
                                    ac => ac.toLowerCase().includes(e.target.value.toLowerCase())
                                )
                            );
                        }} 
                        value={filter.config.value}
                        style={{ 
                            backgroundColor: '#171717', 
                            cursor: 'pointer', 
                            color: 'white', 
                            minHeight: 40, 
                            marginTop: 0, 
                            background: '#171717', 
                            border: '1px solid #353738', 
                            width: '100%', 
                            paddingLeft: 8 
                        }} 
                        placeholder="Select Value" 
                        className=""
                    />
                    <div style={{ position: 'absolute', top: 8, right: 10 }}>
                        {editEnabled && <img src={arrowWhiteDown} />}
                    </div>
                </div>
            </Dropdown>
        )}
        


        <div>
            {(editEnabled ) && <img src={deleteIcon} alt="delete" onClick={() => {
                if (editEnabled) {
                    onRemoveFilter()
                    setIsConditionDropdownOpen(false)
                    setIsAttributeDropdownOpen(false)
                    setIsOperatorDropdownOpen(false)
                    setIsValueDropdownOpen(false)
                }
            }} cursor={'pointer'} style={{ height: 24, width: 21.6 }} />}
        </div>
    </div>
}

const ParentFilter = ({ parent, parentIndex, filter, updateSelectedCohortFilters, attributes, selectedCohort }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [isConditionDropdownOpen, setIsConditionDropdownOpen] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const renderDeleteModal = () => {
        return (
            <Modal
                        visible={showDeleteModal}
                        onCancel={() => setShowDeleteModal(false)}
                        footer={null}
                        style={{backgroundColor: '#353738', padding: '0px'}}
                        bodyStyle={{backgroundColor: '#353738'}}
                        maskClosable={false}
                        width={716}
                        closeIcon={<CloseOutlined style={{color: 'white'}}/>}
                    >
                        <div style={{padding: '24px', color: 'white', backgroundColor: '#353738'}}>
                            <div style={{
                                fontSize: '16px',
                                marginBottom: '32px',
                                fontWeight: 400,
                                fontFamily: 'Montserrat'
                            }}>
                                It looks like you are trying to delete a parent filter. This will delete all the subsequent boolean settings and will not be recoverable. Do you want to continue.
                            </div>
                            <div style={{
                                display: 'flex',
                                gap: '16px'
                            }}>
                                <Button
                                    onClick={() => {
                                        
                                        setShowDeleteModal(false);
                                        setIsConditionDropdownOpen(false);
                                        let tempFilter = JSON.parse(JSON.stringify(filter))
                                        tempFilter.appliedFilters.splice(parentIndex, 1)
                                        updateSelectedCohortFilters(tempFilter)
                                    }}
                                    style={{
                                        height: '40px',
                                        background: '#61CEF7',
                                        border: '1px solid #61CEF7',
                                        borderRadius: '2px',
                                        fontFamily: '"Montserrat", sans-serif',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        fontSize: '14px',
                                        color: '#26282A',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '0 16px'
                                    }}
                                >
                                    Yes, delete
                                </Button>
                                <Button
                                    onClick={() => setShowDeleteModal(false)}
                                    style={{
                                        height: '40px',
                                        background: 'transparent',
                                        border: '1px solid #61CEF7',
                                        borderRadius: '2px',
                                        fontFamily: '"Montserrat", sans-serif',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        fontSize: '14px',
                                        color: '#61CEF7',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '0 16px'
                                    }}
                                >
                                    No, cancel
                                </Button>
                            </div>
                        </div>
                    </Modal>
        )
    }

    return (
        <div style={{ background: '#1D1D1D', padding: 20, borderRadius: 8, marginTop: 10, marginBottom: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginBottom: 12 }}>
            </div>
            {renderDeleteModal()}

            {parent.config.map((item, itemIndex) => (
                <div key={itemIndex}>
                    
                    {item.type === "filter" ? (
                        <SingleFilter
                            displayAttributesDropdown={filter.appliedFilters[parentIndex].config[itemIndex].config.type !== 'searchSelect'}
                            parentLength={parent.config.length}
                            onRemoveFilter={() => {
                                if (itemIndex === 0 && parent.config.length > 1) {
                                    setShowDeleteModal(true)
                                    return;
                                }
                                
                                let tempFilter = JSON.parse(JSON.stringify(filter))
                                tempFilter.appliedFilters[parentIndex].config.splice(itemIndex, 1)
                                if (tempFilter.appliedFilters[parentIndex].config.length === 0) {
                                    tempFilter.appliedFilters.splice(parentIndex, 1)
                                }
                                updateSelectedCohortFilters(tempFilter)
                            }}
                            displayCondition={itemIndex > 0 || parentIndex > 0}
                            attributes={attributes}
                            filter={{
                                ...item,
                                config: {
                                    ...item.config,
                                    condition: itemIndex === 0 ? parent.condition : item.config.condition
                                }
                            }}
                            index={itemIndex}
                            selectedCohort={selectedCohort}
                            editEnabled
                            updateSelectedCohortFilters={(updatedFilter) => {
                                let tempFilter = JSON.parse(JSON.stringify(filter))
                                if (itemIndex === 0) {
                                    tempFilter.appliedFilters[parentIndex].condition = updatedFilter.config.condition
                                }
                                tempFilter.appliedFilters[parentIndex].config[itemIndex] = updatedFilter
                                updateSelectedCohortFilters(tempFilter)
                            }}
                        />
                    ) : item.type === "filterGroup" && (
                        <div style={{ background: '#262626', padding: 20, borderRadius: 8, marginTop: 10, marginBottom: 10 }}>
                            {item.config.map((groupItem, groupItemIndex) => (
                                <SingleFilter
                                displayAttributesDropdown={filter?.config?.type === 'searchSelect'}
                                    key={groupItemIndex}
                                    onRemoveFilter={() => {
                                        let tempFilter = JSON.parse(JSON.stringify(filter))
                                        if (groupItemIndex === 0) {
                                            if (item.config.length > 1) {
                                                tempFilter.appliedFilters[parentIndex].config[itemIndex].config.splice(0, 1)
                                                tempFilter.appliedFilters[parentIndex].config[itemIndex].config[0].condition = 'AND'
                                            } else {
                                                tempFilter.appliedFilters[parentIndex].config.splice(itemIndex, 1)
                                            }
                                        } else {
                                            tempFilter.appliedFilters[parentIndex].config[itemIndex].config.splice(groupItemIndex, 1)
                                        }
                                        updateSelectedCohortFilters(tempFilter)
                                    }}
                                    attributes={attributes}
                                    filter={{
                                        config: {
                                            ...groupItem,
                                            condition: groupItemIndex === 0 ? 'AND' : groupItem.condition
                                        }
                                    }}
                                    index={groupItemIndex}
                                    selectedCohort={selectedCohort}
                                    editEnabled={true}
                                    displayCondition={true}
                                    conditionDropdownDisabled={groupItemIndex === 0}
                                    updateSelectedCohortFilters={(updatedFilter) => {
                                        let tempFilter = JSON.parse(JSON.stringify(filter))
                                        tempFilter.appliedFilters[parentIndex].config[itemIndex].config[groupItemIndex] = {
                                            ...updatedFilter.config,
                                            condition: groupItemIndex === 0 ? 'AND' : updatedFilter.config.condition
                                        }
                                        updateSelectedCohortFilters(tempFilter)
                                    }}
                                />
                            ))}
                            <div style={{ display: 'flex', gap: '4px' }}>
                                <button
                                    onClick={() => {
                                        let tempFilter = JSON.parse(JSON.stringify(filter))
                                        tempFilter.appliedFilters[parentIndex].config[itemIndex].config.push({
                                            condition: 'AND',
                                            operand: '',
                                            operator: '',
                                            type: '',
                                            value: '',
                                        })
                                        updateSelectedCohortFilters(tempFilter)
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '6px 12px',
                                        borderRadius: '2px',
                                        background: "#26282A",
                                        border: "1px solid #353738",
                                        color: '#FFFFFF',
                                        fontSize: '13px',
                                        fontWeight: '600',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        gap: '4px'
                                    }}>
                                    <img src={plusCircle} style={{ width: 14, height: 14 }} />
                                    AND
                                </button>
                                <button
                                    onClick={() => {
                                        let tempFilter = JSON.parse(JSON.stringify(filter))
                                        tempFilter.appliedFilters[parentIndex].config[itemIndex].config.push({
                                            condition: 'OR',
                                            operand: '',
                                            operator: '',
                                            type: '',
                                            value: '',
                                        })
                                        updateSelectedCohortFilters(tempFilter)
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '6px 12px',
                                        borderRadius: '2px',
                                        background: "#26282A",
                                        border: "1px solid #353738",
                                        color: '#FFFFFF',
                                        fontSize: '13px',
                                        fontWeight: '600',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        gap: '4px'
                                    }}>
                                    <img src={plusCircle} style={{ width: 14, height: 14 }} />
                                    OR
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            ))}

            <div style={{ display: 'flex', gap: '4px', marginTop: 12, position: 'relative' }}>
                <button
                    onClick={() => setShowOptions(!showOptions)}
                    style={{
                        cursor: 'pointer',
                        padding: '9px 12px',
                        borderRadius: '2px',
                        background: "#26282A",
                        border: "1px solid #353738",
                        color: '#FFFFFF',
                        fontSize: '13px',
                        fontWeight: '600',
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '4px',
                        zIndex: 1
                    }}>
                    <img src={plusCircle} style={{ width: 14, height: 14 }} />
                </button>

                <div style={{
                    position: 'absolute',
                    left: '45px',
                    display: 'flex',
                    gap: '4px',
                    transition: 'all 0.3s ease',
                    opacity: showOptions ? 1 : 0,
                    transform: showOptions ? 'translateX(0)' : 'translateX(-20px)',
                    pointerEvents: showOptions ? 'auto' : 'none',
                }}>
                    <button
                        onClick={() => {
                            let tempFilter = JSON.parse(JSON.stringify(filter))
                            tempFilter.appliedFilters[parentIndex].config.push({
                                type: 'filter',
                                config: {
                                    condition: 'AND',
                                    operand: '',
                                    operator: '',
                                    type: '',
                                    value: '',
                                }
                            })
                            updateSelectedCohortFilters(tempFilter)
                            setShowOptions(false)
                        }}
                        style={{
                            cursor: 'pointer',
                            padding: '6px 12px',
                            borderRadius: '2px',
                            background: "#26282A",
                            border: "1px solid #353738",
                            color: '#FFFFFF',
                            fontSize: '13px',
                            fontWeight: '600',
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: '4px'
                        }}>
                        AND
                    </button>
                    <button
                        onClick={() => {
                            let tempFilter = JSON.parse(JSON.stringify(filter))
                            tempFilter.appliedFilters[parentIndex].config.push({
                                type: 'filter',
                                config: {
                                    condition: 'OR',
                                    operand: '',
                                    operator: '',
                                    type: '',
                                    value: '',
                                }
                            })
                            updateSelectedCohortFilters(tempFilter)
                            setShowOptions(false)
                        }}
                        style={{
                            cursor: 'pointer',
                            padding: '6px 12px',
                            borderRadius: '2px',
                            background: "#26282A",
                            border: "1px solid #353738",
                            color: '#FFFFFF',
                            fontSize: '13px',
                            fontWeight: '600',
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: '4px'
                        }}>
                        OR
                    </button>
                    <button
                        onClick={() => {
                            let tempFilter = JSON.parse(JSON.stringify(filter))
                            tempFilter.appliedFilters[parentIndex].config.push({
                                type: 'filterGroup',
                                config: [{
                                    condition: 'AND',
                                    operand: '',
                                    operator: '',
                                    type: '',
                                    value: '',
                                }]
                            })
                            updateSelectedCohortFilters(tempFilter)
                            setShowOptions(false)
                        }}
                        style={{
                            cursor: 'pointer',
                            padding: '6px 12px',
                            borderRadius: '2px',
                            background: "#26282A",
                            border: "1px solid #353738",
                            color: '#FFFFFF',
                            fontSize: '13px',
                            fontWeight: '600',
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: '4px'
                        }}>
                        <img src={filterGroup} style={{ width: 14, height: 14 }} />
                        Group
                    </button>
                </div>
            </div>
        </div>
    );
};

export function CustomConditionsBuilder({
    selectedCohort,
    attributes,
    editEnabled,
    updateSelectedCohortConditions,
    updateSelectedCohortFilters,
    saveAsCurrentSegment,
    saveAsNewSegment,
    isUpdating
}) {
    const { configuration: filter } = selectedCohort;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showOptions, setShowOptions] = useState(false);

    return <>
        <div style={{ borderRadius: 12, marginTop: 8, background: '#171717', padding: 24, color: 'white', width: '100%' }} >
            <div style={{ marginBottom: 20, fontWeight: 700, fontSize: 16 }}>
                Filter Segment
            </div>
            <div className="cohort-builder">
                {filter?.appliedFilters?.map((parent, parentIndex) => (
                    <ParentFilter
                        key={parentIndex}
                        parent={parent}
                        parentIndex={parentIndex}
                        filter={filter}
                        updateSelectedCohortFilters={updateSelectedCohortFilters}
                        attributes={attributes}
                        selectedCohort={selectedCohort}
                    />
                ))}
            </div>

            <div style={{
                height: 53, marginTop: 20,
            }}>
                <div style={{ width: 174, cursor: 'pointer' }}>
                    <div style={{ display: 'flex', gap: '4px', marginTop: 12, position: 'relative' }}>
                        <button
                            onClick={() => {
                                if (!filter?.appliedFilters?.length) {
                                    // If no parents exist, directly add first parent with AND
                                    updateSelectedCohortFilters({
                                        ...filter,
                                        appliedFilters: [{
                                            type: 'parent',
                                            condition: 'AND',
                                            config: [{
                                                type: 'filter',
                                                config: {
                                                    condition: 'AND',
                                                    operand: '',
                                                    operator: '',
                                                    type: '',
                                                    value: ''
                                                }
                                            }]
                                        }]
                                    });
                                } else {
                                    // Show animation and options for subsequent parents
                                    setShowOptions(!showOptions);
                                }
                            }}
                            style={{
                                cursor: 'pointer',
                                padding: '6px 12px',
                                borderRadius: '2px',
                                background: "#26282A",
                                border: "1px solid #353738",
                                color: '#FFFFFF',
                                fontSize: '13px',
                                fontWeight: '600',
                                display: 'inline-flex',
                                alignItems: 'center',
                                gap: '4px',
                                zIndex: 1
                            }}>
                            <img src={plusCircle} style={{ width: 14, height: 14 }} /> Add search rule
                        </button>

                        {filter?.appliedFilters?.length > 0 && (
                            <div style={{
                                position: 'absolute',
                                left: '154px',
                                display: 'flex',
                                gap: '4px',
                                transition: 'all 0.3s ease',
                                opacity: showOptions ? 1 : 0,
                                transform: showOptions ? 'translateX(0)' : 'translateX(-20px)',
                                pointerEvents: showOptions ? 'auto' : 'none',
                            }}>
                                <button
                                    onClick={() => {
                                        updateSelectedCohortFilters({
                                            ...filter,
                                            appliedFilters: [...filter?.appliedFilters, {
                                                type: 'parent',
                                                condition: 'AND',
                                                config: [{
                                                    type: 'filter',
                                                    config: {
                                                        condition: 'AND',
                                                        operand: '',
                                                        operator: '',
                                                        type: '',
                                                        value: ''
                                                    }
                                                }]
                                            }]
                                        })
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '6px 12px',
                                        borderRadius: '2px',
                                        background: "#26282A",
                                        border: "1px solid #353738",
                                        color: '#FFFFFF',
                                        fontSize: '13px',
                                        fontWeight: '600',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        gap: '4px'
                                    }}>
                                    AND
                                </button>
                                <button
                                    onClick={() => {
                                        updateSelectedCohortFilters({
                                            ...filter,
                                            appliedFilters: [...filter?.appliedFilters, {
                                                type: 'parent',
                                                condition: 'OR',
                                                config: [{
                                                    type: 'filter',
                                                    config: {
                                                        condition: 'AND',
                                                        operand: '',
                                                        operator: '',
                                                        type: '',
                                                        value: ''
                                                    }
                                                }]
                                            }]
                                        })
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '6px 12px',
                                        borderRadius: '2px',
                                        background: "#26282A",
                                        border: "1px solid #353738",
                                        color: '#FFFFFF',
                                        fontSize: '13px',
                                        fontWeight: '600',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        gap: '4px'
                                    }}>
                                    OR
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>


        </div >
    </>
}
