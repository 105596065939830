import React, { useEffect, useMemo, useState } from "react";
import SideDrawer from "../../Components/SideDrawer";
import { Button, Checkbox, Col, Modal, Row, Switch, Tooltip as ToolTip, Typography } from "antd";
import moment from "moment/moment";
import { identitProvidersType } from "../../Utils/Helper";
import { UIDNoCredentialModal } from "../../Components/UIDNoCredentialModal";
import { TitleTextPassword } from "../../Components/TitleTextPassword";
import { LockrInfoIcon } from "../../Components/UIDCredentialsModal";
import { getDeletedUIDCredentialsAPI, getIdentityProviderSetting, getUIDAnalytics, getUIDConnectedEmailCount, getUIDConsentedUsers, getUIDCredentialsAPI, getUIDUserGraphData, removeUIDIntegration, upsertIdentityProviderSetting, upsertUIDCredentialsAPI } from "../../Utils/ApiActions";
import { IdentityProviderStatsAuthStrip } from "./components/IdentityProviderStatsAuthStrip";
import { IdentityProviderGraphStrip } from "./components/IdentityProviderlockrGraphStrip";

function EpsilonCoreIDCredentialsModal({
    display, onOk, onCancel, error, credentials, onHideError = () => {
    },
}) {

    const [modalCredentials, setModalCredentials] = useState(credentials ?? {
        accessKey: "", secretKey: "", useLockrKeys: false
    });

    function renderTNCLine() {
        return (
            <div style={{ marginTop: 24, color: 'white', fontSize: 14, fontFamily: 'Montserrat' }}>
                Don't have Epsilon Core ID API Key? <span
                    style={{ color: '#61cef7', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => {
                        window.open("https://www.epsilon.com/us", "_blank");
                    }}>Click here</span> to create an account.
            </div>);
    }

    return (
        <Modal
            centered
            width={750}
            footer={null}
            closable={false}
            bodyStyle={{
                minHeight: 460,
                width: 750,
                backgroundColor: "#353738",
                paddingRight: 60,
                paddingLeft: 60,
                paddingTop: 60,
                overflowX: "hidden",
            }}
            destroyOnClose
            style={{
                top: 20,
                paddingBottom: 20,
            }}
            visible={display}
        >
            <img
                style={{ right: 60 }}
                alt="close"
                onClick={onCancel}
                src={require("../../assets/modal-close.png")}
                className="delete-x-button-connection"
            />
            <div>
                <Typography.Title
                    style={{
                        display: "inline-block",
                        fontFamily: "Montserrat",
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: 20,
                        paddingBottom: 40,
                        paddingTop: 8,
                    }}
                >
                    {"Settings"}
                </Typography.Title>
            </div>
            <div style={{ marginTop: 16 }} />
            <TitleTextPassword
                placeholder={" "}
                label={"Epsilon API Key"}
                marginLeft={98}
                eyeRight={90}
                disabled={modalCredentials.useLockrKeys}
                supportCopy
                supportDisabledColor
                value={
                    modalCredentials?.useLockrKeys
                        ? "***********"
                        : modalCredentials?.accessKey
                }
                hideEye={modalCredentials.useLockrKeys}
                onChange={(event) =>
                    setModalCredentials({
                        ...modalCredentials,
                        accessKey: event.target.value,
                    })
                }
            />
            <div style={{ marginTop: 24, display: "flex", alignItems: "center" }}>
                <Checkbox
                    checked={modalCredentials.useLockrKeys}
                    onChange={(event) => {
                        setModalCredentials({
                            ...modalCredentials,
                            useLockrKeys: event.target.checked,
                        });
                    }}
                />
                <span
                    style={{
                        marginLeft: 16,
                        color: "white",
                        fontSize: 16,
                        fontFamily: "Montserrat",
                    }}
                >
                    Use lockrKeys
                </span>
                <ToolTip
                    style={{
                        cursor: "pointer",
                    }}
                    title="Publishers can use their own credentials with service providers or choose to rely on lockr's pre-established connection."
                >
                    <div
                        style={{
                            marginLeft: 16,
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <LockrInfoIcon />
                    </div>
                </ToolTip>
            </div>
            <div style={{ marginTop: 16 }} />
            <div style={{ marginTop: 24, display: "flex", alignItems: "center" }}>
                <Button
                    style={{ width: 217, marginTop: 24, cursor: 'pointer' }}
                    type="primary"
                    className="sign-in"
                    onClick={() => {
                        onOk(modalCredentials);
                    }}
                >
                    Start Connection
                </Button>
            </div>
            <div>
                <span className="errorText" style={{ marginTop: 16 }}>{error}</span>
            </div>
            {renderTNCLine()}
        </Modal>
    );
}

export function EpsilonCoreID() {

    const dateFormat = "dddd MM/DD";

    const [displayLoading, setDisplayLoading] = useState(false);
    const [displayCredentialsModal, setDisplayCredentialsModal] = useState(false);
    const [graphFilterEndDate, setGraphFilterEndDate] = useState(moment());
    const [loginTrendsAppIDs, setLoginTrendsAppIDs] = useState([]);
    const [authDataAppIDs, setAuthDataAppIDs] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [userAuthDataEndDate, setUserAuthDataEndDate] = useState(moment());
    const [userAuthDataStartDate, setUserAuthDataStartDate] = useState(moment().subtract(7, "days"));
    const [epsilonCoreIdCredentials, setEpsilonCoreIdCredentials] = useState(undefined);
    const [userCount, setUserCount] = useState(0);
    const [totalConnectedEmail, setTotalConnectedEmails] = useState(0);
    const [consentedUserCount, setConsentedUserCount] = useState(0);
    const [deletedCredentials, setDeletedCredentials] = useState(undefined);
    const [upsertError, setUpsertError] = useState(undefined);
    const [endDate, setEndDate] = useState(
        moment().add(1, "day").format("yyyy-MM-DD")
    );
    const [startDate, setStartDate] = useState(
        moment().subtract(1, "month").format("yyyy-MM-DD")
    );
    const [analyticsData, setAnalyticsData] = useState([]);
    const [userAuthenticationData, setUserAuthenticationData] =
        useState(undefined);
    const [graphFilterStartDate, setGraphFilterStartDate] = useState(
        moment().subtract(7, "days")
    );
    const [lockrMailAppIDs, setLockrMailAppIDs] = useState([]);
    const [identityProviderSettingLoading, setidentityProviderSettingLoading] =
        useState(false);
    const [identityProviderSettings, setIdentityProviderSetting] = useState({
        dropCookie: false,
        dropLocalStorage: false,
        keepInIdentityLockr: false,
    });
    const [originalIdentityProviderSettings, setoriginalIdentityProviderSetting] =
        useState({
            dropCookie: false,
            dropLocalStorage: false,
            keepInIdentityLockr: false,
        });

    const ssoUserCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType === "lockrMail") {
                    result = parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);

    const utmUserCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType === "UTM") {
                    result = parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);

    const emailCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType !== "UTM" && item.eventType !== "lockrMail") {
                    result += parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);

    useEffect(() => {
        window.scrollTo({
            top: 0, left: 0
        });
        getEpsilonCoreIdCredentials();
    }, []);


    useEffect(() => {
        epsilonCoreIdCredentials?.accessKey && getIdentityProviderSettingsCall();
    }, [epsilonCoreIdCredentials]);

    useEffect(() => {
        epsilonCoreIdCredentials?.accessKey && getUserStats();
        epsilonCoreIdCredentials?.accessKey && getUIDAnalyticsCall();
    }, [epsilonCoreIdCredentials, startDate, endDate]);

    function getUserStats() {
        //SC-24105, Hide lockrMail
        // getTotalUsers();
        getTotalConnectedEmailCount();
        getTotalConsentedUsers();
    }

    async function getTotalConsentedUsers() {
        try {
            const response = await getUIDConsentedUsers(startDate, endDate, lockrMailAppIDs);
            if (response.success) {
                setConsentedUserCount(response.data);
            }
        } catch (error) {
            console.log("Unable to get the total consented users");
            console.log(error);
        }
    }

    async function getTotalConnectedEmailCount() {
        try {
            const response = await getUIDConnectedEmailCount(
                startDate,
                endDate,
                identitProvidersType.EPSILON_CORE_ID,
                lockrMailAppIDs
            );
            if (response.success) {
                setTotalConnectedEmails(response.data);
            }
        } catch (error) {
            console.log("Unable to get the connected email count");
            console.log(error);
        }
    }

    async function getGraphData() {
        try {
            const response = await getUIDUserGraphData(
                startDate,
                endDate,
                identitProvidersType.EPSILON_CORE_ID,
                loginTrendsAppIDs
            );
            if (response.success) {
                setGraphData(response.data.data);
            }
        } catch (error) {
            console.log("Unable to get the graph data at the moment");
            console.log(error);
        }
    }


    async function getUIDAnalyticsCall() {
        try {
            const resposne = await getUIDAnalytics(startDate, endDate);
            if (resposne.success) {
                const result = [];
                resposne.data.map((item, index) => {
                    if (index !== resposne.data.length - 1) {
                        result.push({
                            ...item,
                            visitorCount:
                                item.visitorCount === null ? 0 : parseInt(item.visitorCount),
                            tokenCount:
                                item.tokenCount === null ? 0 : parseInt(item.tokenCount),
                        });
                    }
                });
                setAnalyticsData(result);
            }
        } catch (error) {
            console.log("Unable to get the analytics at the moment");
            console.log(error);
        }
    }

    async function getIdentityProviderSettingsCall() {
        try {
            setidentityProviderSettingLoading(true);
            const { success, data } = await getIdentityProviderSetting(
                identitProvidersType.EPSILON_CORE_ID
            );
            if (success && data) {
                setIdentityProviderSetting({
                    dropCookie: data.dropCookie,
                    dropLocalStorage: data.dropLocalStorage,
                    keepInIdentityLockr: data.keepInIdentityLockr,
                });
                setoriginalIdentityProviderSetting({
                    dropCookie: data.dropCookie,
                    dropLocalStorage: data.dropLocalStorage,
                    keepInIdentityLockr: data.keepInIdentityLockr,
                });
            }
            setidentityProviderSettingLoading(false);
        } catch (error) {
            setidentityProviderSettingLoading(false);
            console.log(
                "Unable to get the identitty provider settings at the moment"
            );
            console.log(error);
        }
    }


    function displaySwitch() {
        return (
            <div>
                <Switch
                    checked={epsilonCoreIdCredentials !== undefined}
                    onChange={(checked) => {
                        !checked && removeEpsilonCoreIdIntegrationCall();
                    }}
                />
                <Typography.Text
                    onClick={() => {
                        setDisplayCredentialsModal(true);
                    }}
                    className="infosum-view-credentials-text"
                >
                    Settings
                </Typography.Text>
            </div>
        )
    }

    async function removeEpsilonCoreIdIntegrationCall() {
        try {
            const response = await removeUIDIntegration(identitProvidersType.EPSILON_CORE_ID);
            if (response.success && response.data) {
                setDeletedCredentials({ ...epsilonCoreIdCredentials });
                setEpsilonCoreIdCredentials(undefined);
            }
        } catch (error) {
            console.log("Unable to remove the epsilon core id integration at the moment");
            console.log(error);
        }
    }

    async function saveEpsilonCoreIdCredentials(credentials) {
        setDisplayLoading(true);
        try {
            const response = await upsertUIDCredentialsAPI(
                credentials.accessKey,
                '',
                identitProvidersType.EPSILON_CORE_ID,
                credentials?.useLockrKeys ?? false
            );
            if (!response.success) {
                setDisplayLoading(false);
                setUpsertError(response.error);
                return;
            }
            getEpsilonCoreIdCredentials();
            setDisplayCredentialsModal(false);
        } catch (error) {
            console.log("Unable to save the epsilon core id credentials at the moment");
            console.log(error);
        }
        setDisplayLoading(false);
    }

    async function getEpsilonCoreIdCredentials() {
        setDisplayLoading(true);
        try {
            const { data, success } = await getUIDCredentialsAPI(
                identitProvidersType.EPSILON_CORE_ID
            );
            if (success && data?.accessKey) {
                setEpsilonCoreIdCredentials(data);
            } else {
                getDeletedCredentials();
            }
        } catch (error) {
            console.log("Unable to get the epsilon core id credentials at the moment");
            console.log(error);
        }
        setDisplayLoading(false);
    }

    async function getDeletedCredentials() {
        try {
            const { data, success } = await getDeletedUIDCredentialsAPI(
                identitProvidersType.EPSILON_CORE_ID
            );
            if (success && data?.accessKey) {
                setDeletedCredentials({ ...data, isDeleted: false });
            }
        } catch (error) {
            console.log("Unable to get the deleted credentials at the moment");
            console.log(error);
        }
    }

    function dispalyConnectionModal() {
        if (!epsilonCoreIdCredentials && !displayCredentialsModal && !displayLoading) {
            return (<UIDNoCredentialModal
                integrationType={identitProvidersType.EPSILON_CORE_ID}
                onOk={() => { setDisplayCredentialsModal(true) }}
                display={true}
            />)
        }
    }

    async function upsertIdentityProviderSettingCall() {
        try {
            setidentityProviderSettingLoading(true);
            const { success, data } = await upsertIdentityProviderSetting(
                identitProvidersType.EPSILON_CORE_ID,
                identityProviderSettings.dropCookie,
                identityProviderSettings.dropLocalStorage,
                identityProviderSettings.keepInIdentityLockr
            );
            if (success && data) {
                setIdentityProviderSetting(data);
                getIdentityProviderSettingsCall();
            }
            setidentityProviderSettingLoading(false);
            setidentityProviderSettingLoading(false);
        } catch (error) {
            console.log(
                "Unable to upsert the identity provider setting at the moment"
            );
            console.log(error);
        }
    }

    function renderSettings() {
        return (
            <Row
                justify="space-between"
                align="middle"
                className="apiandservices-newapp-btn"
            >
                <div>
                    <Typography.Title className="apiandservices-heading">
                        Storage Settings
                    </Typography.Title>
                    <div style={{ marginTop: 32 }} />
                    <div style={{ display: "flex", flexDirection: "row", gap: 48 }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 6,
                                alignItems: "center",
                            }}
                        >
                            <Switch
                                size="small"
                                checked={identityProviderSettings.keepInIdentityLockr}
                                onChange={(checked) => {
                                    setIdentityProviderSetting({
                                        ...identityProviderSettings,
                                        keepInIdentityLockr: checked,
                                        dropCookie: checked,
                                        dropLocalStorage: checked,
                                    });
                                }}
                            />
                            <span
                                style={{
                                    color: "white",
                                    fontSize: 16,
                                    fontFamily: "Montserrat",
                                }}
                            >
                                Keep in AIM
                            </span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                            <Checkbox
                                onChange={(event) => {
                                    setIdentityProviderSetting({
                                        ...identityProviderSettings,
                                        dropCookie: !event.target.checked,
                                    });
                                }}
                                disabled={identityProviderSettings.keepInIdentityLockr}
                                checked={!identityProviderSettings.dropCookie}
                            />
                            <span
                                style={{
                                    color: "white",
                                    fontSize: 16,
                                    fontFamily: "Montserrat",
                                }}
                            >
                                Cookie
                            </span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                            <Checkbox
                                onChange={(event) => {
                                    setIdentityProviderSetting({
                                        ...identityProviderSettings,
                                        dropLocalStorage: !event.target.checked,
                                    });
                                }}
                                disabled={identityProviderSettings.keepInIdentityLockr}
                                checked={!identityProviderSettings.dropLocalStorage}
                            />
                            <span
                                style={{
                                    color: "white",
                                    fontSize: 16,
                                    fontFamily: "Montserrat",
                                }}
                            >
                                Local storage
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <Button
                        disabled={
                            identityProviderSettings.dropCookie ===
                            originalIdentityProviderSettings.dropCookie &&
                            identityProviderSettings.keepInIdentityLockr ===
                            originalIdentityProviderSettings.keepInIdentityLockr &&
                            identityProviderSettings.dropLocalStorage ===
                            originalIdentityProviderSettings.dropLocalStorage
                        }
                        loading={identityProviderSettingLoading}
                        onClick={upsertIdentityProviderSettingCall}
                        style={{ width: "217px" }}
                        className="sign-in"
                    >
                        Save
                    </Button>
                </div>
            </Row>
        )
    }

    function renderStats() {
        return (
            <div
                style={{
                    marginTop: 72,
                    marginLeft: 90,
                    marginRight: 90,
                }}
            >
                <IdentityProviderStatsAuthStrip
                    utmCount={utmUserCount}
                    ssoCount={ssoUserCount}
                    emailCount={emailCount}
                    startDate={userAuthDataStartDate}
                    endDate={userAuthDataEndDate}
                    allData={userAuthenticationData}
                    onChangeEndDate={setUserAuthDataEndDate}
                    onChangeStartDate={setUserAuthDataStartDate}
                    setAuthDataAppIDs={setAuthDataAppIDs}
                    authDataAppIDs={authDataAppIDs}
                />
                <div style={{ marginTop: 60 }} />
                <IdentityProviderGraphStrip
                    endDate={graphFilterEndDate}
                    startDate={graphFilterStartDate}
                    graphData={graphData}
                    onChangeEndDate={setGraphFilterEndDate}
                    onChangeStartDate={setGraphFilterStartDate}
                    setLoginTrendsAppIDs={setLoginTrendsAppIDs}
                    loginTrendsAppIDs={loginTrendsAppIDs}
                />
            </div>
        );
    }

    return (
        <SideDrawer>
            <Row justify={"space-between"} align={"middle"} className={"apiandservices-newapp-btn"}>
                <Col sm={12} xs={24}>
                    <Typography.Title className={"apiandservices-heading"}>
                        Epsilon Core ID
                    </Typography.Title>
                    <div style={{ marginTop: 8 }} />
                    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        <label style={{ fontSize: 14, fontFamily: "Montserrat", color: "#8B8B8B" }} className="dashboard-as-of-today">
                            Enable to start a connection
                        </label>
                        <div style={{ flex: 1, marginLeft: 20, display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ flex: 1 }}>{displaySwitch()}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            {renderSettings()}
            {renderStats()}
            {dispalyConnectionModal()}
            {displayCredentialsModal && (
                <EpsilonCoreIDCredentialsModal
                    display={displayCredentialsModal}
                    onOk={(modalCredentials) => { saveEpsilonCoreIdCredentials(modalCredentials) }}
                    onCancel={() => setDisplayCredentialsModal(false)}
                    credentials={epsilonCoreIdCredentials ?? deletedCredentials}
                    error={upsertError}
                    onHideError={() => { setUpsertError(undefined) }}
                />
            )}
        </SideDrawer>
    )
}