import React from 'react';
import {Button, Checkbox, Modal, Tabs, Tooltip as ToolTip, Typography} from "antd";
import {TitleTextPassword} from "../../../Components/TitleTextPassword";
import {LockrInfoIcon} from "../../../Components/UIDCredentialsModal";

export function RAMPIDCredentialsModal({
                                           display,
                                           onOk,
                                           onCancel,
                                           rampATSCredentials,
                                           error,
                                           onHideError,
                                           rampRTISCredentials,
                                       }) {

    const [selectedTab, setSelectedTab] = React.useState(0);
    const [atsCredentails, setAtsCredentials] = React.useState(rampATSCredentials || {
        accessKey: "", secretKey: "", useLockrKeys: false
    });

    const [rtisCredentials, setRtisCredentials] = React.useState(rampRTISCredentials || {
        accessKey: "", secretKey: "", useLockrKeys: false
    });

    function getTNCURL() {
        if (selectedTab === 0) {
            return 'https://launch.liveramp.com/auth/sign-up';
        } else {
            return 'https://docs.liveramp.com/identity/en/implementing-liveramp-s-real-time-identity-service-tag.html';
        }
    }

    function renderTNCLine() {
        return (
            <div
                style={{
                    marginTop: 24,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <span
                    style={{
                        marginLeft: 8,
                        color: "#EDEDED",
                        fontFamily: "Montserrat",
                        fontSize: 14,
                        fontWeight: "400",
                    }}
                >
                  Don't have a LiveRamp {`${selectedTab === 0 ? 'ATS' : 'RTIS'}`} account?
                </span>
                <span
                    style={{
                        marginLeft: 8,
                        color: "white",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontWeight: "600",
                        fontSize: 14,
                    }}
                    onClick={() => {
                        window.open(getTNCURL(), "_blank");
                    }}
                >
                  Create one here
                </span>
            </div>
        );
    }


    function renderContent() {
        if (selectedTab === 0) {
            return (<div>
                <TitleTextPassword
                    placeholder={" "}
                    label={"Placement ID"}
                    marginLeft={155}
                    eyeRight={45}
                    disabled={atsCredentails.useLockrKeys}
                    supportCopy
                    supportDisabledColor
                    value={atsCredentails?.useLockrKeys ? "***********" : atsCredentails?.accessKey}
                    hideEye={atsCredentails.useLockrKeys}
                    onChange={(event) => setAtsCredentials({
                        ...atsCredentails, accessKey: event.target.value,
                    })}
                />
                <div style={{marginTop: 24, display: "flex", alignItems: "center"}}>
                    <Checkbox
                        checked={atsCredentails.useLockrKeys}
                        onChange={(event) => {
                            setAtsCredentials({
                                ...atsCredentails, useLockrKeys: event.target.checked,
                            });
                        }}
                    />
                    <span
                        style={{
                            marginLeft: 16, color: "white", fontSize: 16, fontFamily: "Montserrat",
                        }}
                    >
                            Use lockrKeys
                        </span>
                    <ToolTip
                        style={{
                            cursor: "pointer",
                        }}
                        title="Publishers can use their own credentials with service providers or choose to rely on lockr's pre-established connection."
                    >
                        <div
                            style={{
                                marginLeft: 16, display: "flex", justifyContent: "center",
                            }}
                        >
                            <LockrInfoIcon/>
                        </div>
                    </ToolTip>
                </div>
            </div>)
        } else if (selectedTab === 1) {
            return (<div>
                <TitleTextPassword
                    placeholder={" "}
                    label={"Pixel ID"}
                    marginLeft={204}
                    eyeRight={45}
                    disabled={rtisCredentials.useLockrKeys}
                    supportCopy
                    supportDisabledColor
                    value={rtisCredentials?.useLockrKeys ? "***********" : rtisCredentials?.accessKey}
                    hideEye={rtisCredentials.useLockrKeys}
                    onChange={(event) => setRtisCredentials({
                        ...rtisCredentials, accessKey: event.target.value,
                    })}
                />
                <div style={{marginTop: 24, display: "flex", alignItems: "center"}}>
                    <Checkbox
                        checked={rtisCredentials.useLockrKeys}
                        onChange={(event) => {
                            setRtisCredentials({
                                ...rtisCredentials, useLockrKeys: event.target.checked,
                            });
                        }}
                    />
                    <span
                        style={{
                            marginLeft: 16, color: "white", fontSize: 16, fontFamily: "Montserrat",
                        }}
                    >
                            Use lockrKeys
                        </span>
                    <ToolTip
                        style={{
                            cursor: "pointer",
                        }}
                        title="Publishers can use their own credentials with service providers or choose to rely on lockr's pre-established connection."
                    >
                        <div
                            style={{
                                marginLeft: 16, display: "flex", justifyContent: "center",
                            }}
                        >
                            <LockrInfoIcon/>
                        </div>
                    </ToolTip>
                </div>
            </div>)
        }
    }

    function renderSaveButton() {
        return (
            <Button
                style={{width: 217, marginTop: 24, cursor: "pointer"}}
                type="primary"
                className="sign-in"
                onClick={() => onOk(atsCredentails, rtisCredentials)}
            >
                Confirm
            </Button>
        )
    }

    function renderTabs() {
        return (<>
            <div style={{
                flexDirection: "row", display: 'flex', gap: 16
            }}>
                <div onClick={() => {
                    setSelectedTab(0);
                }} style={{
                    height: 60,
                    backgroundColor: selectedTab === 0 ? '#262626' : 'transparent',
                    cursor: 'pointer',
                    paddingLeft: 16,
                    paddingRight: 16,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 200,
                    color: 'white',
                    opacity: selectedTab === 0 ? 1 : 0.5,
                    fontSize: 18,
                    textAlign: 'center',
                }}>
                    ATS
                </div>
                <div onClick={() => {
                    setSelectedTab(1)
                }} style={{
                    height: 60,
                    backgroundColor: selectedTab === 1 ? '#262626' : 'transparent',
                    cursor: 'pointer',
                    paddingLeft: 16,
                    paddingRight: 16,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 200,
                    color: 'white',
                    opacity: selectedTab === 1 ? 1 : 0.5,
                    fontSize: 18,
                    textAlign: 'center',
                }}>
                    RTIS
                </div>
            </div>
            <div
                style={{
                    width: '100%', backgroundColor: 'white', height: 0.5, opacity: 0.3
                }}
            />
        </>)
    }

    return (<Modal
        centered
        width={750}
        footer={null}
        closable={false}
        bodyStyle={{
            minHeight: 500,
            width: 750,
            backgroundColor: "#353738",
            paddingRight: 60,
            paddingLeft: 60,
            paddingTop: 60,
            overflowX: "hidden",
        }}
        destroyOnClose
        style={{
            top: 20, paddingBottom: 20,
        }}
        visible={display}
    >
        <img
            style={{right: 60}}
            alt="close"
            onClick={onCancel}
            src={require("../../../assets/modal-close.png")}
            className="delete-x-button-connection"
        />
        <div>
            <Typography.Title
                style={{
                    display: "inline-block",
                    fontFamily: "Montserrat",
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: 20,
                    paddingBottom: 20,
                    paddingTop: 8,
                }}
            >
                {"Credentials"}
            </Typography.Title>
            {renderTabs()}
            <div style={{
                marginTop: 32,
            }}/>
            {renderContent()}
            {renderTNCLine()}
            <div style={{
                marginTop: 16,
            }}/>
            {renderSaveButton()}
        </div>
    </Modal>)
}
