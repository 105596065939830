import React, { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import SideDrawer from "../../Components/SideDrawer";
import { Button, Col, Row, Switch, Typography, Modal, Tooltip, DatePicker, Card } from "antd";
import { IllumaCredentialModal } from "../../Components/IllumaCredentialModal";
import { InfosumCredentialsModal } from "../../Components/InfosumCredentialsModal";
import { createIllumaConnection, deleteIllumaConnection, editIllumaConnection, getIllumaConnections, getIllumaStats, getIllumaPieChartDetails } from "../../Utils/ApiActions";
import { UIDNoCredentialModal } from "../../Components/UIDNoCredentialModal";
import { DataConnectorCredentialModal } from "../../Components/CommonModalDataConnectors";
import { PieChart, Pie, Cell, Tooltip as RechartsTooltip, Legend } from 'recharts';
import { AiOutlineDown } from 'react-icons/ai';
import moment from 'moment';

import illumaGreyLogo from '../../assets/illuma-grey.png'
import { formatNumberWithCommas } from "../../Utils/Helper";

export function IllumaConnections() {
    const [showNewConnectionModal, setShowNewConnectionModal] = useState(false);
    const [showCredentialsModal, setShowCredentialsModal] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [rssFeedEntries, setRssFeedEntries] = useState([]);
    const [illumaConnectectionId, setIllumaConnectectionId] = useState(undefined);
    const [displayConnectorModal, setDisplayConnectorModal] = useState(false)

    const [apiCallsCount, setApiCallsCount] = useState(0)
    const [cacheCountapiCallsCount, setCacheApiCallCount] = useState(0)
    const [pageViewCounts, setPageViewsCount] = useState(0)
    const [primaryCategories, setPrimaryCategories] = useState([]);
    const [secondaryCategories, setSecondaryCategories] = useState([]);
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
    const [endDate, setEndDate] = useState(moment());

    // Custom colors with better contrast
    const primaryColors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4', '#FFEEAD'];
    const secondaryColors = ['#4A90E2', '#82C091', '#F78FB3', '#FFD93D', '#6C5CE7'];

    const customFormat = "MMM DD, YYYY";

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    style={{
                        minWidth: 200,
                        minHeight: 20,
                        background: "#171717",
                        display: "flex",
                        flexDirection: "column",
                        padding: 16,
                        border: "1px solid #333",
                        borderRadius: "4px",
                        fontFamily: "Montserrat, sans-serif"
                    }}
                >
                    <span
                        style={{
                            color: "white",
                            fontSize: 16,
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            fontFamily: "Montserrat, sans-serif"
                        }}
                    >
                        <div
                            style={{
                                width: "12px",
                                height: "12px",
                                backgroundColor: payload[0].color,
                                borderRadius: "2px"
                            }}
                        />
                        {payload[0].name}:{" "}
                        <span style={{ color: "#8B8B8B" }}>
                            {payload[0].value}
                        </span>
                    </span>
                </div>
            );
        }
        return null;
    };

    const renderCustomLegend = (props) => {
        const { payload } = props;
        // const midPoint = Math.ceil(payload.length / 2);

        return (
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '12px 24px',
                marginTop: '16px',
                width: '100%',
                padding: '0 16px',
                fontFamily: "Montserrat, sans-serif"
            }}>
                {payload.map((entry, index) => (
                    <div
                        key={`legend-${index}`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontFamily: "Montserrat, sans-serif"
                        }}
                    >
                        <div style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: entry.color,
                            marginRight: '8px',
                            borderRadius: '2px',
                            flexShrink: 0
                        }} />
                        <span style={{ 
                            color: '#8B8B8B', 
                            fontSize: '12px',
                            fontFamily: "Montserrat, sans-serif",
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {`${entry.value} (${entry.payload.value})`}
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    useEffect(() => {
        getIlumaConnections();
        illumaStats();
        getIllumaCategoryStats();
    }, []);

    const illumaStats = async () => {
        const stats = await getIllumaStats()
        setPageViewsCount(stats?.data?.pageViewCounts)
        setApiCallsCount(stats?.data?.apiCallsCount)
        setCacheApiCallCount(stats?.data?.cacheCount)
    }

    const getIllumaCategoryStats = async () => {
        try {
            const response = await getIllumaPieChartDetails(
                startDate.format('YYYY-MM-DD'),
                endDate.add(1, 'days').format('YYYY-MM-DD')
            );
            setPrimaryCategories(response?.data?.primaryCategories || []);
            setSecondaryCategories(response?.data?.secondaryCategories || []);
        } catch (error) {
            console.error('Error fetching Illuma category stats:', error);
        }
    };

    useEffect(() => {
        getIllumaCategoryStats();
    }, [startDate, endDate]);

    const getIlumaConnections = async () => {
        const response = await getIllumaConnections();
        if (response.data.length > 0) {
            setApiKey(response.data[0].configuration.apiKey);
            setRssFeedEntries(response.data[0].configuration.rss_feeder);
            setIllumaConnectectionId(response.data[0].id);

        }
        else {
            setDisplayConnectorModal(true)
        }
        console.log(response);
    }

    const handleCreateConnection = async () => {
        const response = await createIllumaConnection("", "");
        setIllumaConnectectionId(response.data.id);
    }

    const handleEditConnection = async () => {
        const response = await editIllumaConnection(illumaConnectectionId, rssFeedEntries, apiKey);
        if (response.success) {
            setShowCredentialsModal(false);
        }
        getIlumaConnections()
    }

    const handleDeleteConnection = async () => {

        const response = await deleteIllumaConnection(illumaConnectectionId);
        setIllumaConnectectionId(undefined);
    }

    function renderEmptySection() {
        return (
            <div style={{
                marginLeft: 90,
                marginRight: 90,
                minHeight: '50vh',
                background: 'black',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Typography.Text style={{
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '24px',
                    color: '#fff'
                }}>Connect lockr with Illuma</Typography.Text>
                <Button
                    style={{ marginTop: 24 }}
                    type='primary'
                    disabled={illumaConnectectionId}
                    className='btn-first-connection'
                    onClick={() => {
                        setShowNewConnectionModal(true);
                    }}>Create connection</Button>
            </div>
        );
    }

    const renderIllumaStatsStrips = () => {
        return <div
            style={{
                alignItems: "center",
                display: "flex",
                position: "relative",
                flexDirection: "row",
                gap: 10,
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 32,
                width: "100%",
                background: "#1D1D1D",
                height: "150px",
                boxShadow: "8px 8px 0px 0px #000",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    height: 127,
                    borderRadius: 2,
                    background: "black",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    position: "relative",
                }}
            >
                <span
                    style={{
                        color: "#61CEF7",
                        fontSize: 40,
                        fontWeight: "400",
                        fontFamily: "Montserrat",
                    }}
                >
                    {formatNumberWithCommas(apiCallsCount)}
                </span>
                <span
                    style={{
                        color: "#8B8B8B",
                        fontSize: 14,
                        fontWeight: "400",
                        marginTop: -4,
                        fontFamily: "Montserrat",
                    }}
                >
                    API Calls
                </span>
                <img
                    alt="add_logo"
                    style={{
                        height: 24,
                        zIndex: 200,
                        width: 24,
                        position: "absolute",
                        right: -16,
                    }}
                    src={require("../../assets/stats_add_logo.png")}
                />
                <Tooltip
                    title={"Displays the total number of unique API calls made to the Illuma service."}
                    overlayInnerStyle={{
                        padding: 16,
                    }}
                >
                    <InfoCircleOutlined
                        style={{
                            position: "absolute",
                            top: 15,
                            right: 15,
                            zIndex: 200,
                            color: "grey",
                            fontSize: 16,
                            fontWeight: "400",
                        }}
                    />
                </Tooltip>
            </div>
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    height: 127,
                    borderRadius: 2,
                    position: "relative",
                    background: "black",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <span
                    style={{
                        color: "#61CEF7",
                        fontSize: 40,
                        fontWeight: "400",
                        fontFamily: "Montserrat",
                    }}
                >
                    {cacheCountapiCallsCount}
                </span>
                <span
                    style={{
                        color: "#8B8B8B",
                        fontSize: 14,
                        fontWeight: "400",
                        marginTop: -4,
                        fontFamily: "Montserrat",
                    }}
                >
                    Cached API Calls
                </span>
                <img
                    alt="add_logo"
                    style={{
                        height: 24,
                        width: 24,
                        zIndex: 200,
                        position: "absolute",
                        right: -16,
                    }}
                    src={require("../../assets/stats_equal_logo.png")}
                />
                <Tooltip
                    title={"Redundant cache requests are avoided, ensuring faster and more efficient API calls on each page."}
                    overlayInnerStyle={{
                        padding: 16,
                    }}
                >
                    <InfoCircleOutlined
                        style={{
                            position: "absolute",
                            top: 15,
                            right: 15,
                            zIndex: 200,
                            color: "grey",
                            fontSize: 16,
                            fontWeight: "400",
                        }}
                    />
                </Tooltip>
            </div>
            <div
                onMouseEnter={() => {

                }}
                onMouseLeave={() => {

                }}
                style={{
                    display: "flex",
                    flex: 1,
                    height: 127,
                    borderRadius: 2,
                    background: "#26282A",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    position: "relative",
                }}
            >
                <span
                    style={{
                        color: "#50E8B1",
                        fontSize: 40,
                        fontWeight: "700",
                        fontFamily: "Montserrat",
                    }}
                >
                    {formatNumberWithCommas(pageViewCounts)}
                </span>
                <span
                    style={{
                        color: "#8B8B8B",
                        fontSize: 14,
                        fontWeight: "400",
                        marginTop: -4,
                        fontFamily: "Montserrat",
                    }}
                >
                    Total
                </span>

            </div>
        </div>
    }

    const renderPieCharts = () => {
        return (
            <div style={{ marginTop: 32 }}>
                {/* Date Picker Section */}
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 24,
                    fontFamily: 'Montserrat, sans-serif'
                }}>
                    {/* Left side - Title and subtext */}
                    <div>
                        <h3 style={{ 
                            color: '#fff',
                            margin: 0,
                            marginBottom: '4px',
                            fontSize: '24px',
                            fontFamily: 'Montserrat, sans-serif',
                            fontWeight: 700
                        }}>
                            Category Distribution
                        </h3>
                        <p style={{ 
                            color: '#8B8B8B',
                            margin: 0,
                            fontSize: '14px',
                            fontFamily: 'Montserrat, sans-serif'
                        }}>
                            View the distribution of primary and secondary categories
                        </p>
                    </div>

                    {/* Right side - Date pickers */}
                    <div style={{ 
                        display: 'flex', 
                        gap: 16,
                        alignItems: 'center',
                        fontFamily: 'Montserrat, sans-serif'
                    }}>
                        <DatePicker
                            disabledDate={(date) => moment(date) > moment()}
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            format={customFormat}
                            className="dashboard-date-picker"
                            dropdownClassName="calender-date-picker"
                            popupStyle={{ 
                                background: "#171717",
                                fontFamily: 'Montserrat, sans-serif'
                            }}
                            suffixIcon={<AiOutlineDown color="#fff" />}
                            allowClear={false}
                        />
                        <span style={{ 
                            color: '#8B8B8B',
                            fontFamily: 'Montserrat, sans-serif'
                        }}>to</span>
                        <DatePicker
                            disabledDate={(date) => moment(date) > moment() || moment(date) < startDate}
                            value={endDate}
                            onChange={(date) => setEndDate(date)}
                            format={customFormat}
                            className="dashboard-date-picker"
                            dropdownClassName="calender-date-picker"
                            popupStyle={{ 
                                background: "#171717",
                                fontFamily: 'Montserrat, sans-serif'
                            }}
                            suffixIcon={<AiOutlineDown color="#fff" />}
                            allowClear={false}
                        />
                    </div>
                </div>

                {/* Updated Pie Charts Section */}
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'space-around',
                    gap: '24px',
                    marginTop: 24 
                }}>
                    {/* Primary Categories Chart */}
                    <Card
                        style={{ 
                            flex: 1,
                            background: '#1D1D1D',
                            border: '1px solid #333'
                        }}
                        bodyStyle={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '24px 16px',
                            fontFamily: "Montserrat, sans-serif"
                        }}
                        title={
                            <span style={{ 
                                color: '#8B8B8B', 
                                fontSize: '14px',
                                fontFamily: "Montserrat, sans-serif"
                            }}>
                                Primary Categories
                            </span>
                        }
                    >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}>
                            <PieChart width={300} height={300}>
                                <Pie
                                    data={primaryCategories}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={60}
                                    outerRadius={90}
                                    paddingAngle={2}
                                >
                                    {primaryCategories.map((entry, index) => (
                                        <Cell 
                                            key={`primary-${index}`} 
                                            fill={primaryColors[index % primaryColors.length]} 
                                        />
                                    ))}
                                </Pie>
                                <RechartsTooltip 
                                    content={CustomTooltip}
                                    cursor={false}
                                    wrapperStyle={{ outline: "none" }}
                                />
                                <Legend 
                                    content={renderCustomLegend}
                                    verticalAlign="bottom"
                                    align="center"
                                />
                            </PieChart>
                        </div>
                    </Card>

                    {/* Secondary Categories Chart */}
                    <Card
                        style={{ 
                            flex: 1,
                            background: '#1D1D1D',
                            border: '1px solid #333'
                        }}
                        bodyStyle={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '24px 16px',
                            fontFamily: "Montserrat, sans-serif"
                        }}
                        title={
                            <span style={{ 
                                color: '#8B8B8B', 
                                fontSize: '14px',
                                fontFamily: "Montserrat, sans-serif"
                            }}>
                                Secondary Categories
                            </span>
                        }
                    >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}>
                            <PieChart width={300} height={300}>
                                <Pie
                                    data={secondaryCategories}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={60}
                                    outerRadius={90}
                                    paddingAngle={2}
                                >
                                    {secondaryCategories.map((entry, index) => (
                                        <Cell 
                                            key={`secondary-${index}`} 
                                            fill={secondaryColors[index % secondaryColors.length]} 
                                        />
                                    ))}
                                </Pie>
                                <RechartsTooltip 
                                    content={CustomTooltip}
                                    cursor={false}
                                    wrapperStyle={{ outline: "none" }}
                                />
                                <Legend 
                                    content={renderCustomLegend}
                                    verticalAlign="bottom"
                                    align="center"
                                />
                            </PieChart>
                        </div>
                    </Card>
                </div>
            </div>
        );
    };

    return (
        <SideDrawer>
            <Row
                justify='space-between'
                align='middle'
                className='apiandservices-newapp-btn'
            >
                <Col sm={17} xs={24}>
                    <Typography.Title className="apiandservices-heading">Illuma</Typography.Title>
                    <div style={{ marginTop: 8 }} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <label style={{ fontSize: 14, fontFamily: "Montserrat", color: '#8B8B8B' }} className='dashboard-as-of-today'>
                            Enable standard IAB contextual classifications
                        </label>
                        <div style={{ marginLeft: 20 }}>
                            <Switch
                                checked={!!illumaConnectectionId}
                                onChange={(checked) => {

                                    if (checked) {
                                        setShowCredentialsModal(true)
                                        handleCreateConnection();
                                    } else {
                                        handleDeleteConnection();
                                    }
                                }}
                            />
                        </div>
                        {illumaConnectectionId && <Typography.Text onClick={() => {
                            setShowCredentialsModal(true);
                        }} className="infosum-view-credentials-text" >Settings</Typography.Text>}
                    </div>
                </Col>
            </Row>
            {!illumaConnectectionId && renderEmptySection()}
            {illumaConnectectionId && <Row justify='space-between'
                align='middle'
                className='apiandservices-newapp-btn' style={{}}>{renderIllumaStatsStrips()}
                {illumaConnectectionId && <div style={{ width: '100%' }}>{renderPieCharts()}</div>}
                </Row>}
            

            <IllumaCredentialModal visible={showCredentialsModal} apiKey={apiKey} rssFeedEntries={rssFeedEntries} setApiKey={setApiKey} setRssFeedEntries={setRssFeedEntries} onCancel={() => {
                setShowCredentialsModal(false);
            }} onOk={() => {
                handleEditConnection();
            }} />

            <DataConnectorCredentialModal display={displayConnectorModal} onOk={() => { setDisplayConnectorModal(false) }} integrationType="illuma" logo={illumaGreyLogo} />
        </SideDrawer>
    );
} 