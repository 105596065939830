import React, { useEffect, useMemo, useRef, useState } from 'react';
import SideDrawer from '../../Components/SideDrawer';
import { Button, Col, Modal, Popover, Row, Table, Typography } from 'antd';
import { BASE_URL, UploadEmailFile, getAllConnectionExecutions, getEmailReport, getEmailReportPieDetails, getEmailReportTimeChart, getReportForGenericConnection } from '../../Utils/ApiActions';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import { CartesianGrid, Line, LineChart, XAxis, YAxis, Tooltip } from 'recharts';
import { getGraphColor, getPieColors } from '../../Utils/Helper';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DateRangeCalendar } from './date-range-calendar';
import DatePicker from 'react-multi-date-picker';

function DatePickerArrow() {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
            <g clip-path="url(#clip0_7551_14576)">
                <path d="M11.625 6.375H0.375" stroke="#8B8B8B" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.125 2.625L0.375 6.375L4.125 10.125" stroke="#8B8B8B" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_7551_14576">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export function ReportModal({ visible, onCancel, fileId, isFromConnection = false, connectionID, title }) {

    const [pieChartData, setPirChartData] = useState([]);
    const [timechartData, setTimeChartData] = useState([]);
    const [filterTimechartData, setFilterTimechartData] = useState([]);
    const [dimensions, setDimensions] = useState([]);
    const [pieChartLegends, setPieChartLegends] = useState([]);
    const [displayLineChart, setDisplayLineChart] = useState(true);
    const [filterStartDate, setFilterStartDate] = useState(undefined);
    const [filterEndDate, setFilterEndDate] = useState(undefined);
    const [unsavedStartDate, setUnsavedStartDate] = useState(undefined);
    const [unsavedEndDate, setUnsavedEndDate] = useState(undefined);
    const datePickerRef = useRef(undefined);
    const [pastExecutions, setPastExecutions] = useState([]);
    const [selectedExecutionId, setSelectedExecutionId] = useState(undefined);
    const [isDatePickerDisplayed, setIsDatePickerDisplayed] = useState(false);

    const { minDate, maxDate } = useMemo(() => {
        if (timechartData.length) {
            let minDate = moment(timechartData[0]?.timestamp, 'MM/DD/YYYY');
            let maxDate = moment(timechartData[0]?.timestamp, 'MM/DD/YYYY');
            timechartData.forEach(tItem => {
                debugger;
                if (moment(tItem.timestamp, 'MM/DD/YYYY').isAfter(maxDate, 'date')) {
                    maxDate = moment(tItem.timestamp, 'MM/DD/YYYY')
                }
                if (moment(tItem.timestamp, 'MM/DD/YYYY').isBefore(minDate, 'date')) {
                    minDate = moment(tItem.timestamp, 'MM/DD/YYYY')
                }
            })
            return {
                minDate,
                maxDate
            }
        }
        return {
            minDate: undefined,
            maxDate: undefined,
        }
    }, [timechartData])

    useEffect(() => {
        if (isFromConnection) {
            getPastExecutions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isFromConnection) {
            getChartForConnection();
        } else {
            fetchData();
            fetchTimeChartData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileId]);

    async function getPastExecutions() {
        try {
            const response = await getAllConnectionExecutions(connectionID);
            if (response.data?.length) {
                setPastExecutions(response.data);
            }
        } catch (error) {
            console.log(`Unable to get the past executions ${error.message}`);
        }
    }

    useEffect(() => {
        if (filterStartDate && filterEndDate) {
            //There are dates, let's filter.
            const masterData = Object.assign([], timechartData);
            const filteredData = masterData.filter(item => {
                const itemDate = moment(item.timestamp, 'MM-DD-YYYY');
                if (itemDate.isBetween(filterStartDate, filterEndDate)) {
                    return item
                }
            })
            setFilterTimechartData(filteredData);
            setFilterStartDate(undefined);
            setFilterEndDate(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterStartDate, filterEndDate]);

    function buildPieDataForConnect(data) {
        let _pieChartData = [];
        let _legendDetails = [];
        _pieChartData.push(['Domain', 'count'])
        if (data) {
            if (!Object.keys(data).length) {
                _pieChartData.push(['Others', '1']);
                setPieChartLegends([{
                    key: 'Others',
                    value: 100
                }]);
            } else {
                Object.keys(data).forEach(keyItem => {
                    _pieChartData.push([keyItem, data[keyItem]]);
                    _legendDetails.push({
                        key: keyItem,
                        value: data[keyItem]
                    });
                })
            }
            setPieChartLegends(_legendDetails);
            setPirChartData(_pieChartData);
        } else {
            _pieChartData.push(['Others', '1']);
            setPieChartLegends([{
                key: 'Others',
                value: 100
            }]);
        }
    }

    function buildTimeseriesData(data) {
        try {
            const _dimensionList = [];
            if (data.length === 1) {
                if (data[0].timestamp === null) {
                    setDisplayLineChart(false);
                    return;
                }
            }
            data.forEach(dataItem => {
                Object.keys(dataItem).forEach(keyitem => {
                    if (keyitem === 'timestamp') {
                    } else if (keyitem !== 'date' && keyitem !== 'totalCount') {
                        dataItem[keyitem] = parseFloat(((dataItem[keyitem] * 100) / dataItem['totalCount']).toFixed(2))
                    }
                })
            })
            Object.keys(data[0]).forEach(keyItem => {
                if (keyItem !== 'date' && keyItem !== 'timestamp' && keyItem !== 'totalCount') {
                    if (_dimensionList.indexOf(keyItem) < 0) {
                        _dimensionList.push(keyItem);
                    }
                }
            })
            setDimensions(_dimensionList);
            setTimeChartData(data);
            setFilterTimechartData(data);
        } catch (error) {
            console.log('Unable to build the time series data');
            console.log(error);
        }
    }

    async function getChartForConnection() {
        try {
            const response = await getReportForGenericConnection(connectionID);
            if (response.success && response.data.pieData) {
                buildPieDataForConnect(response.data.pieData);
            }
            if (response.success && response.data?.timeSeriesData) {
                const data = response.data.timeSeriesData;
                buildTimeseriesData(data);
            }
        } catch (error) {
            console.log(`Unable to get the chart data for the connection`);
            console.log(error);
        }
    }

    async function fetchTimeChartData() {
        try {
            const response = await getEmailReportTimeChart(fileId);
            if (response.success && response.data && response.data.length) {
                const data = response.data;
                const _dimensionList = [];
                if (data.length === 1) {
                    if (data[0].timestamp === null) {
                        setDisplayLineChart(false);
                        return;
                    }
                }
                data.forEach(dataItem => {
                    Object.keys(dataItem).forEach(keyitem => {
                        if (keyitem !== 'timestamp' && keyitem !== 'totalCount') {
                            dataItem[keyitem] = parseFloat(((dataItem[keyitem] * 100) / dataItem['totalCount']).toFixed(2))
                        }
                    })
                })
                Object.keys(data[0]).forEach(keyItem => {
                    if (keyItem !== 'timestamp' && keyItem !== 'totalCount') {
                        if (_dimensionList.indexOf(keyItem) < 0) {
                            _dimensionList.push(keyItem);
                        }
                    }
                })
                setDimensions(_dimensionList);
                data.sort((item1, item2) => {
                    if (item1.timestamp < item2.timestamp) {
                        return -1
                    }
                    return 1
                })
                setTimeChartData(data);
                setFilterTimechartData(data);
            } else {
                setDisplayLineChart(false);
            }
        } catch (error) {
            setDisplayLineChart(false);
        }
    }

    async function fetchData() {
        try {
            const response = await getEmailReportPieDetails(fileId);
            let _pieChartData = [];
            let _legendDetails = [];
            _pieChartData.push(['Domain', 'count'])
            if (response.success && response.data) {
                const data = response.data;
                if (!Object.keys(data).length) {
                    _pieChartData.push(['Others', '1']);
                    setPieChartLegends([{
                        key: 'Others',
                        value: 100
                    }]);
                } else {
                    Object.keys(data).forEach(keyItem => {
                        _pieChartData.push([keyItem, data[keyItem]]);
                        _legendDetails.push({
                            key: keyItem,
                            value: data[keyItem]
                        });
                    })
                }
                setPieChartLegends(_legendDetails);
                setPirChartData(_pieChartData);
            } else {
                _pieChartData.push(['Others', '1']);
                setPieChartLegends([{
                    key: 'Others',
                    value: 100
                }]);
            }
        } catch (error) {

        }
    }

    function renderLines() {
        if (filterTimechartData.length) {
            return (
                <>
                    {
                        dimensions.map((dItem, index) => {
                            return <Line dot={{ stroke: 'white', strokeWidth: 1 }} connectNulls type="monotone" dataKey={dItem} stroke={getGraphColor(index)} />
                        })
                    }
                </>
            )
        }
    }

    function renderPieLegends() {
        if (pieChartLegends.length) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16, }}>
                    {pieChartLegends.map((item, index) => {
                        return (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 12 }}>
                                <div style={{ borderRadius: 28, height: 12, width: 12, background: getGraphColor(index) }}></div>
                                <Typography.Text style={{
                                    marginLeft: 6,
                                    fontSize: 14,
                                    color: 'white',
                                    fontFamily: 'Montserrat',
                                }}>{`${item.key}`}</Typography.Text>
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

    function renderLineLegends() {
        if (dimensions.length) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {dimensions.map((item, index) => {
                        if (item !== "null") {
                            return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 12 }}>
                                <div style={{ borderRadius: 28, height: 12, width: 12, background: getGraphColor(index) }}></div>
                                <Typography.Text style={{
                                    marginLeft: 6,
                                    fontSize: 14,
                                    color: 'white',
                                    fontFamily: 'Montserrat',
                                }}>{item}</Typography.Text>
                            </div>
                        }
                    })}
                </div>
            )
        }
    }

    function downloadReport() {
        const component = document.getElementById('chart-report');
        html2canvas(component).then(canvas => {
            const imgData = canvas.toDataURL('image/png', 1.0);
            const pdf = new jsPDF('p', 'pt', [1100, canvas.height]);
            pdf.setFillColor('#353738')
            pdf.rect(0, 0, 1100, canvas.height, "F");
            pdf.addImage(imgData, 'PNG', 10, 10);
            pdf.save('report.pdf');
        })
    }

    function renderDownloadButton() {
        return (
            <Button onClick={() => {
                downloadReport();
            }} type={'primary'} style={{
                width: 211, marginTop: 32, height: 56, color: '#26282A',
                fontSize: 16,
                fontWeight: '700',
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                cursor: 'pointer'
            }}>
                <img alt={'download'} style={{ height: 16, width: 16 }} src={require('../../assets/download_icon.png')} />
                <label style={{ marginLeft: 16, cursor: 'pointer' }}>Download As PDF</label>
            </Button>
        )
    }

    const options = {
        legend: 'none',
        is3D: true,
        backgroundColor: '#1D1D1D',
        colors: getPieColors(),
        chartArea: { left: 0, top: 0, width: "100%", height: "100%" }
    };

    function renderPastExecutionsList() {
        return (
            <div style={{
                background: '#000',
                padding: '10px',
            }}>
                {
                    pastExecutions.map(pItem => {
                        return (
                            <div onClick={() => {
                                buildTimeseriesData(JSON.parse(pItem.timeSeriesData))
                                buildPieDataForConnect(JSON.parse(pItem.pieData))
                                setSelectedExecutionId(pItem.executionID)
                            }} style={{
                                width: '160px',
                                background: selectedExecutionId === pItem.executionID ? '#26282A' : 'black',
                                cursor: 'pointer',
                                padding: 10,
                                paddingBottom: 10
                            }}>
                                <span style={{ fontSize: 14, fontWeight: '400', color: 'white', cursor: 'pointer', }}>
                                    {pItem.createdAt ? moment(pItem.createdAt).format('MMM DD, YYYY') : ''}
                                </span>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    function renderPastExecutionButton() {
        if (pastExecutions.length) {
            return (
                <Popover placement='bottom' trigger={'click'} content={renderPastExecutionsList()}>
                    <Button onClick={() => {

                    }} type={'primary'} style={{
                        width: 170, height: 42, color: '#26282A',
                        fontSize: 16,
                        fontWeight: '700',
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        cursor: 'pointer',
                        flexDirection: 'row',
                        display: 'flex',
                        gap: 8,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <DateRangeCalendar />
                        <label style={{ cursor: 'pointer' }}>Processed On</label>
                    </Button>
                </Popover>
            )
        }
    }

    function renderSelectRangeButton() {
        console.log(minDate?.format('YYYY/MM/DD'))
        console.log(maxDate?.format('YYYY/MM/DD'))
        return (
            <div style={{
                display: 'flex',
                paddingRight: 46,
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'flex-end',
                gap: 16
            }}>
                {renderPastExecutionButton()}
                <DatePicker
                    range
                    ref={datePickerRef}
                    weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                    onChange={(event) => {
                        let startDate = event?.[0];
                        let endDate = event?.[1];
                        if (startDate && endDate) {
                            setUnsavedStartDate(moment.unix(startDate.unix));
                            setUnsavedEndDate(moment.unix(endDate.unix));
                        }
                    }}
                    minDate={minDate?.format('YYYY/MM/DD')}
                    maxDate={maxDate?.format('YYYY/MM/DD')}
                    onClose={() => {
                        setIsDatePickerDisplayed(false);
                    }}
                    onOpen={() => {
                        setTimeout(() => { setIsDatePickerDisplayed(true); }, 20)
                    }}
                    arrowStyle={{ color: 'white' }}
                    inputClass='self-serve-date-picker-input'
                />
                {isDatePickerDisplayed && <div className='self-serve-date-picker-footer'>
                    <Button style={{
                        border: '1px solid white',
                        background: 'transparent',
                        fontWeight: '700',
                        display: 'flex',
                        flex: 1,
                        color: 'white',
                        fontSize: 14,
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 40
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        if (unsavedStartDate && unsavedEndDate) {
                            setFilterStartDate(unsavedStartDate);
                            setFilterEndDate(unsavedEndDate);
                        }
                    }} style={{
                        border: '1px solid transparent',
                        background: '#61CEF7',
                        fontWeight: '700',
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#171717',
                        fontSize: 14,
                        height: 40
                    }}>Apply</Button>
                </div>}
                <Button onClick={() => {
                    let element = document.getElementsByClassName('self-serve-date-picker-input');
                    if (element?.[0]) {
                        element[0].focus();
                        setTimeout(() => {
                            setIsDatePickerDisplayed(!isDatePickerDisplayed);
                        }, 50);
                    }
                }} type={'primary'} style={{
                    width: 211, height: 42, color: '#26282A',
                    fontSize: 16,
                    fontWeight: '700',
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    cursor: 'pointer',
                    flexDirection: 'row',
                    display: 'flex',
                    gap: 8,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <DateRangeCalendar />
                    <label style={{ cursor: 'pointer' }}>Select Date Range</label>
                </Button>
            </div>
        )
    }

    return (
        <Modal
            width={850}
            visible={visible}
            bodyStyle={{ minHeight: 666, width: 850, backgroundColor: '#353738', paddingLeft: 60, paddingTop: 80, overflowX: 'hidden', paddingRight: 0 }}
            maskStyle={{ backdropFilter: 'blur(8px)' }}
            closable={false}
            footer={null}
        >

            <img alt={'Close'} onClick={onCancel} src={require('../../assets/modal-close.png')} style={{ top: 32 }} className="delete-x-button-connection" />
            <div>
                <div id='chart-report' style={{ background: '#353738' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 8, paddingBottom: 40, }}>
                        <Typography.Title style={{
                            display: 'inline-block',
                            fontFamily: 'Montserrat',
                            color: '#fff',
                            fontSize: 24,
                            fontWeight: '700',
                        }}>{title ?? 'Your Report'}</Typography.Title>
                        {renderSelectRangeButton()}
                    </div>
                    <div style={{
                        width: 730,
                        background: '#1D1D1D',
                        paddingTop: 32,
                        paddingBottom: 32,
                    }}>
                        <Chart
                            chartType="PieChart"
                            data={pieChartData}
                            options={options}
                            width={"100%"}
                            height={'400px'}
                        />
                        {renderPieLegends()}
                    </div>
                    {displayLineChart && <div style={{
                        marginTop: 16,
                        width: 730,
                        background: '#1D1D1D',
                        height: 500,
                        paddingTop: 16,
                        paddingBottom: 16,
                        paddingLeft: 48,
                        paddingRight: 48,
                    }}>
                        <Typography.Text style={{
                            color: 'white',
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            fontSize: 24
                        }}>MGE Usage over Time</Typography.Text>
                        <div style={{
                            marginTop: 24
                        }}>
                            {renderLineLegends()}
                            <div style={{ marginTop: 32 }}></div>
                            <LineChart
                                width={600}
                                height={320}
                                data={filterTimechartData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid />
                                <XAxis tick={{ stroke: 'white', strokeWidth: 1 }} dataKey="timestamp" label={{ value: 'Time', angle: -0, dy: 8, position: 'insideBottom', style: { fill: 'white' } }} />
                                <YAxis tick={{ stroke: 'white', strokeWidth: 1 }} label={{ value: 'Percentage', angle: -90, position: 'insideLeft', style: { fill: 'white' } }} />
                                {renderLines()}
                                <Tooltip
                                    contentStyle={{ background: '#171717', border: '0' }}
                                    wrapperStyle={{ background: 'black' }}
                                    formatter={(value1, value2, value3) => {
                                        return ([`${value1}%`, `${value2}`])
                                    }}
                                    labelStyle={{ color: 'white', marginBottom: 4, }} />
                            </LineChart>
                        </div>
                    </div>}
                </div>
                {renderDownloadButton()}
            </div>
        </Modal>
    )
}

export function SelfServe() {

    const [dispalyLoading, setDisplayLoading] = useState(false);
    const [detailsList, setDetailsList] = useState([]);
    const [selectedReportId, setSelectedReportId] = useState(undefined);
    const [selectedReportName, setSelectedReportName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const inputFile = useRef(null)

    function renderMachineGeneratedPercentage(record) {
        if (record.record && record.giveback && record.status === 2) {
            const percentage = ((record.giveback * 100) / record.record).toFixed(2);
            return (
                <label className='connection-tile-font'>{`${percentage} %`}</label>
            )
        } else if (record.status === 1) {
            return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{
                    backgroundColor: '#FAC748',
                    height: 10,
                    width: 10,
                    border: '1px solid transparent',
                    borderRadius: 50
                }}></div>
                <label style={{
                    color: '#FAC748',
                    marginLeft: 8,
                    fontFamily: 'Montserrat'
                }} className='connection-tile-font'>{'Processing'}</label>
            </div>
        } else if (record.status === 3) {
            return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{
                    backgroundColor: '#F46666',
                    height: 10,
                    width: 10,
                    border: '1px solid transparent',
                    borderRadius: 50
                }}></div>
                <label style={{
                    color: '#F46666',
                    marginLeft: 8,
                    fontFamily: 'Montserrat'
                }} className='connection-tile-font'>{'Failed'}</label>
            </div>
        }
    }

    const emailUploadColumns = [{
        title: <span className='app-table-header'>File Name</span>,
        key: 'fileName',
        dataIndex: 'fileName',
        width: '45%',
        render: (_value, record) => <label className='connection-tile-font'>{`${record.originalFileName}`}</label>
    }, {
        title: <span className='app-table-header'>Date / Time</span>,
        key: 'date_time',
        dataIndex: 'date_time',
        render: (_value, record) => <label className='connection-tile-font'>{`${record.date_time}`}</label>
    }, {
        title: <span className='app-table-header'>Record #</span>,
        key: 'record',
        dataIndex: 'record',
        render: (_value, record) => <label className='connection-tile-font'>{`${record.record}`}</label>
    }, {
        title: <span className='app-table-header'>MGE %</span>,
        key: 'giveback',
        dataIndex: 'giveback',
        render: (_value, record) => renderMachineGeneratedPercentage(record)
    }, {
        title: <span className='app-table-header'>Actions</span>,
        key: 'giveback',
        align: 'right',
        dataIndex: 'giveback',
        render: (_value, record) => (<Row style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button
                style={{
                    height: 30,
                    background: 'transparent',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border: '1px solid #26282A'
                }}
                type='default'
                onClick={() => {
                    setSelectedReportName(record.originalFileName.length > 40 ? record.originalFileName?.substr(0, 39) + '...' : record.originalFileName);
                    setSelectedReportId(record.id)
                }}>
                <img alt='report' style={{
                    height: 16,
                    width: 16,
                    cursor: 'pointer'
                }}
                    src={require('../../assets/report_icon.png')}
                />
                <label style={{
                    marginLeft: 12,
                    fontSize: 14,
                    fontWeight: '400',
                    color: '#8B8B8B',
                    fontStyle: 'normal',
                    cursor: 'pointer',
                }}>View Report</label>
            </Button>
        </Row>)
    }]

    useEffect(() => {
        fetchUploadList();
    }, [])

    async function fetchUploadList() {
        setDisplayLoading(true);
        try {
            const response = await getEmailReport(moment().add(-7, 'days').valueOf(), moment().valueOf(), 1);
            const reportData = [];
            response.data.report.map(reportItem => {
                reportData.push({
                    address: reportItem.originalFileName,
                    date_time: moment(reportItem.time / 1).format("MM/DD/YYYY hh:mm A"),
                    record: reportItem?.totalRecords,
                    giveback: reportItem.machineGeneratedEmail,
                    verified: reportItem.acceptedEmails,
                    status: reportItem.status,
                    unknown: reportItem.unknown,
                    fileName: reportItem.fileName,
                    originalFileName: reportItem.originalFileName,
                    id: reportItem.id
                })
            })
            setDetailsList(reportData);
        } catch (error) {
            console.log(`Unable to get the uploaded list at the moment`);
            console.log(error);
        }
        setDisplayLoading(false);
    }

    function renderEmailUploadsTable() {
        if ((!dispalyLoading && detailsList.length > 0) || dispalyLoading) {
            return (
                <div style={{
                    background: 'black',
                    marginLeft: 90,
                    marginRight: 90,
                    padding: 20,
                }}>
                    <Typography.Text style={{
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        fontSize: 20,
                    }}>Email Uploads</Typography.Text>
                    <div style={{ marginTop: 20 }} />
                    <div className='connection-list-table'>
                        <Table
                            loading={dispalyLoading}
                            style={{ background: 'black' }}
                            rowKey={'id'}
                            pagination={false}
                            dataSource={detailsList}
                            columns={emailUploadColumns}
                        />
                    </div>
                </div>
            )
        }
    }


    const handleEmailUpload = (file) => {
        setErrorMessage('');
        UploadEmailFile(file)
            .then(response => {
                // console.log(response);

                if (response.success === false) {
                    setErrorMessage(response.error.message)
                }
                else {
                    // setErrorMessage('');
                }
                fetchUploadList();
            })
            .catch(error => {


            })
    }

    function renderEmptySection() {
        if (!dispalyLoading && detailsList.length <= 0) {
            return (
                <div style={{
                    marginLeft: 90,
                    marginRight: 90,
                    minHeight: '50vh',
                    background: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <img
                        alt='logo_grey'
                        src={require('../../assets/self_serve_no_logo.png')}
                    />
                    <img
                        style={{
                            marginTop: 16
                        }}
                        alt='logo_grey'
                        src={require('../../assets/logo_grey.png')}
                    />
                    <div style={{ marginTop: 45, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography.Text style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: 24,
                            color: 'white'
                        }}>Upload your .xls / .csv file.</Typography.Text>
                        <Button onClick={() => {
                            inputFile.current.click();
                        }} style={{ marginTop: 24 }} type='primary' className='btn-first-connection'>
                            Upload Now
                        </Button>
                        <a style={{ marginTop: 24, }} href={BASE_URL + '/publisher/docs/lockr-email-domain-verification.xlsx'} target='_blank'>
                            <div
                                style={{ marginRight: 0 }}
                                className='upload-template-button'
                            >
                                <img style={{
                                    height: 12,
                                    width: 12,
                                    marginRight: 8
                                }} alt='logo' src={require('../../assets/upload_template_logo.png')} />
                                Download Template
                            </div>
                        </a>
                    </div>
                </div>
            )
        }
    }


    return (
        <SideDrawer>
            <Row
                justify='space-between'
                align='middle'
                className='apiandservices-newapp-btn'
            >
                <Col sm={18} xs={24}>
                    <Typography.Title className='apiandservices-heading'>lockr Self-Serve</Typography.Title>
                    <div style={{ marginTop: 8 }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label style={{ fontSize: 14, fontFamily: "Montserrat", color: '#8B8B8B' }} className={'dashboard-as-of-today'}>Data Onboarding</label>
                    </div>
                    <div style={{ marginTop: 16 }} />
                    <div style={{ width: '194px' }}>
                        <a href={BASE_URL + '/publisher/docs/lockr-email-domain-verification.xlsx'} target='_blank'>
                            <div
                                className='upload-template-button'
                            >
                                <img style={{
                                    height: 12,
                                    width: 12,
                                    marginRight: 8
                                }} alt='logo' src={require('../../assets/upload_template_logo.png')} />
                                Download Template
                            </div>
                        </a>
                    </div>
                </Col>
                <Col xs={24} sm={6} className='createAppButton'>
                    <Button
                        type={'primary'}
                        className='new-app-modal-btn'
                        onClick={() => { inputFile.current.click(); }}
                    >Upload Now
                    </Button>
                    {!!errorMessage && <div className='errorText'>
                        {errorMessage}
                    </div>}
                </Col>
            </Row>
            {renderEmailUploadsTable()}
            {renderEmptySection()}
            {!!selectedReportId && <ReportModal
                title={selectedReportName}
                visible={!!selectedReportId}
                onCancel={() => {
                    setSelectedReportId(undefined);
                }}
                fileId={selectedReportId}
            />}
            <input
                value={""}
                onChange={(event) => {
                    handleEmailUpload(event.target.files[0]);
                }} type='file' accept={".csv,.xlsx,.xls"} id='file' ref={inputFile} style={{ display: 'none' }} />
        </SideDrawer>
    )

}
