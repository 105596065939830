/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useRef, useState} from "react";
import SideDrawer from "../../../Components/SideDrawer";
import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    Input,
    Modal,
    Row,
    Select,
    Table,
    Tooltip,
    Typography,
} from "antd";
import {useHistory, useLocation, Prompt} from 'react-router-dom';
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

import {
    createNewCohort,
    deleteAWSEndpoint,
    deleteCohort,
    downloadCSVEndpointData,
    downloadSegment,
    getAllCohortList,
    getCohortsAttributes,
    getCSVEndpointTableData,
    getS3EndPoints,
    newS3DataEndPoint,
    publisherHasAnyCohortData,
    saveCohortUpdate,
    updateS3DataEndPoint,
    getCohortData
} from "../../../Utils/ApiActions";

import deleteIcon from '../../../assets/delete-white.svg'
import editIcon from '../../../assets/edit.svg'
import propertiesIcon from '../../../assets/properties.svg'
import duplicateIcon from '../../../assets/duplicate-copy.svg'
import eyeIcon from '../../../assets/eye.svg'
import eyeOff from '../../../assets/eye-off.svg'
import dotGridIcon from '../../../assets/dots-grid.svg'
import noSegmentDataImage from '../../../assets/segment-no-data.svg'

import {AiOutlineCheck, AiOutlineDelete, AiOutlineDown, AiOutlinePlus} from "react-icons/ai";

import {LockrInfoIcon} from "../../../Components/UIDCredentialsModal";
import {isUUID} from "../../../Utils/uuid.helper";
import {DATA_ENDPOINTS} from "../../../Utils/Helper";
import DeleteTypeModal from "../../../Components/LockrDeleteModal";
import {CustomConditionsBuilder} from "./components/CustomConditionsBuilder";
import downLoadIcon from "../../../assets/download.svg"
import threeDotMenu from "../../../assets/dots-vertical.svg"
import checkCircle from "../../../assets/check-circle-green.svg"
import {CloseOutlined} from "@ant-design/icons";
import {WarningModal} from "../../../App";
import {InfoModal} from "../../../Components/InfoModal";

export const conditionDropdown = [
    {
        label: "Is",
        key: 'is'
    }, {
        label: "Contains",
        key: 'contains'
    },
    {
        label: "Starts With",
        key: 'startsWith'
    },
    {
        label: "Ends With",
        key: 'endsWith'
    }, {
        label: "Does not contain",
        key: "doesNotContain",
    }, {
        label: "Is Null",
        key: 'isNull'
    }]

export const booleanConditionDropdown = [
    {
        label: "True",
        key: 'true'
    }, {
        label: "Empty",
        key: 'false'
    }]

export const uuidConditionDropdown = [
    {
        label: "Is",
        key: 'is'
    }, {
        label: "Is Null",
        key: 'isNull'
    }]

export const dateConditionDropdown = [
    {
        label: "After",
        key: 'after'
    }, {
        label: "Before",
        key: 'before'
    }, {
        label: "Between",
        key: 'between'
    }]

const defaultView = {
    id: -1,
    configuration: {
        appliedFilters: [],
        selectedAttributes: ["email", "firstPartyCookies", "createdAt", "firstName", "lastName", "id5Token", "rampAts"]
    },
    name: "All Visitors",
    publisherID: null,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    deletedAt: null
}

export const attributesNotToIncudeInDropdown = ["uid2Token", "id5Token", "rampAts", "euidToken"]

export function DataEndpointTitleValue({
                                           title,
                                           value,
                                           showDropdown = false,
                                           isEdit = false,
                                           infoText,
                                       }) {
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    alignItems: "center",
                    marginBottom: 4,
                }}
            >
                <span
                    style={{
                        fontWeight: "500",
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        color: "white",
                    }}
                >
                    {title}
                </span>
                {infoText && (
                    <Tooltip
                        style={{
                            cursor: "pointer",
                        }}
                        title={infoText}
                    >
                        <div>
                            <LockrInfoIcon/>
                        </div>
                    </Tooltip>
                )}
            </div>
            <div
                style={{
                    background: "#26282A",
                    minHeight: 50,
                    minWidth: 600,
                    maxWidth: 600,
                    display: "flex",
                    alignItems: "center",
                    marginTop: 4,
                    paddingLeft: 8,
                    position: "relative",
                }}
            >
                <span
                    style={{
                        fontWeight: "500",
                        minWidth: 550,
                        maxWidth: 550,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        color: isEdit ? "white" : "#8B8B8B",
                    }}
                >
                    {value}
                </span>
                {showDropdown && (
                    <AiOutlineDown
                        style={{color: isEdit ? "white" : "#8B8B8B", position: "absolute", right: 16}}
                    />
                )}
            </div>
        </div>
    );
}


export function CSVDataEndPoint() {
    const [cohorts, setCohorts] = useState([]);

    const [saveInitialSegment, setSaveInitialSegment] = useState(false)
    const [currentCohort, setCurrentCohort] = useState(defaultView);
    const [previousCohortCurrent, setPreviousCohortCurrent] = useState(defaultView)

    const [renameCohort, setRenameCohort] = useState(false);
    const [cohortName, setCohortName] = useState("All Visitors");

    const [publisherHasAnyData, setPublisherHasAnyData] = useState(false)
    const [propertiesModalVisible, setPropertiesModalVisible] = useState(false)

    const [deleteModal, setDeleteModal] = useState(false)

    const [addNewCohort, setAddNewCohort] = useState(false)
    

    const [attributes, setAttributes] = useState([])

    const [selectableAttributes, setSelectableAttributes] = useState([])
    const [tableData, setTableData] = useState([])
    
    const [pageNum, setPageNum] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const [searchQuery, setSearchQuery] = useState("");

    const [showUnsavedModal, setShowUnsavedModal] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState(null);
    const [isNavigating, setIsNavigating] = useState(false);

    const [showDownloadSuccess, setShowDownloadSuccess] = useState(false);

    const history = useHistory();
    

    const [totalPages, setTotalPages] = useState(1);

    // Initial data load
    useEffect(() => {
        getAttributes()
        getCohortList()
        getPublisherHasAnyCohortDataCheck()
    }, [])

    // Handle cohort configuration changes
    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentCohort?.configuration) {
                setPageNum(1); // Reset to page 1 when cohort changes
                getDataForCohort();
            }
        }, 500);

        return () => clearTimeout(timer);
    }, [currentCohort?.configuration]); // Watch configuration specifically

    // Handle pagination changes (page number and size)
    useEffect(() => {
        if (currentCohort?.configuration) {
            getDataForCohort();
        }
    }, [pageNum, pageSize]);

    const getDataForCohort = async () => {
        try {
            const response = await getCohortData(currentCohort.configuration, pageNum, pageSize);
            setTableData(response.data);
            setTotalPages(Math.ceil(response.total / pageSize));
        } catch (error) {
            console.error('Error fetching cohort data:', error);
            setTableData([]);
            setTotalPages(1);
        }
    }

    const disableDownload = useMemo(() => {
        return renameCohort || (JSON.stringify({...previousCohortCurrent}) !== JSON.stringify({...currentCohort}))
    }, [renameCohort, previousCohortCurrent, currentCohort, saveInitialSegment]);

    const filteredCohorts = useMemo(() => {
        return cohorts.filter(cohort =>
            cohort.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [cohorts, searchQuery]);

    const getAttributes = async () => {
        const attrs = await getCohortsAttributes()
        setAttributes(attrs.data)
        setSelectableAttributes(attrs.dropdownAttributes)
    }
    const getCohortList = async (update = false) => {
        const cohortsData = await getAllCohortList()
        if (!update) {
            // setCohortName(cohortsData.data[0]?.name)
            // setCurrentCohort(cohortsData.data[0])
            // setPreviousCohortCurrent(cohortsData.data[0])
            setCohorts([...cohortsData.data])
        } else {
            setCohorts([...cohortsData.data])
        }

    }

    const updateSelectedCohortFilters = (config) => {
        setCurrentCohort({...currentCohort, configuration: config})
    }

    const onSaveCohort = async () => {
        const cohortData = {
            name: cohortName,
            configuration: currentCohort.configuration,
            id: currentCohort?.id
        }

        let success = false;
        if (addNewCohort) {
            // Use createCohort API for new cohorts
            const response = await createNewCohort(currentCohort.configuration, cohortName);
            setCurrentCohort(response.data);
            setPreviousCohortCurrent(response.data);
            success = response?.success;
        } else {
            // Use saveCohortUpdate API for existing cohorts
            const response = await saveCohortUpdate(cohortData);
            success = response?.success;

        }

        if (success) {
            setRenameCohort(false);
            setAddNewCohort(false);
            getCohortList(true);
            setPreviousCohortCurrent(currentCohort);
        }
    }

    const saveAsCurrentSegment = async () => {
        if (currentCohort.id === -1) {
            setSaveInitialSegment(true)
            setRenameCohort(true)
            setCohortName("")
        } else {


            const cohortData = {
                name: cohortName,
                configuration: currentCohort.configuration,
                id: currentCohort?.id
            }

            // Use saveCohortUpdate API for existing cohorts
            const response = await saveCohortUpdate(cohortData);
            const success = response?.success;

            if (success) {
                setRenameCohort(false);
                setAddNewCohort(false);
                getCohortList(true);
                setPreviousCohortCurrent(currentCohort);
            }
        }
    }

    const saveAsNewSegment = async () => {
        const name = saveInitialSegment ? cohortName : `${cohortName} Copy`;
        const response = await createNewCohort(currentCohort.configuration, name);

        if (response?.success) {
            const {data} = response;
            setCurrentCohort(data);
            setPreviousCohortCurrent(data);
            setRenameCohort(saveInitialSegment ? false : true);
            setAddNewCohort(false);
            getCohortList(true);
            setCohortName(data.name);
            setSaveInitialSegment(false)
        }

    }

    const getPublisherHasAnyCohortDataCheck = async () => {
        const response = await publisherHasAnyCohortData()
        setPublisherHasAnyData(response.data ?? false)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentCohort?.configuration) {
                getCohortData(currentCohort.configuration, pageNum, pageSize)
                    .then(response => setTableData(response.data));
            }
        }, 500);

        return () => clearTimeout(timer);
    }, [currentCohort]);


    const onAddNewCohort = () => {
        setAddNewCohort(true);
        // Set initial cohort state
        const newCohort = {
            configuration: {
                appliedFilters: [{
                    config: {
                        condition: 'AND',
                        operand: "",
                        operator: "",
                        type: "",
                        value: ""
                    }, type: "filter"
                }], selectedAttributes: []
            }
        };
        setCurrentCohort(newCohort);
        setPreviousCohortCurrent(newCohort);
        // Turn on rename mode immediately
        setRenameCohort(true);
        setCohortName("All Visitors");
    }

    const onDuplicateCohort = () => {
        setAddNewCohort(true);

        setCurrentCohort(currentCohort);
        setPreviousCohortCurrent(currentCohort);


        setRenameCohort(true);
        setCohortName(`${currentCohort?.name} Duplicate`);
    }

    const onCohortDelete = async () => {
        await deleteCohort(currentCohort?.id);
        getCohortList()
        setDeleteModal(false)
    }


    

    const renderPropertiesModal = () => {
        return <div
            onClick={(e) => e.stopPropagation()}
            style={{
                background: 'black',
                maxHeight: 509,
                position: 'absolute',
                left: -210,
                top: -50,
                borderRadius: 8,
                paddingBottom: 16
            }}
        >
            <div style={{padding: 16, paddingBottom: 8, borderBottom: '1px solid #1D1D1D',}}>Properties</div>
            <div style={{padding: '8px 16px', borderBottom: '1px solid #1D1D1D'}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontWeight: 700,
                    color: 'white',
                    width: 160
                }}>
                    Shown In Table
                    <div style={{color: '#61CEF7'}}>{currentCohort.configuration.selectedAttributes.length}</div>
                </div>
                <div style={{maxHeight: 180, overflow: 'scroll'}}>
                    {selectableAttributes
                        .filter(attribute => currentCohort.configuration.selectedAttributes.includes(attribute.key))
                        .map((a) => (
                            <div
                                key={a.key}
                                style={{display: 'flex', padding: '6px 0px', alignItems: 'center'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentCohort(prevCohort => {
                                        let tempCohort = JSON.parse(JSON.stringify(prevCohort));
                                        tempCohort.configuration.selectedAttributes = tempCohort.configuration.selectedAttributes.filter(key => key !== a.key)
                                        return tempCohort;
                                    });
                                }}
                            >
                                <img style={{marginRight: 4}} src={dotGridIcon} alt="dot grid"/>
                                <div style={{
                                    width: 88,
                                    overflow: 'hidden',
                                    flex: 1,
                                    whiteSpace: 'nowrap',
                                    fontWeight: 500
                                }}>
                                    {a.label}
                                </div>
                                <img src={eyeIcon} alt="eye icon"/>
                            </div>
                        ))}
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontWeight: 700,
                    color: 'white',
                    width: 160
                }}>
                    Hidden In Table
                    <div
                        style={{color: '#61CEF7'}}>{selectableAttributes.length - currentCohort.configuration.selectedAttributes.length}</div>
                </div>
                <div style={{maxHeight: 180, overflow: 'scroll'}}>
                    {selectableAttributes
                        .filter(attribute => !currentCohort.configuration.selectedAttributes.includes(attribute.key))
                        .map((a) => (
                            <div
                                key={a.key}
                                style={{display: 'flex', padding: '6px 0px', alignItems: 'center'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentCohort(prevCohort => {
                                        let tempCohort = JSON.parse(JSON.stringify(prevCohort));
                                        tempCohort.configuration.selectedAttributes = [...tempCohort.configuration.selectedAttributes, a.key]
                                        return tempCohort;
                                    });
                                }}
                            >
                                <img style={{marginRight: 4}} src={dotGridIcon} alt="dot grid"/>
                                <div style={{
                                    width: 88,
                                    overflow: 'hidden',
                                    flex: 1,
                                    whiteSpace: 'nowrap',
                                    fontWeight: 500,
                                    color: '#8B8B8B'
                                }}>
                                    {a.label}
                                </div>
                                <img src={eyeOff} alt="eye off"/>
                            </div>
                        ))}
                </div>
            </div>
            <div style={{padding: '8px 16px 0px', display: 'flex', alignItems: 'center', gap: 10}}>
                {/* <div
                    onClick={(e) => {
                        e.stopPropagation();
                        console.log(currentCohort);
                        setRenameCohort(false);
                    }}
                    style={{
                        background: '#353738',
                        color: '#EDEDED',
                        height: 30,
                        width: 65,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        borderRadius: 4
                    }}
                >
                    Save
                </div> */}
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        setPropertiesModalVisible(false);
                    }}
                    style={{
                        background: '#0000009',
                        color: '#8B8B8B',
                        height: 30,
                        width: 53,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                >
                    Back
                </div>
            </div>
        </div>
    }


    function disableSaveButton() {
        // Always enable save button when adding new cohort
        if (addNewCohort) {
            return false;
        }


        // Existing logic for other cases
        if ((JSON.stringify({...previousCohortCurrent}) === JSON.stringify({...currentCohort}))) {
            if (!(cohortName === previousCohortCurrent?.name)) {
                return false;
            }
            return true;
        }

        return false;
    }


    function renderMainSections() {
        return <div
            style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: 90,
                paddingRight: 90,
                position: "relative",
            }}
        >

            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",

                    width: '100%'
                }}
            >


                <div className="csv-downloader-endpoint" style={{color: 'white',}}>
                    {renderCSVEndpointTable()}
                </div>
            </div>
        </div>
        return <></>;
    }


    // Move the DraggableHeaderCell component outside
    const DraggableHeaderCell = ({column, index, moveColumn}) => {
        const [{isDragging}, drag] = useDrag({
            type: 'column',
            item: {index},
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const [, drop] = useDrop({
            accept: 'column',
            hover(item) {
                if (item.index !== index) {
                    moveColumn(item.index, index);
                    item.index = index;
                }
            },
        });

        return (
            <th
                ref={(node) => drag(drop(node))}
                style={{
                    opacity: isDragging ? 0.5 : 1,
                    cursor: 'move',
                    background: '#1D1D1D',
                    padding: '16px',
                    fontFamily: 'Montserrat',
                    fontWeight: 700,
                    fontSize: '14px',
                    color: 'white',
                }}
            >
                <div style={{display: 'flex', alignItems: 'center', gap: 8}}><img style={{height: 14, width: 14}}
                                                                                  src={dotGridIcon}/>{column?.title}
                </div>
            </th>
        );
    };

    // Create a new component for the table
    const DraggableTable = ({
                                addNewCohort,
                                tableData,
                                selectableAttributes,
                            }) => {
        const moveColumn = (dragIndex, hoverIndex) => {
            const selectedAttributes = currentCohort?.configuration?.selectedAttributes ?? [];
            const newOrder = [...selectedAttributes];
            const draggedItem = newOrder[dragIndex];
            newOrder.splice(dragIndex, 1);
            newOrder.splice(hoverIndex, 0, draggedItem);
            setCurrentCohort({
                ...currentCohort,
                configuration: {...currentCohort.configuration, selectedAttributes: newOrder}
            });
        };

        const columns = (currentCohort?.configuration?.selectedAttributes ?? []).map((attributeKey, index) => {
            const attribute = selectableAttributes?.find(a => a.key === attributeKey);
            return {
                title: attribute?.label,
                key: attribute?.key,
                dataIndex: attribute?.key,
                width: "max-content",
                onHeaderCell: (column) => ({
                    column,
                    index,
                    moveColumn,
                }),
            };
        });

        return (
            <>
                {publisherHasAnyData ? (
                    <>
                        <Table
                            scroll={{x: 'max-content'}}
                            className="cohort-table"
                            style={{overflowWrap: "anywhere", borderRadius: 12}}
                            rowKey={"id"}
                            dataSource={(addNewCohort) ? [] : tableData}
                            columns={columns}
                            pagination={false}
                            components={{
                                header: {
                                    cell: DraggableHeaderCell,
                                },
                            }}
                            locale={{
                                emptyText: (
                                    <div style={{padding: '40px 0'}}>
                                        <h2 style={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 700,
                                            fontSize: '24px',
                                            lineHeight: '28px',
                                            letterSpacing: '0%',
                                            textAlign: 'center',
                                            color: '#fff',
                                            marginBottom: '8px'
                                        }}>
                                            No results to show
                                        </h2>
                                        <p style={{
                                            fontFamily: 'Montserrat',
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            letterSpacing: '0%',
                                            textAlign: 'center',
                                            color: '#8b8b8b'
                                        }}>
                                            Please adjust your filter parameters and try again
                                        </p>
                                    </div>
                                )
                            }}
                        />
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '12px 24px',
                            color: 'white',
                            fontFamily: 'Montserrat',
                            background: '#353738',
                            borderBottomLeftRadius: 12,
                            borderBottomRightRadius: 12
                        }}>
                            <button
                                onClick={() => pageNum > 1 && setPageNum(prev => prev - 1)}
                                disabled={pageNum === 1}
                                style={{
                                    background: '#1D1D1D',
                                    border: 'none',
                                    borderRadius: '2px',
                                    width: 87,
                                    height: 36,
                                    color: 'white',
                                    cursor: pageNum === 1 ? 'not-allowed' : 'pointer',
                                    opacity: pageNum === 1 ? 0.5 : 1,
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '8px'
                                }}
                            >
                                Previous
                            </button>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px',
                                fontSize: '12px',
                                fontWeight: '500'
                            }}>
                                <div>Page {pageNum} of {totalPages || 1}</div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <span>Show:</span>
                                    <Dropdown
                                        trigger={["click"]}
                                        overlay={
                                            <div style={{
                                                background: 'black',
                                                borderRadius: 8,
                                                padding: '8px 0',
                                                minWidth: 80,
                                            }}>
                                                {[10, 25, 50, 100].map((size) => (
                                                    <div
                                                        key={size}
                                                        onClick={() => {
                                                            setPageSize(size);
                                                            setPageNum(1);
                                                        }}
                                                        style={{
                                                            padding: '8px 16px',
                                                            cursor: 'pointer',
                                                            color: pageSize === size ? '#61CEF7' : 'white',
                                                            background: pageSize === size ? '#353738' : 'transparent',
                                                            fontSize: '12px',
                                                            fontWeight: '500',
                                                            fontFamily: 'Montserrat',
                                                        }}
                                                    >
                                                        {size}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    >
                                        <div style={{
                                            background: '#1D1D1D',
                                            border: '1px solid #353738',
                                            borderRadius: '2px',
                                            padding: '6px 12px',
                                            minWidth: 80,
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            gap: '8px',
                                            fontSize: '12px',
                                            fontWeight: '500',
                                        }}>
                                            {pageSize}
                                            <AiOutlineDown style={{ fontSize: '10px', color: '#8B8B8B' }} />
                                        </div>
                                    </Dropdown>
                                </div>
                            </div>

                            <button
                                onClick={() => pageNum < totalPages && setPageNum(prev => prev + 1)}
                                disabled={pageNum === totalPages}
                                style={{
                                    background: '#1D1D1D',
                                    border: 'none',
                                    borderRadius: '2px',
                                    width: 87,
                                    height: 36,
                                    color: 'white',
                                    cursor: pageNum === totalPages ? 'not-allowed' : 'pointer',
                                    opacity: pageNum === totalPages ? 0.5 : 1,
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '8px'
                                }}
                            >
                                Next
                            </button>
                        </div>
                    </>
                ) : (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img style={{width: '100%'}} src={noSegmentDataImage} alt="No data available"/>
                    </div>
                )}
            </>
        );
    };

    // Update the renderCSVEndpointTable function
    function renderCSVEndpointTable() {
        return (
            <div className="cohort-table-wrapper" style={{borderRadius: 12, overflow: "scroll"}}>
                {publisherHasAnyData ? (
                    <DndProvider backend={HTML5Backend}>
                        <DraggableTable
                            addNewCohort={addNewCohort}
                            tableData={tableData}
                            selectableAttributes={selectableAttributes}
                        />
                    </DndProvider>
                ) : (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img style={{width: '100%', cursor: 'pointer'}} onClick={() => {
                            history.push('/console/apiandservices')
                        }} src={noSegmentDataImage} alt="No data available"/>
                    </div>
                )}
            </div>
        );
    }

    // Function to check if there are unsaved changes
    const hasUnsavedChanges = () => {
        return !disableSaveButton();
    };

    // Handle navigation attempts
    const handleNavigationAttempt = (nextLocation) => {
        if (isNavigating) return false;

        if (hasUnsavedChanges()) {
            setShowUnsavedModal(true);
            setPendingNavigation(nextLocation);
            return false;
        }
        return true;
    };

    // Handle modal actions
    const handleUnsavedModalAction = async (shouldStay) => {
        setIsNavigating(true); // Set flag before navigation

        if (shouldStay) {
            // Stay on the current page
            setShowUnsavedModal(false);
            setPendingNavigation(null);
        } else {
            // Leave without saving
            setShowUnsavedModal(false);
            if (pendingNavigation) {
                setTimeout(() => {
                    history.push(pendingNavigation);
                }, 0);
            }
        }

        // Reset the navigation flag after a short delay
        setTimeout(() => {
            setIsNavigating(false);
        }, 100);
    };

    const renderUnsavedChangesModal = () => (
        <Modal
            visible={showUnsavedModal}
            onCancel={() => {
                setShowUnsavedModal(false);
                setPendingNavigation(null);
            }}
            footer={null}
            style={{backgroundColor: '#353738', padding: '0px'}}
            bodyStyle={{backgroundColor: '#353738'}}
            maskClosable={false}
            width={716}
            closeIcon={<CloseOutlined style={{color: 'white'}}/>}
        >
            <div style={{padding: '24px', color: 'white', backgroundColor: '#353738'}}>
                <div style={{
                    fontSize: '16px',
                    marginBottom: '32px',
                    fontWeight: 400,
                    fontFamily: 'Montserrat'
                }}>
                    You currently have unsaved changes! Are you sure you want to exit without saving?
                </div>
                <div style={{
                    display: 'flex',
                    gap: '16px',


                }}>
                    <Button
                        onClick={() => handleUnsavedModalAction(true)}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '60px',
                            background: '#61CEF7',
                            borderRadius: '2px',
                            fontFamily: '"Montserrat", sans-serif',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '16px',
                            lineHeight: '32px',
                            color: '#26282A',
                            width: '240px',
                            border: 'none'
                        }}
                    >
                        Stay on this page
                    </Button>
                    <Button
                        onClick={() => handleUnsavedModalAction(false)}
                        style={{
                            height: '60px',
                            background: 'transparent',
                            border: '1px solid #61CEF7',
                            borderRadius: '2px',
                            fontFamily: '"Montserrat", sans-serif',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '16px',
                            lineHeight: '32px',
                            color: '#61CEF7',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '240px'
                        }}
                    >
                        Leave this page
                    </Button>
                </div>
            </div>
        </Modal>
    );

    const [cohortListDropdownVisible, setCohortListDropdownVisible] = useState(false);
    const cohortListDropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            // Only close if click is outside both the dropdown trigger and content
            const isClickInsideDropdown = cohortListDropdownRef.current?.contains(event.target);
            const isClickInsideDropdownContent = event.target.closest('.ant-dropdown');

            if (!isClickInsideDropdown && !isClickInsideDropdownContent) {
                setCohortListDropdownVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [menuDropdownVisible, setMenuDropdownVisible] = useState(false);
    const menuDropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            // Only close if click is outside both the dropdown trigger and content
            const isClickInsideDropdown = menuDropdownRef.current?.contains(event.target);
            const isClickInsideDropdownContent = event.target.closest('.ant-dropdown');

            if (!isClickInsideDropdown && !isClickInsideDropdownContent) {
                setMenuDropdownVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    async function downloadSegmentCall() {
        try {
            const segmentId = currentCohort.id ?? 0;
            const configuration = currentCohort.configuration;
            const response = await downloadSegment(segmentId, configuration);
            
        } catch (error) {
            console.log("Unable to download the segment at the moment");
            console.log(error);
        }
    }

    return (
        <>
            <div style={{fontFamily: 'Montserrat',}}>
                <Prompt
                    when={hasUnsavedChanges() && !isNavigating}
                    message={(location) => {
                        handleNavigationAttempt(location.pathname);
                        return false;
                    }}
                />
                <SideDrawer background={'#1D1D1D'}>
                    <Row
                        justify="space-between"
                        align="middle"
                        className="apiandservices-newapp-btn"
                    >
                        <Col sm={17} xs={24}>
                            <Typography.Title className="apiandservices-heading">
                                Segments
                            </Typography.Title>
                            <div style={{marginTop: 8}}/>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <label
                                    style={{
                                        fontSize: 14,
                                        fontFamily: "Montserrat",
                                        color: "#8B8B8B",
                                    }}
                                    className="dashboard-as-of-today"
                                >
                                    Create your own custom segment designations based on visitor behavior, data and
                                    contextual attributes.
                                </label>
                            </div>
                        </Col>

                    </Row>
                    <Row justify="space-between"
                         align="middle"
                         style={{marginTop: 0, marginBottom: 0}}
                         className="apiandservices-newapp-btn">
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            <div ref={cohortListDropdownRef}>
                                <Dropdown
                                    visible={cohortListDropdownVisible && !renameCohort}
                                    overlay={!renameCohort ?
                                        <div style={{
                                            background: 'black',
                                            borderRadius: 8,
                                            padding: '16px 6px',
                                            color: 'white',
                                            width: 260
                                        }}>
                                            <div style={{
                                                paddingLeft: '10px',
                                                marginBottom: 8,
                                                fontSize: 14,
                                                fontWeight: 700
                                            }}>Segments
                                            </div>
                                            <input
                                                style={{
                                                    height: 31,
                                                    padding: '0 10px',
                                                    background: 'transparent',
                                                    border: '1px solid #26282A',
                                                    borderRadius: 4,
                                                    width: '100%',
                                                    color: 'white'
                                                }}
                                                placeholder="Search..."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />

                                            <div style={{marginTop: 8, maxHeight: 200, overflowY: 'auto'}}>
                                                <div
                                                    key={-1}
                                                    style={{
                                                        padding: '10px 14px',
                                                        cursor: 'pointer',
                                                        backgroundColor: currentCohort?.id === -1 ? '#353738' : 'transparent',
                                                        transition: 'background-color 0.2s'
                                                    }}
                                                    onClick={() => {
                                                        setCurrentCohort(defaultView);
                                                        setCohortName("All Visitors");
                                                        setPreviousCohortCurrent(defaultView);
                                                        setCohortListDropdownVisible(false)
                                                    }}
                                                >
                                                    {"All Visitors"}
                                                </div>
                                                {filteredCohorts.map((cohort) => (
                                                    <div
                                                        key={cohort.id}
                                                        style={{
                                                            padding: '10px 14px',
                                                            cursor: 'pointer',
                                                            backgroundColor: currentCohort?.id === cohort.id ? '#353738' : 'transparent',
                                                            transition: 'background-color 0.2s'
                                                        }}
                                                        onClick={() => {
                                                            setCurrentCohort(cohort);
                                                            setCohortName(cohort.name);
                                                            setPreviousCohortCurrent(cohort);
                                                            setCohortListDropdownVisible(false)
                                                        }}
                                                    >
                                                        {cohort.name}
                                                    </div>
                                                ))}
                                                {filteredCohorts.length === 0 && (
                                                    <div style={{padding: '10px 14px', color: '#8B8B8B'}}>
                                                        No cohorts found
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                onClick={() => onAddNewCohort()}
                                                style={{
                                                    marginTop: 16,
                                                    marginLeft: 12,
                                                    background: "#353738",
                                                    borderRadius: 4,
                                                    height: 30,
                                                    width: 154,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: '#EDEDED',
                                                    fontSize: 12,
                                                    fontWeight: 600,
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Create New Segment
                                            </div>
                                        </div>
                                        : <></>}
                                    trigger={['click']}
                                >
                                    {(renameCohort) ?
                                        <div style={{position: 'relative', display: 'inline-block'}}>
                                            <input
                                                onChange={(e) => setCohortName(e.target.value)}
                                                value={cohortName}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && cohortName.trim() && cohortName !== previousCohortCurrent?.name) {
                                                        if (saveInitialSegment) {
                                                            saveAsNewSegment();
                                                        } else {
                                                            setRenameCohort(false);
                                                            onSaveCohort();
                                                        }
                                                    }
                                                }}
                                                style={{
                                                    minWidth: 400,
                                                    padding: '8px 40px 8px 8px',
                                                    background: 'transparent',
                                                    color: 'white',
                                                    border: '1px solid #8B8B8B'
                                                }}
                                                placeholder="Name your Segment"
                                            />
                                            {cohortName.trim() && (saveInitialSegment || cohortName !== previousCohortCurrent?.name) && (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: 8,
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 4
                                                    }}
                                                >
                                                    <Checkbox
                                                        className="custom-checkbox-background"
                                                        onChange={() => {
                                                            if (saveInitialSegment) {
                                                                saveAsNewSegment();
                                                            } else {
                                                                setRenameCohort(false);
                                                                onSaveCohort();
                                                            }
                                                        }}
                                                        checked={true}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        :
                                        <div
                                            onClick={() => setCohortListDropdownVisible(!cohortListDropdownVisible)}
                                            style={{
                                                fontSize: 20,
                                                fontWeight: 700,
                                                color: 'white',
                                                display: 'flex',
                                                gap: 8,
                                                alignItems: 'center',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {(currentCohort.id === -1 && JSON.stringify(currentCohort) !== JSON.stringify(previousCohortCurrent)) ? "Unsaved Segment" : cohortName}
                                            <AiOutlineDown stroke={2} color="#8B8B8B" size={15}/>
                                        </div>
                                    }
                                </Dropdown>
                            </div>
                            <div style={{display: 'flex', gap: 8}}>
                                {(JSON.stringify({...previousCohortCurrent}) !== JSON.stringify({...currentCohort})) && !addNewCohort &&
                                    <div style={{
                                        display: 'flex',
                                        gap: '16px',
                                        borderTop: '1px solid #26282A',
                                    }}>
                                        {!saveInitialSegment && <Button
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '40px',
                                                background: '#61CEF7',
                                                borderRadius: '2px',
                                                fontFamily: '"Montserrat", sans-serif',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#26282A',
                                                padding: '0 16px',
                                                border: 'none'
                                            }}
                                            onClick={saveAsCurrentSegment}
                                        >
                                            {currentCohort.id === -1 ? "Save Segment" : "Update Current Segment"}
                                        </Button>}
                                        {saveInitialSegment && <><Button
                                            style={{
                                                height: '40px',
                                                background: 'transparent',
                                                border: '1px solid #61CEF7',
                                                borderRadius: '2px',
                                                fontFamily: '"Montserrat", sans-serif',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                fontSize: '14px',
                                                color: '#61CEF7'
                                            }}
                                            onClick={() => {
                                                setSaveInitialSegment(false)
                                                setRenameCohort(false)
                                                setCohortName("All Visitors")
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                            <Button
                                                style={{
                                                    height: '40px',
                                                    background: '#61CEF7',
                                                    border: '1px solid #61CEF7',
                                                    borderRadius: '2px',
                                                    fontFamily: '"Montserrat", sans-serif',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    fontSize: '14px',
                                                    color: '#26282A'
                                                }}
                                                onClick={() => {
                                                    cohortName.trim() && saveAsNewSegment()
                                                }}
                                            >
                                                Finish
                                            </Button>
                                        </>
                                        }
                                        {currentCohort.id !== -1 && <Button
                                            style={{
                                                height: '40px',
                                                background: 'transparent',
                                                border: '1px solid #61CEF7',
                                                borderRadius: '2px',
                                                fontFamily: '"Montserrat", sans-serif',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#61CEF7',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '0 16px'
                                            }}
                                            onClick={saveAsNewSegment}
                                        >
                                            Save as New Segment
                                        </Button>}
                                    </div>}
                                {/*<Dropdown trigger={["click"]} placement="bottomLeft" overlay={<div style={{ height: 261, width: 160, background: 'black', borderRadius: 8, color: 'white', }}>*/}
                                {/*    <div style={{ padding: 16, paddingBottom: 8, borderBottom: '1px solid #353738', fontWeight: 700, fontSize: 14 }}>*/}
                                {/*        Export Fields*/}
                                {/*    </div>*/}
                                {/*    <div style={{ padding: '8px 16px', fontSize: 12, fontWeight: 700, borderBottom: '1px solid #353738', display: 'flex', alignItems: 'center', gap: 8 }}>*/}
                                {/*        <Checkbox*/}
                                {/*            className="custom-checkbox-background"*/}

                                {/*            onChange={(event) => {*/}

                                {/*            }}*/}

                                {/*            checked={true}*/}
                                {/*        />All Fields*/}
                                {/*    </div>*/}

                                {/*    <div style={{ height: 105, overflowY: 'scroll', padding: '8px 16px', fontSize: 12, display: 'flex', gap: 8, flexDirection: 'column' }}>*/}
                                {/*        {attributes.map(a => <><div style={{ display: 'flex', alignItems: 'center', fontWeight: 500, gap: 8 }}><Checkbox*/}
                                {/*            className="custom-checkbox-background"*/}

                                {/*            onChange={(event) => {*/}

                                {/*            }}*/}
                                {/*            checked={true}*/}
                                {/*        />{a.label}</div></>)}*/}
                                {/*    </div>*/}
                                {/*    <div style={{ padding: '16px 16px', }}>*/}
                                {/*        <div style={{ height: 30, width: 106, padding: '4px 16px', borderRadius: 4, fontSize: 12, fontWeight: 700, cursor: 'pointer', background: '#353738', color: '#EDEDED', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>*/}
                                {/*            Export CSV*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>}>*/}
                                {/*    */}
                                {/*</Dropdown>*/}

                                <Tooltip 
                                    title="Download saved segment" 
                                    placement="bottom"
                                    overlayStyle={{
                                        fontSize: '12px',
                                        fontFamily: 'Montserrat'
                                    }}
                                    visible={!disableDownload ? undefined : false}
                                >
                                    <div onClick={() => {
                                       if(!disableDownload) {
                                        setShowDownloadSuccess(true);
                                       }
                                    }} style={{
                                        cursor: !disableDownload ? 'pointer' : 'not-allowed',
                                        height: 44,
                                        width: 44,
                                        padding: 10,
                                        background: '#26282A',
                                        border: 'border: 1px solid #26282A',
                                        borderRadius: 2
                                    }}>
                                        <img alt={'download'} src={downLoadIcon} style={{height: 24, width: 24}}/>
                                    </div>
                                </Tooltip>
                                {/* Dropdown for setting three menu icon */}
                                <div ref={menuDropdownRef}>
                                    <Dropdown
                                        trigger={["click"]}
                                        visible={menuDropdownVisible}
                                        overlay={
                                            <div style={{
                                                width: 160,
                                                background: 'black',
                                                borderRadius: 8,
                                                color: 'white',
                                                cursor: 'pointer'
                                            }}>
                                                <div style={{
                                                    padding: 16,
                                                    paddingBottom: 8,
                                                    borderBottom: '1px solid #353738',
                                                    fontWeight: 700,
                                                    fontSize: 14,
                                                    color: currentCohort.id === -1 ? '#8B8B8B' : (disableSaveButton() ? '#8B8B8B' : '#50E8B1')
                                                }}>
                                                    <div
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (!disableSaveButton() && currentCohort.id !== -1) {
                                                                setRenameCohort(false);
                                                                onSaveCohort();
                                                            }
                                                        }}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            cursor: currentCohort.id === -1 ? 'not-allowed' : (disableSaveButton() ? 'not-allowed' : 'pointer')
                                                        }}
                                                    >
                                                        <img src={checkCircle}
                                                             style={{opacity: currentCohort.id === -1 ? 0.5 : 1}}/>Save
                                                    </div>
                                                </div>
                                                <div style={{
                                                    padding: '8px 16px',
                                                    borderBottom: '1px solid #353738',
                                                    fontSize: 12,
                                                    fontWeight: 700
                                                }}>
                                                    <div
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (currentCohort.id !== -1) {
                                                                setRenameCohort(true);
                                                                setMenuDropdownVisible(false);
                                                            }
                                                        }}
                                                        style={{
                                                            marginBottom: 12,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            color: currentCohort.id === -1 ? '#8B8B8B' : 'white',
                                                            cursor: currentCohort.id === -1 ? 'not-allowed' : 'pointer'
                                                        }}
                                                    >
                                                        <img src={editIcon}
                                                             style={{opacity: currentCohort.id === -1 ? 0.5 : 1}}/>Rename
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            color: currentCohort.id === -1 ? '#8B8B8B' : 'white',
                                                            cursor: currentCohort.id === -1 ? 'not-allowed' : 'pointer'
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (currentCohort.id !== -1) {
                                                                onDuplicateCohort();
                                                                setMenuDropdownVisible(false);
                                                            }
                                                        }}
                                                    >
                                                        <img src={duplicateIcon}
                                                             style={{opacity: currentCohort.id === -1 ? 0.5 : 1}}/>Duplicate
                                                    </div>
                                                </div>
                                                <div style={{
                                                    padding: '8px 16px',
                                                    borderBottom: '1px solid #353738',
                                                    fontSize: 12,
                                                    fontWeight: 700,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 8
                                                }}>
                                                    <div onClick={(e) => {
                                                        e.stopPropagation();
                                                        setPropertiesModalVisible(!propertiesModalVisible);
                                                    }} style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        position: 'relative'
                                                    }}>
                                                        <img src={propertiesIcon}/>Edit Properties
                                                        {propertiesModalVisible && renderPropertiesModal()}
                                                    </div>
                                                </div>
                                                <div style={{padding: '8px 16px', fontSize: 12, fontWeight: 700}}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            color: currentCohort.id === -1 ? '#8B8B8B' : 'white',
                                                            cursor: currentCohort.id === -1 ? 'not-allowed' : 'pointer'
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (currentCohort.id !== -1) {
                                                                setDeleteModal(true);
                                                                setMenuDropdownVisible(false);
                                                            }
                                                        }}
                                                    >
                                                        <img src={deleteIcon}
                                                             style={{opacity: currentCohort.id === -1 ? 0.5 : 1}}/>Delete
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <div
                                            onClick={() => setMenuDropdownVisible(!menuDropdownVisible)}
                                            style={{
                                                height: 44,
                                                width: 44,
                                                padding: 10,
                                                background: '#26282A',
                                                border: 'border: 1px solid #26282A',
                                                borderRadius: 2
                                            }}
                                        >
                                            <img style={{height: 24, width: 24}} src={threeDotMenu} alt="menu"/>
                                        </div>
                                    </Dropdown>
                                </div>

                            </div>
                        </div>
                    </Row>
                    <Row
                        justify="space-between"
                        align="middle"
                        style={{marginTop: 20, marginBottom: 20}}
                        className="apiandservices-newapp-btn"
                    >
                        <CustomConditionsBuilder
                            selectedCohort={JSON.parse(JSON.stringify(currentCohort))}
                            attributes={attributes}
                            editEnabled={true}
                            updateSelectedCohortConditions={updateSelectedCohortFilters}
                            updateSelectedCohortFilters={updateSelectedCohortFilters}
                            saveAsCurrentSegment={saveAsCurrentSegment}
                            saveAsNewSegment={saveAsNewSegment}
                            isUpdating={(!(JSON.stringify({...previousCohortCurrent}) === JSON.stringify({...currentCohort}))) && !addNewCohort}
                        />

                    </Row>

                    {renderMainSections()}
                    {deleteModal && <DeleteTypeModal visible={deleteModal} handleOk={() => {
                        onCohortDelete()
                    }} handleCancel={() => setDeleteModal(false)} title="Delete Segment"
                                                     message={"Are you sure you want to delete this Segment"}
                                                     confirmText={"Please confirm by typing DELETE into the field below:"}/>}
                </SideDrawer>
                {renderUnsavedChangesModal()}
            </div>
            <InfoModal
                display={showDownloadSuccess}
                title={"Your segment is being processed. This may take a few minutes. Once your export is ready, you'll receive an email with a link to download the file."}
                onOk={() => {
                    if (!disableDownload) {
                        downloadSegmentCall();
                    }
                    setShowDownloadSuccess(false);
                }}
                onCancel={() => {
                    setShowDownloadSuccess(false);
                }}
            />
        </>
    );
}
