import { useEffect, useState, useMemo } from "react";
import SideDrawer from "../../Components/SideDrawer";
import {
    Button,
    Checkbox,
    Col,
    Modal,
    Row,
    Switch,
    Typography,
    Tooltip as ToolTip
} from "antd";
import { identitProvidersType } from "../../Utils/Helper";
import moment, { locale } from "moment";
import { AiOutlineDown } from "react-icons/ai";
import { IdentityProviderStatsAuthStrip } from "./components/IdentityProviderStatsAuthStrip";
import { IdentityProviderGraphStrip } from "./components/IdentityProviderlockrGraphStrip";
import { UIDNoCredentialModal } from "../../Components/UIDNoCredentialModal";
import { TitleTextPassword } from "../../Components/TitleTextPassword";
import { LockrInfoIcon } from "../../Components/UIDCredentialsModal";
import {
    getDeletedUIDCredentialsAPI,
    getIdentityProviderSetting,
    getUIDAnalytics,
    getUIDConnectedEmailCount,
    getUIDConsentedUsers,
    getUIDCredentialsAPI,
    getUIDUserGraphData,
    getUserAuthenticationDashboardData,
    removeUIDIntegration,
    sendYahooConnectIdEmail,
    upsertIdentityProviderSetting,
    upsertUIDCredentialsAPI
} from "../../Utils/ApiActions";

function YahooConnectIDCredentialsModal({
    display,
    onOk,
    onCancel,
    credentials,
    error,
    onHideError,
}) {
    const [modalCredentials, setModalCredentials] = useState(credentials ?? {
        accessKey: "",
        secretKey: "",
        metaData: {},
        // useLockrKeys: false,
    });
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        onHideError();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalCredentials]);

    return (
        <Modal centered
            width={750}
            footer={null}
            closable={false}
            bodyStyle={{
                minHeight: 400,
                width: 750,
                backgroundColor: "#353738",
                paddingRight: 60,
                paddingLeft: 60,
                paddingTop: 60,
                overflowX: "hidden",
            }}
            destroyOnClose
            style={{
                top: 20,
                paddingBottom: 20,
            }}
            visible={display}>
            <img
                style={{ right: 60 }}
                alt="close"
                onClick={onCancel}
                src={require("../../assets/modal-close.png")}
                className="delete-x-button-connection"
            />
            <div>
                <Typography.Title
                    style={{
                        display: "inline-block",
                        fontFamily: "Montserrat",
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: 20,
                        paddingBottom: 40,
                        paddingTop: 8,
                    }}
                >
                    {"Credentials"}
                </Typography.Title>
                <div style={{ marginTop: 16 }} />
                <TitleTextPassword
                    placeholder={" "}
                    label={"Yahoo Publisher ID"}
                    marginLeft={69}
                    eyeRight={90}
                    // disabled={modalCredentials.useLockrKeys}
                    supportCopy
                    supportDisabledColor
                    value={
                        modalCredentials?.metaData?.publisherID
                    }
                    // hideEye={modalCredentials.useLockrKeys}
                    onChange={(event) =>
                        setModalCredentials({
                            ...modalCredentials,
                            metaData: {
                                ...modalCredentials.metaData,
                                publisherID: event.target.value,
                            },
                        })
                    }
                />
                <div style={{ marginTop: 16 }} />
                <TitleTextPassword
                    placeholder={" "}
                    label={"Yahoo Client ID"}
                    marginLeft={98}
                    eyeRight={90}
                    // disabled={modalCredentials.useLockrKeys}
                    supportCopy
                    supportDisabledColor
                    value={

                        modalCredentials?.accessKey
                    }
                    // hideEye={modalCredentials.useLockrKeys}
                    onChange={(event) =>
                        setModalCredentials({
                            ...modalCredentials,
                            accessKey: event.target.value,
                        })
                    }
                />
                <div style={{ marginTop: 16 }} />
                <TitleTextPassword
                    placeholder={" "}
                    label={"Yahoo Client Secret Key"}
                    marginLeft={30}
                    eyeRight={90}
                    // disabled={modalCredentials.useLockrKeys}
                    supportCopy
                    supportDisabledColor
                    value={

                        modalCredentials?.secretKey
                    }
                    // hideEye={modalCredentials.useLockrKeys}
                    onChange={(event) =>
                        setModalCredentials({
                            ...modalCredentials,
                            secretKey: event.target.value,
                        })
                    }
                />
                {/*<div style={{marginTop: 24, display: "flex", alignItems: "center"}}>
                    <Checkbox
                        // checked={modalCredentials.useLockrKeys}
                        onChange={(event) => {
                            setModalCredentials({
                                ...modalCredentials,
                                // useLockrKeys: event.target.checked,
                            });
                        }}
                    />
                    <span
                        style={{
                            marginLeft: 16,
                            color: "white",
                            fontSize: 16,
                            fontFamily: "Montserrat",
                        }}
                    >
                        Use lockrKeys
                    </span>
                    <ToolTip
                        style={{
                            cursor: "pointer",
                        }}
                        title="Publishers can use their own credentials with service providers or choose to rely on lockr's pre-established connection."
                    >
                        <div
                            style={{
                                marginLeft: 16,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <LockrInfoIcon/>
                        </div>
                    </ToolTip>
                </div> */}
                <div style={{ marginTop: 24 }} />
                <Button
                    type="primary"
                    className="sign-in"
                    onClick={() => {
                        onOk(modalCredentials);
                    }}
                    style={{
                        width: 217,
                        marginTop: 24,
                        cursor: "pointer",
                    }}
                >
                    Confirm
                </Button>
                <div>
                    <span className="errorText">{error}</span>
                </div>
                <div style={{ marginTop: 24, color: 'white', fontSize: 14, fontFamily: 'Montserrat' }}>
                    Don't have Yahoo Connect ID credentials? <span style={{ color: '#61cef7', cursor: 'pointer', fontWeight: 'bold' }} onClick={async () => {
                        try {
                            await sendYahooConnectIdEmail();
                            setShowSuccessModal(true);
                        } catch (error) {
                            console.error("Failed to send Yahoo Connect ID email", error);
                        }
                    }}>Click here</span> to have Yahoo reach out to set up an integration.
                </div>
            </div>
            <Modal
                closable={false}
                centered
                visible={showSuccessModal}
                footer={null}
                onCancel={() => onCancel()}
                width={600}

                bodyStyle={{
                    backgroundColor: "#353738",
                    padding: "30px",

                }}
            >
                <img
                    style={{ right: 30 }}
                    alt="close"
                    onClick={onCancel}
                    src={require("../../assets/modal-close.png")}
                    className="delete-x-button-connection"
                />
                <Typography.Title
                    level={4}
                    style={{
                        color: "#fff",
                        fontFamily: "Montserrat",
                        marginBottom: "20px"
                    }}
                >
                    Request Sent Successfully
                </Typography.Title>

                <Typography.Text
                    style={{
                        color: "#8B8B8B",
                        fontFamily: "Montserrat",
                        fontSize: 14,

                    }}
                >
                    Yahoo will reach out to you shortly to help set up your integration.
                </Typography.Text>

            </Modal>
        </Modal>
    )

}

export function YahooConnectID() {
    const dateFormat = "dddd MM/DD";
    const customFormat = (value) => `${value.format(dateFormat)}`;
    const [displayLoading, setDisplayLoading] = useState(false);
    const [yahooConnectCredentials, setYahooConnectCredentials] = useState(undefined);
    const [deletedCredentials, setDeletedCredentials] = useState(undefined);
    const [displayCredentialsModal, setDisplayCredentialsModal] = useState(false);
    const [upsertError, setUpsertError] = useState("");
    const [identityProviderSettingLoading, setidentityProviderSettingLoading] = useState(false);
    const [identityProviderSettings, setIdentityProviderSetting] = useState({
        dropCookie: false,
        dropLocalStorage: false,
        keepInIdentityLockr: false,
    });
    const [originalIdentityProviderSettings, setoriginalIdentityProviderSetting] =
        useState({
            dropCookie: false,
            dropLocalStorage: false,
            keepInIdentityLockr: false,
        });
    const [graphData, setGraphData] = useState([]);
    const [userCount, setUserCount] = useState(0);
    const [totalConnectedEmail, setTotalConnectedEmails] = useState(0);
    const [consentedUserCount, setConsentedUserCount] = useState(0);
    const [userAuthenticationData, setUserAuthenticationData] =
        useState(undefined);
    const [userAuthDataStartDate, setUserAuthDataStartDate] = useState(
        moment().subtract(7, "days")
    );
    const [userAuthDataEndDate, setUserAuthDataEndDate] = useState(moment());

    const [graphFilterStartDate, setGraphFilterStartDate] = useState(
        moment().subtract(7, "days")
    );
    const [graphFilterEndDate, setGraphFilterEndDate] = useState(moment());
    const [loginTrendsAppIDs, setLoginTrendsAppIDs] = useState([]);
    const [authDataAppIDs, setAuthDataAppIDs] = useState([]);

    const ssoUserCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType === "lockrMail") {
                    result = parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);

    const utmUserCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType === "UTM") {
                    result = parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);

    const emailCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType !== "UTM" && item.eventType !== "lockrMail") {
                    result += parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);

    const [endDate, setEndDate] = useState(
        moment().add(1, "day").format("yyyy-MM-DD")
    );
    const [startDate, setStartDate] = useState(
        moment().subtract(1, "month").format("yyyy-MM-DD")
    );
    const [analyticsData, setAnalyticsData] = useState([]);
    const [lockrMailAppIDs, setLockrMailAppIDs] = useState([]);


    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
        });
        getYahooCredentials();
    }, []);

    useEffect(() => {
        if (yahooConnectCredentials?.accessKey) {
            getUserAuthenticationData();
        }
    }, [yahooConnectCredentials, userAuthDataEndDate, userAuthDataStartDate, authDataAppIDs]);

    useEffect(() => {
        yahooConnectCredentials?.accessKey && getGraphData();
    }, [yahooConnectCredentials, graphFilterStartDate, graphFilterEndDate, loginTrendsAppIDs]);

    useEffect(() => {
        yahooConnectCredentials?.accessKey && getUserStats();
    }, [yahooConnectCredentials, startDate, endDate, lockrMailAppIDs]);

    useEffect(() => {
        yahooConnectCredentials?.accessKey && getUserStats();
        yahooConnectCredentials?.accessKey && getUIDAnalyticsCall();
        yahooConnectCredentials?.accessKey && getIdentityProviderSettingsCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yahooConnectCredentials]);

    function getUserStats() {
        //SC-24105, Hide lockrMail
        // getTotalUsers();
        getTotalConnectedEmailCount();
        getTotalConsentedUsers();
    }

    async function getTotalConsentedUsers() {
        try {
            const response = await getUIDConsentedUsers(startDate, endDate, lockrMailAppIDs);
            if (response.success) {
                setConsentedUserCount(response.data);
            }
        } catch (error) {
            console.log("Unable to get the total consented users");
            console.log(error);
        }
    }

    async function getTotalConnectedEmailCount() {
        try {
            const response = await getUIDConnectedEmailCount(
                startDate,
                endDate,
                identitProvidersType.YAHOO_CONNECT_ID,
                lockrMailAppIDs
            );
            if (response.success) {
                setTotalConnectedEmails(response.data);
            }
        } catch (error) {
            console.log("Unable to get the connected email count");
            console.log(error);
        }
    }

    async function getGraphData() {
        try {
            const response = await getUIDUserGraphData(
                startDate,
                endDate,
                identitProvidersType.YAHOO_CONNECT_ID,
                loginTrendsAppIDs
            );
            if (response.success) {
                setGraphData(response.data.data);
            }
        } catch (error) {
            console.log("Unable to get the graph data at the moment");
            console.log(error);
        }
    }


    async function getYahooCredentials() {
        setDisplayLoading(true);
        try {
            const response = await getUIDCredentialsAPI(identitProvidersType.YAHOO_CONNECT_ID);
            const { data, success } = response;
            if (success && data?.accessKey) {
                setYahooConnectCredentials(data);
            } else {
                getDeletedCredentials();
            }
        } catch (error) {
            console.log("ERROR: Unable to get Yahoo Connect Credentials");
            console.log(error);
        }
        setDisplayLoading(false);
    }

    async function getDeletedCredentials() {
        try {
            const { data, success } = await getDeletedUIDCredentialsAPI(
                identitProvidersType.YAHOO_CONNECT_ID
            );
            if (success && data?.accessKey) {
                setDeletedCredentials({ ...data, isDeleted: false });
            }
        } catch (error) {
            console.log("Unable to get the deleted credentials at the moment");
            console.log(error);
        }
    }

    async function getUserAuthenticationData() {
        try {
            const response = await getUserAuthenticationDashboardData(
                userAuthDataStartDate,
                userAuthDataEndDate,
                identitProvidersType.YAHOO_CONNECT_ID,
                authDataAppIDs
            );
            if (response.success) {
                setUserAuthenticationData(response.data);
                return;
            }
            setUserAuthenticationData([]);
        } catch (error) {
            console.log("Unable to get the data for the user authentication");
            console.log(error);
        }
    }

    async function getUIDAnalyticsCall() {
        try {
            const resposne = await getUIDAnalytics(startDate, endDate);
            if (resposne.success) {
                const result = [];
                resposne.data.map((item, index) => {
                    if (index !== resposne.data.length - 1) {
                        result.push({
                            ...item,
                            visitorCount:
                                item.visitorCount === null ? 0 : parseInt(item.visitorCount),
                            tokenCount:
                                item.tokenCount === null ? 0 : parseInt(item.tokenCount),
                        });
                    }
                });
                setAnalyticsData(result);
            }
        } catch (error) {
            console.log("Unable to get the analytics at the moment");
            console.log(error);
        }
    }

    async function removeYahooIntegrationsCall() {
        try {
            const response = await removeUIDIntegration(identitProvidersType.YAHOO_CONNECT_ID);
            if (response.success && response.data) {
                setDeletedCredentials({ ...yahooConnectCredentials });
                setYahooConnectCredentials(undefined);
            }
        } catch (error) {
            console.log("ERROR: Unable to remove Yahoo Connect Integrations");
            console.log(error);
        }
    }

    async function saveYahooConnectCredentials(credentials) {
        setDisplayLoading(true);
        try {
            const response = await upsertUIDCredentialsAPI(credentials.accessKey, credentials.secretKey, identitProvidersType.YAHOO_CONNECT_ID, false, credentials.metaData);
            if (!response.success) {
                setDisplayLoading(false);
                setUpsertError(response.error);
                return;
            }
            getYahooCredentials();
            setDisplayCredentialsModal(false);
        } catch (error) {
            console.log("ERROR: Unable to save Yahoo Connect Credentials");
            console.log(error);
        }
        setDisplayLoading(false);
    }

    async function getIdentityProviderSettingsCall() {
        try {
            setidentityProviderSettingLoading(true);
            const { success, data } = await getIdentityProviderSetting(
                identitProvidersType.YAHOO_CONNECT_ID
            );
            if (success && data) {
                setIdentityProviderSetting({
                    dropCookie: data.dropCookie,
                    dropLocalStorage: data.dropLocalStorage,
                    keepInIdentityLockr: data.keepInIdentityLockr,
                });
                setoriginalIdentityProviderSetting({
                    dropCookie: data.dropCookie,
                    dropLocalStorage: data.dropLocalStorage,
                    keepInIdentityLockr: data.keepInIdentityLockr,
                });
            }
            setidentityProviderSettingLoading(false);
        } catch (error) {
            setidentityProviderSettingLoading(false);
            console.log(
                "Unable to get the identitty provider settings at the moment"
            );
            console.log(error);
        }
    }


    async function upsertIdentityProviderSettingCall() {
        try {
            setidentityProviderSettingLoading(true);
            const { success, data } = await upsertIdentityProviderSetting(
                identitProvidersType.YAHOO_CONNECT_ID,
                identityProviderSettings.dropCookie,
                identityProviderSettings.dropLocalStorage,
                identityProviderSettings.keepInIdentityLockr
            );
            if (success && data) {
                setIdentityProviderSetting(data);
                getIdentityProviderSettingsCall();
            }
            setidentityProviderSettingLoading(false);
            setidentityProviderSettingLoading(false);
        } catch (error) {
            console.log(
                "Unable to upsert the identity provider setting at the moment"
            );
            console.log(error);
        }
    }

    function displaySwitch() {
        return (
            <div>
                <Switch
                    checked={yahooConnectCredentials !== undefined}
                    onChange={(checked) => {
                        !checked && removeYahooIntegrationsCall();
                    }}
                />
                <Typography.Text
                    onClick={() => {
                        setDisplayCredentialsModal(true);
                    }}
                    className="infosum-view-credentials-text"
                >
                    Settings
                </Typography.Text>
            </div>
        );
    }

    function renderStats() {
        return (
            <div
                style={{
                    marginTop: 72,
                    marginLeft: 90,
                    marginRight: 90,
                }}
            >
                <IdentityProviderStatsAuthStrip
                    utmCount={utmUserCount}
                    ssoCount={ssoUserCount}
                    emailCount={emailCount}
                    startDate={userAuthDataStartDate}
                    endDate={userAuthDataEndDate}
                    allData={userAuthenticationData}
                    onChangeEndDate={setUserAuthDataEndDate}
                    onChangeStartDate={setUserAuthDataStartDate}
                    setAuthDataAppIDs={setAuthDataAppIDs}
                    authDataAppIDs={authDataAppIDs}
                />
                <div style={{ marginTop: 60 }} />
                <IdentityProviderGraphStrip
                    endDate={graphFilterEndDate}
                    startDate={graphFilterStartDate}
                    graphData={graphData}
                    onChangeEndDate={setGraphFilterEndDate}
                    onChangeStartDate={setGraphFilterStartDate}
                    setLoginTrendsAppIDs={setLoginTrendsAppIDs}
                    loginTrendsAppIDs={loginTrendsAppIDs}
                />
            </div>
        );
    }


    function renderSettings() {
        return (
            <Row
                justify="space-between"
                align="middle"
                className="apiandservices-newapp-btn"
            >
                <div>
                    <Typography.Title className="apiandservices-heading">
                        Storage Settings
                    </Typography.Title>
                    <div style={{ marginTop: 32 }} />
                    <div style={{ display: "flex", flexDirection: "row", gap: 48 }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 6,
                                alignItems: "center",
                            }}
                        >
                            <Switch
                                size="small"
                                checked={identityProviderSettings.keepInIdentityLockr}
                                onChange={(checked) => {
                                    setIdentityProviderSetting({
                                        ...identityProviderSettings,
                                        keepInIdentityLockr: checked,
                                        dropCookie: checked,
                                        dropLocalStorage: checked,
                                    });
                                }}
                            />
                            <span
                                style={{
                                    color: "white",
                                    fontSize: 16,
                                    fontFamily: "Montserrat",
                                }}
                            >
                                Keep in AIM
                            </span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                            <Checkbox
                                onChange={(event) => {
                                    setIdentityProviderSetting({
                                        ...identityProviderSettings,
                                        dropCookie: !event.target.checked,
                                    });
                                }}
                                disabled={identityProviderSettings.keepInIdentityLockr}
                                checked={!identityProviderSettings.dropCookie}
                            />
                            <span
                                style={{
                                    color: "white",
                                    fontSize: 16,
                                    fontFamily: "Montserrat",
                                }}
                            >
                                Cookie
                            </span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                            <Checkbox
                                onChange={(event) => {
                                    setIdentityProviderSetting({
                                        ...identityProviderSettings,
                                        dropLocalStorage: !event.target.checked,
                                    });
                                }}
                                disabled={identityProviderSettings.keepInIdentityLockr}
                                checked={!identityProviderSettings.dropLocalStorage}
                            />
                            <span
                                style={{
                                    color: "white",
                                    fontSize: 16,
                                    fontFamily: "Montserrat",
                                }}
                            >
                                Local storage
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <Button
                        disabled={
                            identityProviderSettings.dropCookie ===
                            originalIdentityProviderSettings.dropCookie &&
                            identityProviderSettings.keepInIdentityLockr ===
                            originalIdentityProviderSettings.keepInIdentityLockr &&
                            identityProviderSettings.dropLocalStorage ===
                            originalIdentityProviderSettings.dropLocalStorage
                        }
                        loading={identityProviderSettingLoading}
                        onClick={upsertIdentityProviderSettingCall}
                        style={{ width: "217px" }}
                        className="sign-in"
                    >
                        Save
                    </Button>
                </div>
            </Row>
        );
    }

    function displayConnectModal() {
        if (yahooConnectCredentials === undefined && !displayCredentialsModal && !displayLoading) {
            return (
                <UIDNoCredentialModal
                    display
                    integrationType={identitProvidersType.YAHOO_CONNECT_ID}
                    onOk={() => {
                        setDisplayCredentialsModal(true);
                    }}
                />
            )
        }
    }


    return (
        <SideDrawer>
            <Row
                justify={"space-between"}
                align={"middle"}
                className="apiandservices-newapp-btn"
            >
                <Col sm={12} xs={24}>
                    <Typography.Title className={"apiandservices-heading"}>Yahoo Connect ID</Typography.Title>
                    <div style={{ marginTop: 8 }} />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                        }}
                    >
                        <label
                            style={{
                                fontSize: 14,
                                fontFamily: "Montserrat",
                                color: "#8B8B8B",
                            }}
                            className="dashboard-as-of-today"
                        >
                            Enable to start a connection
                        </label>
                        <div
                            style={{
                                flex: 1,
                                marginLeft: 20,
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                            }}
                        >
                            <div style={{ flex: 1 }}>{displaySwitch()}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            {renderSettings()}
            {displayCredentialsModal && <YahooConnectIDCredentialsModal
                display={displayCredentialsModal}
                onOk={saveYahooConnectCredentials}
                credentials={{
                    accessKey:
                        yahooConnectCredentials?.accessKey ?? deletedCredentials?.accessKey,
                    secretKey:
                        yahooConnectCredentials?.secretKey ?? deletedCredentials?.secretKey,
                    metaData: yahooConnectCredentials?.metaData ?? deletedCredentials?.metaData ?? {},
                    // useLockrKeys:
                    //     yahooConnectCredentials?.useLockrKeys ??
                    //     deletedCredentials?.useLockrKeys ??
                    //     false,
                }}
                onCancel={() => {
                    setDisplayCredentialsModal(false);
                }}
                error={upsertError}
                onHideError={() => {
                    setUpsertError("");
                }}
            />}
            {renderStats()}
            {displayConnectModal()}
        </SideDrawer>
    )

}
