import React, { useState } from 'react';
import { Modal, Button, Input, Typography, Row } from 'antd';
import { TitleText } from './NewAppModal';
import deleteIcon from '../assets/delete-icon.png'
import { sendIllumaEmail } from '../Utils/ApiActions';
import { TitleTextPassword } from './TitleTextPassword';

export function IllumaCredentialModal({ visible, onCancel, onOk = () => { }, apiKey, rssFeedEntries, setApiKey, setRssFeedEntries }) {
    const [inputErrors, setInputErrors] = useState({});
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const isValidUrl = (url) => {
        try {
            new URL(url);
            return true;
        } catch (err) {
            return false;
        }
    };

    const validateUrls = () => {
        const errors = {};
        let isValid = true;

        rssFeedEntries.forEach((entry, index) => {
            if (!entry.url || !isValidUrl(entry.url)) {
                errors[index] = 'Please enter a valid URL';
                isValid = false;
            }
        });

        setInputErrors(errors);
        return isValid;
    };

    return (
        <Modal
            visible={visible}
            style={{ top: 20, paddingBottom: 20, }}
            centered
            width={776}
            footer={null}
            closable={false}
            destroyOnClose
            bodyStyle={{ minHeight: 508, width: 776, backgroundColor: '#353738', paddingLeft: 60, paddingTop: 40, paddingRight: 0, overflowX: 'hidden' }}
        >
            <img className='delete-x-button-connection' alt={'Close'} onClick={onCancel} src={require('../assets/modal-close.png')} />
            <div>
                <Typography.Title style={{ display: 'inline-block', fontFamily: 'Montserrat', color: '#fff', fontWeight: 700, fontSize: 20, paddingBottom: 40, paddingTop: 8 }}>Credentials</Typography.Title>
            </div>
            <div>
                <TitleTextPassword
                    placeholder={" "}
                    label={"API Key"}
                    marginLeft={217}

                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                />
                <div style={{ marginTop: 24 }} />
                <div style={{ display: 'flex', }}><div style={{ color: 'white', fontFamily: 'Montserrat', fontSize: 15, marginRight: 128 }}>RSS Feed (Optional)</div>
                    <div style={{ marginLeft: !rssFeedEntries?.length && 245 }}>{!rssFeedEntries?.length ? <Button
                        onClick={(event) => {
                            setRssFeedEntries([...rssFeedEntries, ""])
                        }}
                        type="default"
                        style={{
                            background: "transparent",
                            width: 119,
                            height: 40,
                            color: "#61CEF7",
                            border: "1px solid #61CEF7",
                            fontFamily: "Montserrat",
                        }}
                        className="add-uri-btn"
                    >
                        Add More
                    </Button> : rssFeedEntries?.map((r, i) => <><div style={{ display: 'flex', alignItems: 'center' }}><div style={{ width: 365 }}><Input
                        value={r.url}
                        status={inputErrors[i] ? 'error' : ''}
                        style={{
                            color: "#fff",
                            border: "1px solid #8B8B8B",
                            width: 300
                        }}
                        onChange={(e) => {
                            let temp = JSON.parse(JSON.stringify(rssFeedEntries));
                            temp[i] = {
                                ...temp[i],
                                url: e.target.value
                            }
                            setRssFeedEntries(temp);
                            if (inputErrors[i]) {
                                const newErrors = { ...inputErrors };
                                delete newErrors[i];
                                setInputErrors(newErrors);
                            }
                        }}
                        className="form-input new-app-modal-input ant-input-40 connection-screen-input illumna-input"
                    />
                        {inputErrors[i] && (
                            <div style={{
                                color: '#ff4d4f',
                                fontSize: '12px',
                                marginTop: '4px'
                            }}>
                                {inputErrors[i]}
                            </div>
                        )}
                    </div>
                        <img src={deleteIcon} style={{ height: 30, width: 28, cursor: 'pointer', }} onClick={() => setRssFeedEntries(prevEntries => prevEntries.filter((_, index) => index !== i))} />
                    </div><div style={{ marginTop: 24 }} /> </>)}</div>
                </div>

                <div />
                {rssFeedEntries?.length ? <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingRight: 72 }}>
                    <Button
                        onClick={(event) => {
                            setRssFeedEntries([...rssFeedEntries, ""])
                        }}
                        type="default"
                        style={{
                            background: "transparent",
                            width: 119,
                            height: 40,
                            color: "#61CEF7",
                            border: "1px solid #61CEF7",
                            fontFamily: "Montserrat",
                        }}
                        className="add-uri-btn"
                    >
                        Add More
                    </Button>
                </div> : <></>}

            </div>
            <Row style={{ marginTop: 50 }}>
                <Button
                    disabled={!apiKey}
                    type={"primary"}
                    style={{ width: 200 }}
                    loading={false}
                    onClick={() => {
                        if (validateUrls()) {
                            onOk();
                        }
                    }}
                    className='sign-in'
                >{"Confirm"}</Button>
            </Row>
            <Row>
                <div style={{ color: 'white', marginTop: 24, fontSize: 14, fontFamily: 'Montserrat' }}>
                    Don't have an Illuma account? <span onClick={async () => {
                        try {
                            await sendIllumaEmail();
                            setShowSuccessModal(true);
                        } catch (error) {
                            console.error("Failed to send Illuma email", error);
                        }
                    }} style={{ color: "#61cef7", fontWeight: 'bold', cursor: 'pointer' }}>Click here</span> to have Illuma reach out to set up an integration.
                </div>
            </Row>
            <Modal
                destroyOnClose
                closable={false}
                centered
                visible={showSuccessModal}
                footer={null}
                onCancel={() => {
                    onCancel();
                    setShowSuccessModal(false);
                }}
                width={600}
                bodyStyle={{
                    backgroundColor: "#353738",
                    padding: "30px",

                }}
            >
                <img
                    style={{ right: 30 }}
                    alt="close"
                    onClick={() => {
                        onCancel();
                        setShowSuccessModal(false);
                    }}
                    src={require("../assets/modal-close.png")}
                    className="delete-x-button-connection"
                />
                <Typography.Title
                    level={4}
                    style={{
                        color: "#fff",
                        fontFamily: "Montserrat",
                        marginBottom: "20px"
                    }}
                >
                    Request Sent Successfully
                </Typography.Title>

                <Typography.Text
                    style={{
                        color: "#8B8B8B",
                        fontFamily: "Montserrat",
                        fontSize: 14,

                    }}
                >
                    Illuma will reach out to you shortly to help set up your integration.
                </Typography.Text>

            </Modal>
        </Modal>
    )
} 