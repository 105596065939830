/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Dropdown
} from "antd";
import { useEffect, useState } from "react";
import { AiOutlineCheck, AiOutlineDown } from "react-icons/ai";
import {
    listAllApplications
} from "../../../../Utils/ApiActions";
import { DataEndpointTitleValue } from "../AWSDataEndpoint";


export function FilterChip({ item, onClose, index, hideClose, extraClass, disabled }) {
	return (
		<div style={{}} className={`filter-chip-parent ${extraClass} ${disabled ? '-disabled' : ''}`}>
			<label className={`filter-chip-text ${disabled ? '-disabled' : ''}`}>{item?.name}</label>
			{!hideClose && <img onClick={() => onClose(item)} alt='cross' style={{ marginLeft: 10, cursor: 'pointer', width: 12 }} src={require("../../../../assets/modal-close.png")} />}
		</div>
	)
}

export function AppSelectionDropdown({ value, onChange, domainsValue = [], onDomainChange = () => {}, disabled }) {
    const [appList, setAppList] = useState([]);
    const [selectedApps, setSelectedApps] = useState([]);
    const [displayCustomFilter, setDisplayCustomFilter] = useState(false);
    const [domainList, setDomainList] = useState([]);
    const [selectedDomains, setSelectedDomains] = useState([]);
    const [displayCustomFilterDomain, setDisplayCustomFilterDomain] = useState(false);
  
    useEffect(() => {
      getApplications();
    }, []);
  
    useEffect(() => {
      if(value?.length) {
        setSelectedApps(value);
      } else {
        setSelectedApps(appList?.map(app => app.id));
        onChange(appList?.map(app => app.id));
      }
    }, [value, appList]);

    useEffect(() => {
      domainsValue?.length && setSelectedDomains(domainsValue);
    }, [domainsValue]);
  
    async function getApplications() {
      try {
        const response = await listAllApplications();
        if (response.data?.length) {
          setAppList(response.data);
          !value?.length && onChange(response.data?.map(item => item.id));
        }
      } catch (error) {
        console.log("Unable to get the list of the apps");
        console.log(error);
      }
    }
  
    useEffect(() => {
      let domains = [];
      if(selectedApps?.length){
        selectedApps?.map(item => {
          const app = appList?.find(app => app.id === item);
          if(app?.javascriptURLs?.length){
            domains = [...domains, ...app.javascriptURLs?.map(item => item.URL)];
          }
        });
        setDomainList(Array.from(new Set(domains)));
      }else{
        appList?.map(item => {
          if(item?.javascriptURLs?.length){
            domains = [...domains, ...item.javascriptURLs?.map(item => item.URL)];
          }
        });
        setDomainList(Array.from(new Set(domains)));
      }
      
      if(!selectedDomains?.length && domainsValue?.length){
        setSelectedDomains(domainsValue);
      }else if(!selectedDomains?.length){
        setSelectedDomains(domains);
        onDomainChange(domains);
      }else{
        const sDomains = selectedDomains.filter(item => domains?.includes(item));
        if(sDomains?.length){
          setSelectedDomains(sDomains);
          onDomainChange(sDomains);
        }else{
          setSelectedDomains(domains);
          onDomainChange(domains);
        }
      }
    }, [selectedApps, appList]);
    
    const menu = (
      <div       className="verticle-dropdown-container"
      style={{
        paddingTop: 8,
        background: "black",
        overflow: "scroll",
      }}>
        <div
          style={{
            marginTop: 10,
          }}
          onClick={() => {
            if(disabled) return;
            setSelectedApps(appList?.map(item => item.id));
            onChange(appList?.map(item => item.id));
            // setDisplayCustomFilter(false);
          }}
        >
          <AiOutlineCheck
            style={{
              color: selectedApps?.length > 0 && selectedApps?.length !== appList?.length ? "transparent" : "#61CEF7",
              marginRight: 8,
            }}
          />
          <span
            style={{
              color: selectedApps?.length > 0 && selectedApps?.length !== appList?.length ? "white" : "#61CEF7",
              cursor: "pointer",
              fontFamily: "Montserrat",
              fontSize: 14,
            }}
          >
            All
          </span>
        </div>
        {appList?.map((item) => {
          return (
            <div
              style={{
                marginTop: 10,
              }}
              onClick={() => {
                if(disabled) return;
                if (!selectedApps?.includes(item.id)) {
                  setSelectedApps([...selectedApps, item.id]);
                  onChange([...selectedApps, item.id]);
                  // setDisplayCustomFilter(false);
                } else {
                  let selectedAppList = [...selectedApps];
                  if(selectedAppList?.length == appList?.length){
                    selectedAppList = [item.id];
                  }else{
                    selectedAppList.splice(selectedAppList.indexOf(item.id));
                  }
                  if(selectedAppList?.length == 0){
                    selectedAppList = appList?.map(item => item.id);
                  }
                  setSelectedApps(selectedAppList);
                  onChange(selectedAppList);
                }
              }}
            >
              <AiOutlineCheck
                style={{
                  color: selectedApps?.includes(item.id) && selectedApps?.length !== appList?.length ? "#61CEF7" : "transparent",
                  marginRight: 8,
                }}
              />
              <span
                style={{
                  color: selectedApps?.includes(item.id) && selectedApps?.length !== appList?.length ? "#61CEF7" : "white",
                  cursor: "pointer",
                  fontFamily: "Montserrat",
                  fontSize: 14,
                }}
              >
                {item.name}
              </span>
            </div>
          );
        })}
      </div>
    );

    const domainsMenu = (
      <div       className="verticle-dropdown-container"
      style={{
        paddingTop: 8,
        background: "black",
        overflow: "scroll",
      }}>
        <div
          style={{
            marginTop: 10,
          }}
          onClick={() => {
            if(disabled) return;
            setSelectedDomains(domainList?.map(item => item));
            onDomainChange(domainList?.map(item => item));
            // setDisplayCustomFilter(false);
          }}
        >
          <AiOutlineCheck
            style={{
              color: selectedDomains?.length > 0 && selectedDomains?.length !== domainList?.length ? "transparent" : "#61CEF7",
              marginRight: 8,
            }}
          />
          <span
            style={{
              color: selectedDomains?.length > 0 && selectedDomains?.length !== domainList?.length ? "white" : "#61CEF7",
              cursor: "pointer",
              fontFamily: "Montserrat",
              fontSize: 14,
            }}
          >
            All
          </span>
        </div>
        {domainList?.map((item) => {
          return (
            <div
              style={{
                marginTop: 10,
              }}
              onClick={() => {
                if(disabled) return;
                if (!selectedDomains?.includes(item)) {
                  setSelectedDomains([...selectedDomains, item]);
                  onDomainChange([...selectedDomains, item]);
                  // setDisplayCustomFilter(false);
                } else {
                  let selectedDomainsList = [...selectedDomains];
                  if(selectedDomainsList?.length == domainList?.length){
                    selectedDomainsList = [item];
                  }else if(selectedDomainsList?.length > 1){
                    selectedDomainsList.splice(selectedDomainsList.indexOf(item), 1);
                  }
                  setSelectedDomains(selectedDomainsList);
                  onDomainChange(selectedDomainsList);
                }
              }}
            >
              <AiOutlineCheck
                style={{
                  color: selectedDomains?.includes(item) && selectedDomains?.length !== domainList?.length ? "#61CEF7" : "transparent",
                  marginRight: 8,
                }}
              />
              <span
                style={{
                  color: selectedDomains?.includes(item) && selectedDomains?.length !== domainList?.length ? "#61CEF7" : "white",
                  cursor: "pointer",
                  fontFamily: "Montserrat",
                  fontSize: 14,
                }}
              >
                {item}
              </span>
            </div>
          );
        })}
      </div>
    );
  
    return ( <>
        {disabled ? 
          <DataEndpointTitleValue
            title={"App"}
            showDropdown={true}
            value={selectedApps?.length && selectedApps?.length == appList?.length ? 'All' : 'Custom'}
          /> :
        <div
          style={{
            paddingRight: 10,
          }}
        >
          <Dropdown
            overlayStyle={{minWidth: 0}}
            visible={disabled ? false : displayCustomFilter}
            onClick={() => {
              setDisplayCustomFilter(true);
            }}
            onVisibleChange={event => {
              setDisplayCustomFilter(event);
            }}
            destroyPopupOnHide={false}
            placement="bottomLeft"
            trigger={["click"]}
            overlay={menu}
          >
              <div>
                  <DataEndpointTitleValue
                      title={"App"}
                      showDropdown={true}
                      value={selectedApps?.length && selectedApps?.length == appList?.length ? 'All' : 'Custom'}
                      isEdit={true}
                  />
              </div>
          </Dropdown>
        </div>}
        <div style={{marginTop: 12, display: 'flex', gap: 8, minWidth: 600, maxWidth: 600, flexWrap: 'wrap'}}>
          {selectedApps?.map((item, index) => {
            return (
              <FilterChip
                key={index}
                disabled={disabled}
                item={appList?.find(app => app.id === item)}
                onClose={(item) => {
                  if(selectedApps?.length == 1) return
                  let selectedAppList = [...selectedApps];
                  selectedAppList.splice(selectedAppList.indexOf(item.id), 1);
                  setSelectedApps(selectedAppList);
                  onChange(selectedAppList);
                }}
                index={index}
                hideClose={disabled || selectedApps?.length == 1 ? true : false}
              />
            );
          })}
        </div>

        <div style={{marginTop: 24}}>
        {disabled ? 
          <DataEndpointTitleValue
            title={"Domains"}
            showDropdown={true}
            value={selectedDomains?.length && selectedDomains?.length == domainList?.length ? 'All' : 'Custom'}
          /> :
        <div
          style={{
            paddingRight: 10,
          }}
        >
          <Dropdown
            overlayStyle={{minWidth: 0}}
            visible={disabled ? false : displayCustomFilterDomain}
            onClick={() => {
              setDisplayCustomFilterDomain(true);
            }}
            onVisibleChange={event => {
              setDisplayCustomFilterDomain(event);
            }}
            destroyPopupOnHide={false}
            placement="bottomLeft"
            trigger={["click"]}
            overlay={domainsMenu}
          >
              <div>
                  <DataEndpointTitleValue
                      title={"Domains"}
                      showDropdown={true}
                      value={selectedDomains?.length && selectedDomains?.length == domainList?.length ? 'All' : 'Custom'}
                      isEdit={true}
                  />
              </div>
          </Dropdown>
        </div>}
        <div style={{marginTop: 12, display: 'flex', gap: 8, minWidth: 600, maxWidth: 600, flexWrap: 'wrap'}}>
          {selectedDomains?.map((item, index) => {
            return (
              <FilterChip
                key={index}
                disabled={disabled}
                item={{name: item}}
                onClose={(item) => {
                  if(selectedDomains?.length == 1) return
                  let selectedDomainsList = [...selectedDomains];
                  selectedDomainsList.splice(selectedDomainsList.indexOf(item?.name), 1);
                  setSelectedDomains(selectedDomainsList);
                  onDomainChange(selectedDomainsList);
                }}
                index={index}
                hideClose={disabled || selectedDomains?.length == 1 ? true : false}
              />
            );
          })}
        </div>
        </div>
      </>
    );
  }