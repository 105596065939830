/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import SideDrawer from "../../Components/SideDrawer";
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Modal,
  Row,
  Switch,
  Typography,
  Tooltip as ToolTip,
} from "antd";
import {
  checkRawID5EnabledForPublisher,
  getDeletedUIDCredentialsAPI,
  getIdentityProviderSetting,
  getUIDAnalytics,
  getUIDConnectedEmailCount,
  getUIDConsentedUsers,
  getUIDCredentialsAPI,
  getUIDUserCount,
  getUIDUserGraphData,
  getUserAuthenticationDashboardData,
  publisherHasID5Enabled,
  removeUIDIntegration,
  updateRawID5ForPublisher,
  upsertIdentityProviderSetting,
  upsertUIDCredentialsAPI,
} from "../../Utils/ApiActions";
import { identitProvidersType } from "../../Utils/Helper";
import { TitleText } from "../../Components/NewAppModal";
import moment, { locale } from "moment";
import { AiOutlineDown } from "react-icons/ai";
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { UIDNoCredentialModal } from "../../Components/UIDNoCredentialModal";
import { LockrInfoIcon } from "../../Components/UIDCredentialsModal";
import { IdentityProviderlockrNMailStrip } from "./components/IdentityProviderlockrMailStrip";
import { IdentityProviderStatsAuthStrip } from "./components/IdentityProviderStatsAuthStrip";
import { IdentityProviderGraphStrip } from "./components/IdentityProviderlockrGraphStrip";
import { TitleTextPassword } from "../../Components/TitleTextPassword";
import {PiggyBackJSAcknowledgementModal} from "./components/PiggyBackJSAcknowledgementModal";
import { BatchUpload } from "./components/BatchUpload";

function ID5CrdentialsModal({
  display,
  onOk,
  onCancel,
  credentials,
  error,
  onHideError = () => { },
  rawId5Enabled = false,
  onRawID5Change = () => { },
  id5HiddenFeatureEnabled = false,
}) {
  const [modalCredentials, setModalCredentials] = useState(
    credentials ?? {
      accessKey: "",
      secretKey: "",
      useLockrKeys: false,
    }
  );

  const [showPartnerID, setShowPartnerID] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [displayPiggyBackJSModal, setDisplayPiggyBackJSModal] = useState(false);

  useEffect(() => {
    onHideError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalCredentials]);

  function renderTNCLine() {
    return (
      <div
        style={{
          marginTop: 24,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: "#EDEDED",
            fontFamily: "Montserrat",
            fontSize: 14,
            fontWeight: "400",
          }}
        >
          Don't have an ID5 account?
        </span>
        <span
          style={{
            marginLeft: 8,
            color: "white",
            textDecoration: "underline",
            cursor: "pointer",
            fontWeight: "600",
            fontSize: 14,
          }}
          onClick={() => {
            window.open("https://id5.io/universal-id/lockr/", "_blank");
          }}
        >
          Create one here
        </span>
      </div>
    );
  }

  return (
      <>
        <Modal
            centered
            width={750}
            footer={null}
            closable={false}
            bodyStyle={{
              minHeight: 400,
              width: 750,
              backgroundColor: "#353738",
              paddingRight: 60,
              paddingLeft: 60,
              paddingTop: 60,
              overflowX: "hidden",
            }}
            destroyOnClose
            style={{
              top: 20,
              paddingBottom: 20,
            }}
            visible={display}
        >
          <img
              style={{right: 60}}
              alt="close"
              onClick={onCancel}
              src={require("../../assets/modal-close.png")}
              className="delete-x-button-connection"
          />
          <div>
            <Typography.Title
                style={{
                  display: "inline-block",
                  fontFamily: "Montserrat",
                  color: "#fff",
                  fontWeight: 700,
                  fontSize: 20,
                  paddingBottom: 40,
                  paddingTop: 8,
                }}
            >
              {"Credentials"}
            </Typography.Title>
          </div>
          <div style={{marginTop: 16}}/>
          <TitleTextPassword
              placeholder={" "}
              label={"ID5 Partner ID"}
              marginLeft={128}
              eyeRight={65}
              disabled={modalCredentials.useLockrKeys}
              supportCopy
              supportDisabledColor
              value={
                modalCredentials?.useLockrKeys
                    ? "***********"
                    : modalCredentials?.accessKey
              }
              hideEye={modalCredentials.useLockrKeys}
              onChange={(event) =>
                  setModalCredentials({
                    ...modalCredentials,
                    accessKey: event.target.value,
                  })
              }
          />
          {/* <div style={{ marginTop: 16 }} /> */}
          {/* <TitleTextPassword
        placeholder={" "}
        label={"ID5 Token"}
        marginLeft={162}
        supportCopy
        eyeRight={65}
        hideEye = {modalCredentials.useLockrKeys}
        disabled={modalCredentials.useLockrKeys}
        supportDisabledColor
        value={
          modalCredentials?.useLockrKeys
            ? "***********"
            : modalCredentials?.secretKey
        }
        onChange={(event) =>
          setModalCredentials({
            ...modalCredentials,
            secretKey: event.target.value,
          })
        }
      /> */}
          {(
              <div
                  style={{
                    marginTop: 16,
                  }}
              />
          )}
          {(
              <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
              >
          <span
              style={{
                fontSize: 16,
                fontWeight: "400",
                fontFamily: "Montserrat",
                color: "white",
              }}
          >
            Use ID5 JS
          </span>
                <ToolTip
                    style={{
                      cursor: "pointer",
                    }}
                    title="When enabled, ID5's javascript will directly call the service from the client - bypassing lockr's server-side infrastructure."
                >
                  <div
                      style={{
                        marginLeft: 16,
                        display: "flex",
                        justifyContent: "center",
                      }}
                  >
                    <LockrInfoIcon/>
                  </div>
                </ToolTip>
                <div
                    style={{
                      marginLeft: 130,
                    }}
                />
                <Checkbox
                    onChange={(event) => {
                      if(event.target.checked) {
                        setDisplayPiggyBackJSModal(true);
                        return;
                      }
                      onRawID5Change(event.target.checked);
                    }}
                    checked={rawId5Enabled}
                />
                <span
                    style={{
                      fontSize: 12,
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      color: "white",
                      marginLeft: 8,
                    }}
                >
            {rawId5Enabled ? "Enabled" : "Disabled"}
          </span>
              </div>
          )}
          <div style={{marginTop: 24, display: "flex", alignItems: "center"}}>
            <Checkbox
                checked={modalCredentials.useLockrKeys}
                onChange={(event) => {
                  setModalCredentials({
                    ...modalCredentials,
                    useLockrKeys: event.target.checked,
                  });
                }}
            />
            <span
                style={{
                  marginLeft: 16,
                  color: "white",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                }}
            >
          Use lockrKeys
        </span>
            <ToolTip
                style={{
                  cursor: "pointer",
                }}
                title="Publishers can use their own credentials with service providers or choose to rely on lockr's pre-established connection."
            >
              <div
                  style={{
                    marginLeft: 16,
                    display: "flex",
                    justifyContent: "center",
                  }}
              >
                <LockrInfoIcon/>
              </div>
            </ToolTip>
          </div>
          <div style={{marginTop: 24}}/>
          <Button
              type="primary"
              className="sign-in"
              onClick={() => {
                onOk(modalCredentials);
              }}
              style={{
                width: 217,
                marginTop: 24,
                cursor: "pointer",
              }}
          >
            Confirm
          </Button>
          <div>
            <span className="errorText">{error}</span>
          </div>
          {renderTNCLine()}
        </Modal>
        <PiggyBackJSAcknowledgementModal
            titleTwo={"lockr also offers ID5 support via Server-to-Server (S2S) integration."}
            titleOne={"Enabling ID5 JS utilizes piggybacked JavaScript. Piggybacked JavaScript may slightly decrease page load performance."}
            display={displayPiggyBackJSModal} onCancel={() => {
          setDisplayPiggyBackJSModal(false);
        }} onOk={() => {
          setDisplayPiggyBackJSModal(false);
          onRawID5Change(true);
        }}/>
      </>
  );
}

export function ID5Token() {
  const dateFormat = "dddd MM/DD";
  const customFormat = (value) => `${value.format(dateFormat)}`;
  const [displayLoading, setDisplayLoading] = useState(false);
  const [id5Credentials, setID5Credentials] = useState(undefined);
  const [deletedCredentials, setDeletedCredentials] = useState(undefined);
  const [userCount, setUserCount] = useState(0);
  const [totalConnectedEmail, setTotalConnectedEmails] = useState(0);
  const [displayCredentialsModal, setDisplayCredentialsModal] = useState(false);
  const [upsertError, setUpsertError] = useState("");
  const [identityProviderSettingLoading, setidentityProviderSettingLoading] =
    useState(false);
  const [identityProviderSettings, setIdentityProviderSetting] = useState({
    dropCookie: false,
    dropLocalStorage: false,
    keepInIdentityLockr: false,
  });
  const [originalIdentityProviderSettings, setoriginalIdentityProviderSetting] =
    useState({
      dropCookie: false,
      dropLocalStorage: false,
      keepInIdentityLockr: false,
    });
  const [graphData, setGraphData] = useState([]);
  const [consentedUserCount, setConsentedUserCount] = useState(0);
  const [userAuthenticationData, setUserAuthenticationData] =
    useState(undefined);
  const [userAuthDataStartDate, setUserAuthDataStartDate] = useState(
    moment().subtract(7, "days")
  );
  const [userAuthDataEndDate, setUserAuthDataEndDate] = useState(moment());

  const [graphFilterStartDate, setGraphFilterStartDate] = useState(
    moment().subtract(7, "days")
  );
  const [graphFilterEndDate, setGraphFilterEndDate] = useState(moment());
  const [loginTrendsAppIDs, setLoginTrendsAppIDs] = useState([]);
  const [authDataAppIDs, setAuthDataAppIDs] = useState([]);

  const ssoUserCount = useMemo(() => {
    if (userAuthenticationData?.length) {
      let result = 0;
      userAuthenticationData.forEach((item) => {
        if (item.eventType === "lockrMail") {
          result = parseInt(item.count);
        }
      });
      return result;
    }
    return 0;
  }, [userAuthenticationData]);

  const utmUserCount = useMemo(() => {
    if (userAuthenticationData?.length) {
      let result = 0;
      userAuthenticationData.forEach((item) => {
        if (item.eventType === "UTM") {
          result = parseInt(item.count);
        }
      });
      return result;
    }
    return 0;
  }, [userAuthenticationData]);

  const emailCount = useMemo(() => {
    if (userAuthenticationData?.length) {
      let result = 0;
      userAuthenticationData.forEach((item) => {
        if (item.eventType !== "UTM" && item.eventType !== "lockrMail") {
          result += parseInt(item.count);
        }
      });
      return result;
    }
    return 0;
  }, [userAuthenticationData]);

  const [endDate, setEndDate] = useState(
    moment().add(1, "day").format("yyyy-MM-DD")
  );
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").format("yyyy-MM-DD")
  );
  const [analyticsData, setAnalyticsData] = useState([]);
  const [rawID5Enabled, setRawID5Enabled] = useState(false);
  const [checkID5HiddenFeatureEnabled, setCheckIF5HiddenFeatureEnabled] =
    useState(false);
  const [lockrMailAppIDs, setLockrMailAppIDs] = useState([]);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
    });
    getID5Credentials();
  }, []);

  useEffect(() => {
    id5Credentials?.accessKey && getGraphData();
  }, [id5Credentials, graphFilterStartDate, graphFilterEndDate, loginTrendsAppIDs]);

  useEffect(() => {
    id5Credentials?.accessKey && getUserStats();
  }, [id5Credentials, startDate, endDate, lockrMailAppIDs]);

  useEffect(() => {
    id5Credentials?.accessKey && checkRawID5FeatureEnabled();
    id5Credentials?.accessKey && checkHiddenFeatureID5Enabled();
    id5Credentials?.accessKey && getUserStats();
    id5Credentials?.accessKey && getUIDAnalyticsCall();
    id5Credentials?.accessKey && getIdentityProviderSettingsCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id5Credentials]);

  useEffect(() => {
    if (id5Credentials?.accessKey) {
      getUserAuthenticationData();
    }
  }, [id5Credentials, userAuthDataEndDate, userAuthDataStartDate, authDataAppIDs]);

  async function checkHiddenFeatureID5Enabled() {
    try {
      const response = await publisherHasID5Enabled();
      if (response.success) {
        setCheckIF5HiddenFeatureEnabled(response.data);
      }
    } catch (error) {
      console.log("Unable to check if the ID5 hidden feature is enabled");
      console.log(error);
    }
  }

  async function checkRawID5FeatureEnabled() {
    try {
      const response = await checkRawID5EnabledForPublisher();
      if (response.success) {
        setRawID5Enabled(response.data);
      }
    } catch (error) {
      console.log("Unable to check if the raw ID5 is enabled for the user");
      console.log(error);
    }
  }

  async function updateRawID5ForPublisherCall() {
      try {
          await updateRawID5ForPublisher(rawID5Enabled);
          if (rawID5Enabled) {
              await upsertIdentityProviderSetting(identitProvidersType.ID5, false, false, false);
              getIdentityProviderSettingsCall();
          }
          checkRawID5FeatureEnabled();
      } catch (error) {
          console.log(
              "Unable to update the raw ID5 for the publisher at the moment"
          );
          console.log(error);
      }
  }

  useEffect(() => {
    if (id5Credentials?.accessKey) {
      getUserAuthenticationData();
    }
  }, [id5Credentials, userAuthDataEndDate, userAuthDataStartDate, authDataAppIDs]);

  async function getUserAuthenticationData() {
    try {
      const response = await getUserAuthenticationDashboardData(
        userAuthDataStartDate,
        userAuthDataEndDate,
        identitProvidersType.ID5,
        authDataAppIDs
      );
      if (response.success) {
        setUserAuthenticationData(response.data);
        return;
      }
      setUserAuthenticationData([]);
    } catch (error) {
      console.log("Unable to get the data for the user authentication");
      console.log(error);
    }
  }

  async function getUIDAnalyticsCall() {
    try {
      const resposne = await getUIDAnalytics(startDate, endDate);
      if (resposne.success) {
        const result = [];
        resposne.data.map((item, index) => {
          if (index !== resposne.data.length - 1) {
            result.push({
              ...item,
              visitorCount:
                item.visitorCount === null ? 0 : parseInt(item.visitorCount),
              tokenCount:
                item.tokenCount === null ? 0 : parseInt(item.tokenCount),
            });
          }
        });
        setAnalyticsData(result);
      }
    } catch (error) {
      console.log("Unable to get the analytics at the moment");
      console.log(error);
    }
  }

  async function getIdentityProviderSettingsCall() {
    try {
      setidentityProviderSettingLoading(true);
      const { success, data } = await getIdentityProviderSetting(
        identitProvidersType.ID5
      );
      if (success && data) {
        setIdentityProviderSetting({
          dropCookie: data.dropCookie,
          dropLocalStorage: data.dropLocalStorage,
          keepInIdentityLockr: data.keepInIdentityLockr,
        });
        setoriginalIdentityProviderSetting({
          dropCookie: data.dropCookie,
          dropLocalStorage: data.dropLocalStorage,
          keepInIdentityLockr: data.keepInIdentityLockr,
        });
      }
      setidentityProviderSettingLoading(false);
    } catch (error) {
      setidentityProviderSettingLoading(false);
      console.log(
        "Unable to get the identitty provider settings at the moment"
      );
      console.log(error);
    }
  }

  async function upsertIdentityProviderSettingCall() {
    try {
      setidentityProviderSettingLoading(true);
      const { success, data } = await upsertIdentityProviderSetting(
        identitProvidersType.ID5,
        identityProviderSettings.dropCookie,
        identityProviderSettings.dropLocalStorage,
        identityProviderSettings.keepInIdentityLockr
      );
      if (success && data) {
        setIdentityProviderSetting(data);
        getIdentityProviderSettingsCall();
      }
      setidentityProviderSettingLoading(false);
      setidentityProviderSettingLoading(false);
    } catch (error) {
      console.log(
        "Unable to upsert the identity provider setting at the moment"
      );
      console.log(error);
    }
  }

  async function getID5Credentials() {
    setDisplayLoading(true);
    try {
      const { data, success } = await getUIDCredentialsAPI(
        identitProvidersType.ID5
      );
      if (success && data?.accessKey) {
        setID5Credentials(data);
      } else {
        getDeletedCredentials();
      }
    } catch (error) {
      console.log("Unable to get the id5 credentials at the moment");
      console.log(error);
    }
    setDisplayLoading(false);
  }

  async function getDeletedCredentials() {
    try {
      const { data, success } = await getDeletedUIDCredentialsAPI(
        identitProvidersType.ID5
      );
      if (success && data?.accessKey) {
        setDeletedCredentials({ ...data, isDeleted: false });
      }
    } catch (error) {
      console.log("Unable to get the deleted credentials at the moment");
      console.log(error);
    }
  }

  function renderDateFilters() {
    return (
      <Col
        sm={24}
        xs={24}
        style={{
          textAlign: "end",
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ConfigProvider locale={locale}>
          <DatePicker
            disabledDate={(date) => moment(date) > moment()}
            value={moment(startDate)}
            onChange={(e) => {
              setStartDate(moment(e).format("yyyy-MM-DD"));
            }}
            format={customFormat}
            className="dashboard-date-picker"
            dropdownClassName="calender-date-picker"
            popupStyle={{ background: "#171717" }}
            suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>}
            allowClear={false}
          ></DatePicker>
          <Typography.Text className="datepicker-btw-to">to</Typography.Text>
          <DatePicker
            disabledDate={(date) =>
              moment(date) > moment() || moment(startDate) > moment(date)
            }
            value={moment(endDate)}
            onChange={(e) => {
              setEndDate(moment(e).add(1, "day").format("yyyy-MM-DD"));
            }}
            format={customFormat}
            className="dashboard-date-picker"
            dropdownClassName="calender-date-picker"
            suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>}
            allowClear={false}
          ></DatePicker>
        </ConfigProvider>
      </Col>
    );
  }

  function getUserStats() {
    //SC-24105, Hide lockrMail
    // getTotalUsers();
    getTotalConnectedEmailCount();
    getTotalConsentedUsers();
  }

  async function getGraphData() {
    try {
      const response = await getUIDUserGraphData(
        startDate,
        endDate,
        identitProvidersType.ID5,
        loginTrendsAppIDs
      );
      if (response.success) {
        setGraphData(response.data.data);
      }
    } catch (error) {
      console.log("Unable to get the graph data at the moment");
      console.log(error);
    }
  }

  async function getTotalConsentedUsers() {
    try {
      const response = await getUIDConsentedUsers(startDate, endDate, lockrMailAppIDs);
      if (response.success) {
        setConsentedUserCount(response.data);
      }
    } catch (error) {
      console.log("Unable to get the total consented users");
      console.log(error);
    }
  }

  async function getTotalConnectedEmailCount() {
    try {
      const response = await getUIDConnectedEmailCount(
        startDate,
        endDate,
        identitProvidersType.ID5,
        lockrMailAppIDs
      );
      if (response.success) {
        setTotalConnectedEmails(response.data);
      }
    } catch (error) {
      console.log("Unable to get the connected email count");
      console.log(error);
    }
  }

  //SC-24105, Hide lockrMail
  // async function getTotalUsers() {
  //   try {
  //     const resposne = await getUIDUserCount(
  //       startDate,
  //       endDate,
  //       identitProvidersType.ID5,
  //       lockrMailAppIDs
  //     );
  //     if (resposne.success) {
  //       setUserCount(resposne.data);
  //     }
  //   } catch (error) {
  //     console.log("Unable to get the total users at the moment");
  //     console.log(error);
  //   }
  // }

  function getAvgConnectedEmails() {
    if (totalConnectedEmail && userCount) {
      return (totalConnectedEmail / userCount).toFixed(2);
    }
    return "0";
  }

  function getConsentedUserPercent() {
    if (userCount != 0) {
      return (
        ((consentedUserCount ?? 1) * 100) /
        (userCount ? userCount : 1)
      ).toFixed(2);
    }
    return 0;
  }

  function renderStats() {
    return (
      <div
        style={{
          marginTop: 72,
          marginLeft: 90,
          marginRight: 90,
        }}
      >
        {/*<IdentityProviderStatsAuthStrip*/}
        {/*  utmCount={utmUserCount}*/}
        {/*  ssoCount={ssoUserCount}*/}
        {/*  emailCount={emailCount}*/}
        {/*  startDate={userAuthDataStartDate}*/}
        {/*  endDate={userAuthDataEndDate}*/}
        {/*  allData={userAuthenticationData}*/}
        {/*  onChangeEndDate={setUserAuthDataEndDate}*/}
        {/*  onChangeStartDate={setUserAuthDataStartDate}*/}
        {/*  setAuthDataAppIDs={setAuthDataAppIDs}*/}
        {/*  authDataAppIDs={authDataAppIDs}*/}
        {/*/>*/}
        {/* <div style={{ marginTop: 60 }} />
        <IdentityProviderlockrNMailStrip
          startDate={startDate}
          endDate={endDate}
          onChangeEndDate={setEndDate}
          onChangeStartDate={setStartDate}
          consentedPercentage={getConsentedUserPercent()}
          totalUsers={userCount}
          avgEmails={getAvgConnectedEmails()}
          lockrMailAppIDs={lockrMailAppIDs}
          setLockrMailAppIDs={setLockrMailAppIDs}
        /> */}
        <div style={{ marginTop: 60 }} />
        <IdentityProviderGraphStrip
          endDate={graphFilterEndDate}
          startDate={graphFilterStartDate}
          graphData={graphData}
          onChangeEndDate={setGraphFilterEndDate}
          onChangeStartDate={setGraphFilterStartDate}
          setLoginTrendsAppIDs={setLoginTrendsAppIDs}
          loginTrendsAppIDs={loginTrendsAppIDs}
        />

        {/* <BatchUpload
          idType={identitProvidersType.ID5}
        /> */}
      </div>
    );
  }

  function renderAnalytics() {
    return (
      <div
        style={{
          marginTop: 72,
          marginLeft: 90,
          marginRight: 90,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "white",
                fontSize: 24,
                fontWeight: "700",
                fontFamily: "Montserrat",
              }}
            >
              Analytics
            </span>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "#FAC748",
                  height: 12,
                  width: 12,
                  borderRadius: 120,
                }}
              ></div>
              <span
                style={{
                  marginLeft: 8,
                  color: "white",
                  fontSize: 12,
                  fontWeight: "400",
                  fontFamily: "Montserrat",
                }}
              >
                Consenting accounts
              </span>
              <div
                style={{
                  background: "#50E8B1",
                  height: 12,
                  width: 12,
                  borderRadius: 120,
                  marginLeft: 24,
                }}
              ></div>
              <span
                style={{
                  marginLeft: 8,
                  color: "white",
                  fontSize: 12,
                  fontWeight: "400",
                  fontFamily: "Montserrat",
                }}
              >
                lockMail login accounts
              </span>
            </div>
          </div>
          <div
            style={{
              paddingTop: 30,
              paddingBottom: 30,
              background: "black",
              marginTop: 24,
              paddingRight: 30,
            }}
          >
            <ResponsiveContainer width={"100%"} height={320}>
              <LineChart
                width={500}
                height={300}
                data={analyticsData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray={"3 3"} color="#353738" />
                <XAxis dataKey="rangeDate" />
                <YAxis>
                  <Label
                    dx={-18}
                    style={{ fontFamily: "Montserrat", fontSize: 14 }}
                    angle={270}
                    fill="#8B8B8B"
                    value={"Login Count"}
                  ></Label>
                </YAxis>
                <Tooltip
                  contentStyle={{ background: "#171717", border: "0" }}
                  labelStyle={{ display: "none" }}
                  wrapperStyle={{ background: "black" }}
                  formatter={(value1, value2, value3) => {
                    // console.log(value1, value2, value3)
                    let v = "";
                    let valueLabel = "";
                    if (value2 === "tokenCount") {
                      v = value3.payload?.tokenCount ?? 0;
                      valueLabel = "Consenting Accounts";
                    } else {
                      v = value3.payload?.visitorCount ?? 0;
                      valueLabel = "lockMail login accounts";
                    }
                    return [v, valueLabel];
                  }}
                ></Tooltip>
                <Line
                  dot={{ display: "none" }}
                  activeDot={{ display: "none" }}
                  type="monotone"
                  dataKey="tokenCount"
                  stroke="#FAC748"
                  strokeWidth={2}
                />
                <Line
                  dot={{ display: "none" }}
                  type="monotone"
                  dataKey="visitorCount"
                  activeDot={{ display: "none" }}
                  stroke="#50E8B1"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }

  function displayConnectModal() {
    if (
      id5Credentials === undefined &&
      !displayCredentialsModal &&
      !displayLoading
    ) {
      return (
        <UIDNoCredentialModal
          integrationType={identitProvidersType.ID5}
          onOk={() => {
            setDisplayCredentialsModal(true);
          }}
          display={true}
        />
      );
    }
    return <></>;
  }

  async function removeID5IntegrationCall() {
    try {
      const response = await removeUIDIntegration(identitProvidersType.ID5);
      if (response.success && response.data) {
        setDeletedCredentials({ ...id5Credentials });
        setID5Credentials(undefined);
      }
    } catch (error) {
      console.log("Unable to remove the UID integration at the moment");
      console.log(error);
    }
  }

  async function saveID5Credentials(credentials) {
    setDisplayLoading(true);
    try {
      const response = await upsertUIDCredentialsAPI(
        credentials.accessKey,
        credentials.accessKey,
        identitProvidersType.ID5,
        credentials?.useLockrKeys ?? false
      );
      await updateRawID5ForPublisherCall();
      if (!response.success) {
        setDisplayLoading(false);
        setUpsertError(response.error);
        return;
      }
      getID5Credentials();
      setDisplayCredentialsModal(false);
    } catch (error) {
      console.log("Unable to upsert the id5 credentials at the moment");
      console.log(error);
    }
    setDisplayLoading(false);
  }

  function displaySwitch() {
    return (
      <div>
        <Switch
          checked={id5Credentials !== undefined}
          onChange={(checked) => {
            !checked && removeID5IntegrationCall();
          }}
        />
        <Typography.Text
          onClick={() => {
            setDisplayCredentialsModal(true);
          }}
          className="infosum-view-credentials-text"
        >
          Settings
        </Typography.Text>
      </div>
    );
  }

  function renderSettings() {
    return (
      <Row
        justify="space-between"
        align="middle"
        className="apiandservices-newapp-btn"
      >
        <div>
          <Typography.Title className="apiandservices-heading">
            Storage Settings
          </Typography.Title>
          <div style={{ marginTop: 32 }} />
          <div style={{ display: "flex", flexDirection: "row", gap: 48 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 6,
                alignItems: "center",
              }}
            >
              <Switch
                size="small"
                checked={identityProviderSettings.keepInIdentityLockr}
                disabled={rawID5Enabled}
                onChange={(checked) => {
                  setIdentityProviderSetting({
                    ...identityProviderSettings,
                    keepInIdentityLockr: checked,
                    dropCookie: checked,
                    dropLocalStorage: checked,
                  });
                }}
              />
              <span
                style={{
                  color: "white",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                }}
              >
                Keep in AIM
              </span>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
              <Checkbox
                onChange={(event) => {
                  setIdentityProviderSetting({
                    ...identityProviderSettings,
                    dropCookie: !event.target.checked,
                  });
                }}
                disabled={identityProviderSettings.keepInIdentityLockr || rawID5Enabled}
                checked={!identityProviderSettings.dropCookie}
              />
              <span
                style={{
                  color: "white",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                }}
              >
                Cookie
              </span>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
              <Checkbox
                onChange={(event) => {
                  setIdentityProviderSetting({
                    ...identityProviderSettings,
                    dropLocalStorage: !event.target.checked,
                  });
                }}
                disabled={identityProviderSettings.keepInIdentityLockr || rawID5Enabled}
                checked={!identityProviderSettings.dropLocalStorage}
              />
              <span
                style={{
                  color: "white",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                }}
              >
                Local storage
              </span>
            </div>
          </div>
        </div>
        <div>
          <Button
            disabled={
              identityProviderSettings.dropCookie ===
              originalIdentityProviderSettings.dropCookie &&
              identityProviderSettings.keepInIdentityLockr ===
              originalIdentityProviderSettings.keepInIdentityLockr &&
              identityProviderSettings.dropLocalStorage ===
              originalIdentityProviderSettings.dropLocalStorage
            }
            loading={identityProviderSettingLoading}
            onClick={upsertIdentityProviderSettingCall}
            style={{ width: "217px" }}
            className="sign-in"
          >
            Save
          </Button>
        </div>
      </Row>
    );
  }

  return (
    <SideDrawer>
      <Row
        justify="space-between"
        align="middle"
        className="apiandservices-newapp-btn"
      >
        <Col sm={12} xs={24}>
          <Typography.Title className="apiandservices-heading">
            ID5
          </Typography.Title>
          <div style={{ marginTop: 8 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <label
              style={{
                fontSize: 14,
                fontFamily: "Montserrat",
                color: "#8B8B8B",
              }}
              className="dashboard-as-of-today"
            >
              Enable to start a connection
            </label>
            <div
              style={{
                flex: 1,
                marginLeft: 20,
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div style={{ flex: 1 }}>{displaySwitch()}</div>
            </div>
          </div>
        </Col>
      </Row>
      {renderSettings()}
      {displayCredentialsModal && (
        <ID5CrdentialsModal
          onRawID5Change={setRawID5Enabled}
          rawId5Enabled={rawID5Enabled}
          display={displayCredentialsModal}
          id5HiddenFeatureEnabled={checkID5HiddenFeatureEnabled}
          credentials={{
            accessKey:
              id5Credentials?.accessKey ?? deletedCredentials?.accessKey,
            secretKey:
              id5Credentials?.secretKey ?? deletedCredentials?.secretKey,
            useLockrKeys:
              id5Credentials?.useLockrKeys ??
              deletedCredentials?.useLockrKeys ??
              false,
          }}
          onOk={saveID5Credentials}
          error={upsertError}
          onHideError={() => {
            setUpsertError("");
          }}
          onCancel={() => {
            setDisplayCredentialsModal(false);
          }}
        />
      )}
      {renderStats()}
      {displayConnectModal()}
    </SideDrawer>
  );
}
