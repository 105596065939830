/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import SideDrawer from "../../Components/SideDrawer";
import {
    Col,
    Row,
    Typography,
    Switch,
    ConfigProvider,
    DatePicker,
    Tooltip,
    Checkbox,
    Button, Tooltip as ToolTip, Modal,
} from "antd";
import locale from "antd/es/locale/en_GB";
import moment from "moment";
import { AiOutlineDown } from "react-icons/ai";
import {
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from "recharts";
import {
    checkFirstIDEnabledForPublisher,
    getDeletedUIDCredentialsAPI,
    getIdentityProviderSetting,
    getUIDAnalytics,
    getUIDConnectedEmailCount,
    getUIDConsentedUsers,
    getUIDCredentialsAPI,
    getUIDUserCount,
    getUIDUserGraphData,
    getUserAuthenticationDashboardData,
    removeUIDIntegration,
    updateFirstIdForPublisher,
    upsertIdentityProviderSetting,
    upsertUIDCredentialsAPI,
} from "../../Utils/ApiActions";
import { identitProvidersType } from "../../Utils/Helper";
import { UIDNoCredentialModal } from "../../Components/UIDNoCredentialModal";
import {LockrInfoIcon, UIDCredentialsModal} from "../../Components/UIDCredentialsModal";
import { IdentityProviderlockrNMailStrip } from "./components/IdentityProviderlockrMailStrip";
import { IdentityProviderStatsAuthStrip } from "./components/IdentityProviderStatsAuthStrip";
import { IdentityProviderGraphStrip } from "./components/IdentityProviderlockrGraphStrip";
import {PiggyBackJSAcknowledgementModal} from "./components/PiggyBackJSAcknowledgementModal";

export function FirstIDCredentialsModal({
                                            display, onOk, onCancel, error, credentials, onHideError = () => {
    }
                                        }) {
    const [modalCredentials, setModalCredentials] = useState(credentials ?? {passHEM: false});

    useEffect(() => {
        onHideError();
    }, [modalCredentials]);

    return (
        <Modal
            centered
            width={750}
            footer={null}
            closable={false}
            bodyStyle={{
                width: 750,
                backgroundColor: "#353738",
                paddingRight: 60,
                paddingLeft: 60,
                paddingTop: 60,
                overflowX: "hidden",
            }}
            destroyOnClose
            visible={display}
            style={{top: 20, paddingBottom: 20}}
        >
            <img
                style={{right: 60}}
                alt="close"
                onClick={onCancel}
                src={require("../../assets/modal-close.png")}
                className="delete-x-button-connection"
            />
            <div>
                <Typography.Title
                    style={{
                        display: "inline-block",
                        fontFamily: "Montserrat",
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: 20,
                        paddingBottom: 40,
                        paddingTop: 8,
                    }}
                >
                    {"Settings"}
                </Typography.Title>
            </div>
            <div style={{marginTop: 0, display: "flex", alignItems: "center"}}>
                <Checkbox
                    checked={modalCredentials.passHEM}
                    onChange={(event) => {
                        setModalCredentials({
                            ...modalCredentials,
                            passHEM: event.target.checked,
                        });
                    }}
                />
                <span
                    style={{
                        marginLeft: 16,
                        color: "white",
                        fontSize: 16,
                        fontFamily: "Montserrat",
                    }}
                >
                    Pass Hashed Emails to First ID
                </span>
                <ToolTip
                    style={{
                        cursor: "pointer",
                    }}
                    title="Enabling this enhances First ID’s service and provides a more stable, persistent ID. Disabling it reduces data sharing and your contribution to their identity graph."
                >
                    <div
                        style={{
                            marginLeft: 16,
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <LockrInfoIcon/>
                    </div>
                </ToolTip>
            </div>
            <div style={{marginTop: 24}}/>
            <Button
                type="primary"
                className="sign-in"
                onClick={() => {
                    onOk(modalCredentials);
                }}
                style={{
                    width: 217,
                    marginTop: 24,
                    cursor: "pointer",
                }}
            >
                Confirm
            </Button>
        </Modal>
    )
}

export function FirstIdToken() {

    const [endDate, setEndDate] = useState(
        moment().add(1, "day").format("yyyy-MM-DD")
    );
    const [startDate, setStartDate] = useState(
        moment().subtract(1, "month").format("yyyy-MM-DD")
    );

    const [displayLoading, setDisplayLoading] = useState(false);
    const [firstIDCredentials, setFirstIDCredentials] = useState(undefined);

    const [displayCredentialsModal, setDisplayCredentialsModal] = useState(false);
    const [firstIdEnabled, setFirstIdEnabled] = useState(false)
    const [passHEM, setPassHEM] = useState(false);
    const [graphFilterEndDate, setGraphFilterEndDate] = useState(moment());
    const [loginTrendsAppIDs, setLoginTrendsAppIDs] = useState([]);
    const [authDataAppIDs, setAuthDataAppIDs] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [displayJSPiggyBackModal, setDisplayJSPiggyBackModal] = useState(false);
    const [userAuthenticationData, setUserAuthenticationData] =
        useState(undefined);
    const [graphFilterStartDate, setGraphFilterStartDate] = useState(
        moment().subtract(7, "days")
    );
    const [consentedUserCount, setConsentedUserCount] = useState(0);

    const [userAuthDataStartDate, setUserAuthDataStartDate] = useState(
        moment().subtract(7, "days")
    );
    const [userAuthDataEndDate, setUserAuthDataEndDate] = useState(moment());

    useEffect(() => {
        getUserAuthenticationData();
    }, [userAuthDataEndDate, userAuthDataStartDate, authDataAppIDs]);

    async function getUserAuthenticationData() {
        try {
            const response = await getUserAuthenticationDashboardData(
                userAuthDataStartDate,
                userAuthDataEndDate,
                identitProvidersType.FIRSTID,
                authDataAppIDs
            );
            if (response.success) {
                setUserAuthenticationData(response.data);
                return;
            }
            setUserAuthenticationData([]);
        } catch (error) {
            console.log("Unable to get the data for the user authentication");
            console.log(error);
        }
    }

    const ssoUserCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType === "lockrMail") {
                    result = parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);



    const utmUserCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType === "UTM") {
                    result = parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);

    const emailCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType !== "UTM" && item.eventType !== "lockrMail") {
                    result += parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);



    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
        });
        checkFirstIDEnabled()
    }, []);

    const checkFirstIDEnabled = async () => {
        setDisplayLoading(true);
        const response = await checkFirstIDEnabledForPublisher();
        if (response.success) {
            setFirstIdEnabled(response.data);
            setPassHEM(response.passHEM);
        }
        setDisplayLoading(false)
    }

    const updateFirstId = async (value = false, fPassHEM = null) => {
        updateFirstIdForPublisher(value, fPassHEM || passHEM || false);
        setFirstIdEnabled(value)
    }

    function displaySwitch() {
        return (
            <div>
                <Switch
                    checked={firstIdEnabled}
                    onChange={(checked) => {
                        if(checked) {
                            setDisplayJSPiggyBackModal(true);
                            return;
                        }
                        !checked ? updateFirstId(false) : updateFirstId(true)
                    }}
                />
                <Typography.Text
                    onClick={() => {
                        setDisplayCredentialsModal(true);
                    }}
                    className="infosum-view-credentials-text"
                >
                    Settings
                </Typography.Text>
            </div>
        );
    }

    function renderStats() {
        return (
            <div
                style={{
                    marginTop: 72,
                    marginLeft: 90,
                    marginRight: 90,
                }}
            >
                {/*<IdentityProviderStatsAuthStrip*/}
                {/*    utmCount={utmUserCount}*/}
                {/*    ssoCount={ssoUserCount}*/}
                {/*    emailCount={emailCount}*/}
                {/*    allData={userAuthenticationData}*/}
                {/*    startDate={userAuthDataStartDate}*/}
                {/*    endDate={userAuthDataEndDate}*/}
                {/*    onChangeEndDate={setUserAuthDataEndDate}*/}
                {/*    onChangeStartDate={setUserAuthDataStartDate}*/}
                {/*    setAuthDataAppIDs={setAuthDataAppIDs}*/}
                {/*    authDataAppIDs={authDataAppIDs}*/}
                {/*/>*/}
                {/* <div style={{ marginTop: 60 }} />
            <IdentityProviderlockrNMailStrip
              startDate={startDate}
              endDate={endDate}
              onChangeEndDate={setEndDate}
              onChangeStartDate={setStartDate}
              consentedPercentage={getConsentedUserPercent()}
              totalUsers={userCount}
              avgEmails={getAvgConnectedEmails()}
              lockrMailAppIDs={lockrMailAppIDs}
              setLockrMailAppIDs={setLockrMailAppIDs}
            /> */}
                <div style={{ marginTop: 60 }} />
                <IdentityProviderGraphStrip
                    endDate={graphFilterEndDate}
                    startDate={graphFilterStartDate}
                    graphData={graphData}
                    onChangeEndDate={setGraphFilterEndDate}
                    onChangeStartDate={setGraphFilterStartDate}
                    setLoginTrendsAppIDs={setLoginTrendsAppIDs}
                    loginTrendsAppIDs={loginTrendsAppIDs}
                />
            </div>
        );
    }

    useEffect(() => {
        getGraphData();
    }, [graphFilterEndDate, graphFilterStartDate, firstIDCredentials, loginTrendsAppIDs]);

    async function getGraphData() {
        try {
            const response = await getUIDUserGraphData(
                graphFilterStartDate,
                graphFilterEndDate,
                identitProvidersType.FIRSTID,
                loginTrendsAppIDs
            );
            if (response.success) {
                setGraphData(response.data.data);
            }
        } catch (error) {
            console.log("Unable to get the graph data at the moment");
            console.log(error);
        }
    }




    function displayConnectModal() {
        if (
            !firstIdEnabled &&
            !displayCredentialsModal &&
            !displayJSPiggyBackModal &&
            !displayLoading
        ) {
            return (
                <UIDNoCredentialModal
                    integrationType={identitProvidersType.FIRSTID}
                    onOk={() => {
                        setDisplayJSPiggyBackModal(true);
                    }}
                    display={true}
                />
            );
        }
        return <></>;
    }
    return (
        <SideDrawer>
            <Row
                justify="space-between"
                align="middle"
                className="apiandservices-newapp-btn"
            >
                <Col sm={12} xs={24}>
                    <Typography.Title className="apiandservices-heading">
                        First ID
                    </Typography.Title>
                    <div style={{ marginTop: 8 }} />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                        }}
                    >
                        <label
                            style={{
                                fontSize: 14,
                                fontFamily: "Montserrat",
                                color: "#8B8B8B",
                            }}
                            className="dashboard-as-of-today"
                        >
                            Enable to start a connection
                        </label>
                        <div
                            style={{
                                flex: 1,
                                marginLeft: 20,
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                            }}
                        >
                            <div style={{ flex: 1 }}>{displaySwitch()}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            {renderStats()}
            {displayConnectModal()}
            {displayCredentialsModal && <FirstIDCredentialsModal
                display={displayCredentialsModal}
                onOk={(credentials) => {
                    setDisplayCredentialsModal(false);
                    setPassHEM(credentials.passHEM);
                    updateFirstId(true, credentials.passHEM);
                }}
                onCancel={() => {
                    setDisplayCredentialsModal(false);
                }}
                credentials={{
                    passHEM,
                }}
            />}
            <PiggyBackJSAcknowledgementModal
                display={displayJSPiggyBackModal}
                onOk={() => {
                    setDisplayJSPiggyBackModal(false);
                    setDisplayCredentialsModal(true);
                }}
                onCancel={() => {
                    setDisplayJSPiggyBackModal(false);
                }}
            />
        </SideDrawer>
    );
}
